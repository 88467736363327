/*
 * <copyright company="Extraaedge Pvt Ltd">
 *        copyright (c) Extraaedge Pvt Ltd.  All rights reserved.
 * </copyright>
 */

import { forEach, get, isEmpty, isNil, omitBy, isNumber, set } from "lodash";
import React, { createRef, lazy, Suspense } from "react";
import { Helmet } from "react-helmet";
import { connect } from "react-redux";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Row, ListGroup, ListGroupItem, Col } from "reactstrap";
import zipy from "zipyai";
import "./App.scss";
import { API } from "./infrastructure/network/HttpClient";
import Footer from "./shared/components/footer";
import Header from "./shared/components/header";
import { setCounselorAccessState,updateCurrentStep,setMandatoryFieldsMissing, getAllApplicationInit, userDetailInit} from "./shared/services/appData/Actions";
import {
  DEFAULT_UNAUTHERIZED_PAGE,
  MAXIMUM_NUMBER_OF_TIMES_TO_CALL_LEAD_DTAIL_API,
  PAGES,
  STORAGE_KEY,
  STORAGE_TYPE
} from "./shared/utilties/Constants";
import { OfflineStorage } from "./shared/utilties/OfflineStorage";
import { addURLParametersAndRoute, getLang, getURLqueryParameters, isAccessTokenValid } from "./shared/utilties/Utils";
import { getQueryCount, getTotalUnreadMessageCount } from "./pages/query/Actions";
import WidgetPopup from "./shared/components/WidgetPopup";

const LazyDashboardComponent = lazy(() =>
  import(/* webpackChunkName: 'dashboard' */ "./pages/dashboard")
);
const LazyFormComponent = lazy(() =>
  import(/* webpackChunkName: 'form' */ "./pages/form")
);
const LazyLoginComponent = lazy(() =>
  import(/* webpackChunkName: 'login' */ "./pages/login")
);
const LazyPageNotFoundComponent = lazy(() =>
  import(/* webpackChunkName: 'pageNotFound' */ "./pages/pageNotFound")
);
const LazyForgetPasswordComponent = lazy(() =>
  import(/* webpackChunkName: 'forgetpassword' */ "./pages/forgetpassword")
);
const LazyCountinueComponent = lazy(() =>
  import(/* webpackChunkName: 'Continue' */ "./pages/continue")
);
const LazyQueryComponent = lazy(() =>
  import(/* webpackChunkName: 'Continue' */ "./pages/query")
);
const LazyFinanceComponent = lazy(() =>
  import(/* webpackChunkName: 'Continue' */ "./pages/finance")
);
const LazyExamAndProspectusComponent = lazy(() =>
  import(/* webpackChunkName: 'Continue' */ "./pages/examAndProspectus")
);
const LazyVisaAndImmigrationComponent = lazy(() =>
  import(/* webpackChunkName: 'Continue' */ "./pages/visaImmigration")
);

const LazyMenuNavigationComponent = React.lazy(() =>
  import("./shared/components/MenuNavigation")
);

const LazyPaymentHistoryComponent = React.lazy(() => import("./pages/paymentHistory"));

const LazyAllApplicationsComponent = React.lazy(() => import("./pages/allApplications"));

toast.configure({
  draggable: false,
  autoClose: false,
  //etc you get the idea
});
const LazyResetPasswordComponent = lazy(() =>
  import(/* webpackChunkName: 'resetPassword' */ "./pages/resetPassword")
);
function PrivateRoute({ component: Component, ...rest }) {
  let accessToken = OfflineStorage.getItem(
    STORAGE_KEY.ACCESS_TOKEN,
    STORAGE_TYPE.LOCAL
  );


  return (
    <Route
      {...rest}
      render={(props) =>
        !!accessToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: DEFAULT_UNAUTHERIZED_PAGE,
              state: {
                from: props.location,
              },
              search:
                props && props.location && !!props.location.search
                  ? props.location.search
                  : "",
            }}
          />
        )
      }
    />
  );
}
function ResetPassowrdRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        let searchParams = getURLqueryParameters(true);
        searchParams =
          searchParams && Object.keys(searchParams).length
            ? searchParams
            : null;
        return searchParams &&
          Object.keys(searchParams).length &&
          !!searchParams["token"] &&
          !!searchParams["email"] ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
            }}
          />
        );
      }}
    />
  );
}
/*test */
const CustomLoader = ({message}) => {
  return (
    <div className="zipy-block"><div className="api-loader">
      <div className="animation" />
      {!!message && 
          <div className="api-loader-text" 
          dangerouslySetInnerHTML={{
            __html: message
          }} />}
    </div></div>
  );
};
export const SuspendRoute = () => {
  return (
    <div className="zipy-block"><div className="route-loader">
      <div className="animation" />
    </div></div>
  );
};
class App extends React.Component {
  constructor(props){ 
    super(props);
    if (
      !!OfflineStorage.getItem(STORAGE_KEY.ACCESS_TOKEN, STORAGE_TYPE.LOCAL)
    ) {
      //update user info from local storage to reducer
    }
    this.searchParams = null;
    this.state = {
      isInProgress: false,
      clientInfo: this.props.clientInfo,
      htmlDocumentHeading: getLang("htmlDocumentHeading",null) 
    };
    let existingToken = OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    );
    let isLoggedIn = !!existingToken && isAccessTokenValid(existingToken);
    let searchParams = getURLqueryParameters();
    let localStorageParams={
      [STORAGE_KEY.PRN]:!!OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL)?OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL):undefined,
      [STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]:!!OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, STORAGE_TYPE.LOCAL)?OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, STORAGE_TYPE.LOCAL):undefined,
      [STORAGE_KEY.INST]:!!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)?OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL):undefined,
      [STORAGE_KEY.COUNSELOR_ID]:!!OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL)?OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL):undefined,
      [STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID]:!!OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID, STORAGE_TYPE.LOCAL)?OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID, STORAGE_TYPE.LOCAL):undefined
    };
    let removeAssessToken=false;
    searchParams=omitBy(searchParams,isEmpty);
    //localStorageParams=omitBy(localStorageParams,isEmpty);
    forEach(Object.keys(localStorageParams),key=>{
      let queryStringKeyValue=(key===STORAGE_KEY.INST ? get(searchParams, key) || get(searchParams, STORAGE_KEY.APP_CRITERION) : get(searchParams, key));
      if((!!queryStringKeyValue && !localStorageParams[key]) || (localStorageParams[key] !== queryStringKeyValue)){
        removeAssessToken=!removeAssessToken?queryStringKeyValue!==localStorageParams[key]:removeAssessToken;
        !!queryStringKeyValue?OfflineStorage.setItem( key, queryStringKeyValue, STORAGE_TYPE.LOCAL):OfflineStorage.deleteItem(key, STORAGE_TYPE.LOCAL);
      }
    });
    if(removeAssessToken) {
      OfflineStorage.setItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP, 1, STORAGE_TYPE.LOCAL);
    }
    removeAssessToken && !!existingToken && OfflineStorage.deleteItem(STORAGE_KEY.ACCESS_TOKEN, STORAGE_TYPE.LOCAL);
    removeAssessToken && !!existingToken && OfflineStorage.deleteItem(STORAGE_KEY.CHATBOT_CONFIG, STORAGE_TYPE.LOCAL);
    if(searchParams["verifyToken"] && searchParams["alias"]){
      API.get(`/leads/verifyIdentity?token=${searchParams["verifyToken"]}&alias=${searchParams["alias"]}`)
      const stateObj = {
        verifyToken: searchParams["verifyToken"],
        alias: searchParams["alias"]
      }
      window.history.replaceState(stateObj, '', window.location.search);
    }
    this.isCounselorAccessActive =
    !!OfflineStorage.getItem(STORAGE_KEY.PRN, STORAGE_TYPE.LOCAL) &&
    !!OfflineStorage.getItem(
      STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID,
      STORAGE_TYPE.LOCAL
    ) &&
    !!OfflineStorage.getItem(STORAGE_KEY.COUNSELOR_ID, STORAGE_TYPE.LOCAL); 
    if(!this.isCounselorAccessActive) {
      const showInstructionFlag = OfflineStorage.getItem(
        STORAGE_KEY.SHOW_INSTRUCTION_MODAL
      );
      if(!showInstructionFlag){
        //if not present then store .
        OfflineStorage.setItem(
          STORAGE_KEY.SHOW_INSTRUCTION_MODAL,
          STORAGE_TYPE.LOCAL
        ) 
      }
    }
    if (existingToken && !isLoggedIn) {
      OfflineStorage.clear(STORAGE_TYPE.LOCAL);
      OfflineStorage.clear();
      window.location.reload();
    }else{
      this.props.setCounselorAccessState && this.props.setCounselorAccessState(this.isCounselorAccessActive);
    }
    this.allApplicationsDataApiCalled = createRef(false);
    this.getQueryCountApiCalled = createRef(false);
    this.numberOfTimesCalledLeadDetailsAPI = 0;
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return {
      ...prevState,
      clientInfo: nextProps.clientInfo,
      showRefreshWindowPopup:nextProps.showRefreshWindowPopup,
      mandatoryFieldsMissing:nextProps.mandatoryFieldsMissing,
      autoNavigateRouteDetails:nextProps.autoNavigateRouteDetails
    };
  }
  componentDidUpdate(prevProps, prevState) { 
    /*if(!prevProps.autoNavigateRouteDetails && !!get(this.props,`autoNavigateRouteDetails.stepIndex`)){
      this.navigateToStep(this.props.autoNavigateRouteDetails.stepIndex,{ currentStep: this.props.autoNavigateRouteDetails.stepIndex });
      
    }*/
    const accessToken = OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    );
    if (accessToken) {
      const userId = get(this.props.userDetail, "user.id");
      if (
        !this.getQueryCountApiCalled.current &&
        userId &&
        !(window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE)
      ) {
        const clientAlias = get(this.props.clientInfo, "Info.crm_alias");
        if (clientAlias && userId) {
          this.props.getQueryCount(userId, clientAlias);
          !this.isCounselorAccessActive &&
            this.props.getTotalUnreadMessageCount(userId, clientAlias);
          this.getQueryCountApiCalled.current = true;
        }
      }
      if (!this.props.isInProgress) {
        if (
          this.state.clientInfo &&
          isNil(this.props.userDetail) &&
          !!this.props.userDetailApiError &&
          this.numberOfTimesCalledLeadDetailsAPI <
            MAXIMUM_NUMBER_OF_TIMES_TO_CALL_LEAD_DTAIL_API
        ) {
          this.numberOfTimesCalledLeadDetailsAPI =
            this.numberOfTimesCalledLeadDetailsAPI + 1;
          this.props.userDetailInit({ logFrom: "Fetch User Details" });
        }
        if (
          this.props.masterData &&
          isNil(this.props.allApplicationsData) &&
          !this.allApplicationsDataApiCalled.current &&
          !(window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE)
        ) {
          this.props.getAllApplicationInit();
          this.allApplicationsDataApiCalled.current = true;
        }
      }
    }
  } 
  componentDidMount () {

    const accessToken = OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    );
    if (accessToken) {
      const userId = get(this.props.userDetail, "user.id");
      if (
        !this.getQueryCountApiCalled.current &&
        userId &&
        !(window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE)
      ) {
        const clientAlias = get(this.props.clientInfo, "Info.crm_alias");
        if (clientAlias && userId) {
          this.props.getQueryCount(userId, clientAlias);
          !this.isCounselorAccessActive &&
            this.props.getTotalUnreadMessageCount(userId, clientAlias);
          this.getQueryCountApiCalled.current = true;
        }
      }
      if (!this.props.isInProgress) {
        if (this.state.clientInfo && isNil(this.props.userDetail)) {
          this.props.userDetailInit({ logFrom: "Fetch User Details" });
        }
        if (
          this.props.masterData &&
          isNil(this.props.allApplicationsData) &&
          !this.allApplicationsDataApiCalled.current &&
          !(window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE)
        ) {
          this.props.getAllApplicationInit();
          this.allApplicationsDataApiCalled.current = true;
        }
      }
    }
  }
  navigateToStep=(stepIndex,routeCustomState={})=>{
    this.props.updateCurrentStep && this.props.updateCurrentStep(stepIndex);
    /**
     * TODO Set currentStep in Local storage
     */
    OfflineStorage.setItem(STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,stepIndex,STORAGE_TYPE.LOCAL);   
    let routeObject={pathname: PAGES.FORM};
    set(routeCustomState,STORAGE_KEY.APPLICATION_FROM_CURRENT_STEP,stepIndex);
    set(routeObject,`state`,routeCustomState);
    addURLParametersAndRoute(routeObject,get(this.props,"history"));
  }
  onRefreshClick=(e)=>{
      const windowRefreshCount=OfflineStorage.getItem(STORAGE_KEY.WINDOW_REFRESH_COUNT,STORAGE_TYPE.LOCAL);
      OfflineStorage.setItem(STORAGE_KEY.WINDOW_REFRESH_COUNT,!isNil(windowRefreshCount) && isNumber(parseInt(windowRefreshCount)) ? (parseInt(windowRefreshCount))+1:1,STORAGE_TYPE.LOCAL);
      zipy && zipy.anonymize();
      window.location.reload();
  }
  
  ForceRefreshWarningPopup=(props)=>{
    return (<React.Fragment>
      <Modal centered={true} isOpen={true} backdrop="static" unmountOnClose={true} >
        <ModalHeader>
          {getLang("global.refreshWindow.heading","Refresh Window")}
        </ModalHeader>
        <ModalBody>
          {getLang("global.refreshWindow.message", "Unable to continue, please refresh window to continue.")}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={this.onRefreshClick}>
            {" "}
            {getLang("global.refreshWindow.yesLabel","Refresh")}
          </Button>                  
        </ModalFooter>
      </Modal>
  </React.Fragment>)
  }
  ForceRefreshFinalWarningPopup=(props)=>{
    return (<React.Fragment>
              <Modal isOpen={true} backdrop="static" unmountOnClose={true} >
                <ModalHeader>
                  {getLang("global.refreshWindow2.heading","Try Again")}
                </ModalHeader>
                <ModalBody>
                  {getLang("global.refreshWindow2.message", "Please check your all mandatory fields in previous steps and then click on Submit.")}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={e=>{
                      OfflineStorage && OfflineStorage.deleteItem(STORAGE_KEY.WINDOW_REFRESH_COUNT,STORAGE_TYPE.LOCAL);
                      this.props.hideRefreshWindowPopup && this.props.hideRefreshWindowPopup();
                    }}>
                    {" "}
                    {getLang("global.refreshWindow2.yesLabel","OK")}
                  </Button>                  
                </ModalFooter>
              </Modal>
          </React.Fragment>)
  }
  
  MandatoryFieldsMissingOfAlreadyCompeletedSteps=(props)=>{
    const {mandatoryFieldsMissing} = this.state;
    return (mandatoryFieldsMissing && Array.isArray(mandatoryFieldsMissing) && mandatoryFieldsMissing.length>0 ? <React.Fragment>
              <Modal isOpen={true} backdrop="static" unmountOnClose={true} >
                <ModalHeader>
                  {getLang("global.refreshWindow3.heading","Try Again")}
                </ModalHeader>
                <ModalBody>
                  {getLang("global.refreshWindow3.message", "Please check your all mandatory fields in previous steps and then click on Submit.")}
                  {mandatoryFieldsMissing.map((step,arrIndex)=>{
                    const {missingFields,stepHeading}=step;
                    return (<React.Fragment key={arrIndex}>
                      <p style={{margin:"1rem 0 0 0"}}><strong>{getLang(stepHeading)}</strong></p>
                      <ListGroup>
                        {missingFields.map((field,fieldArrIndex)=>{
                          return <ListGroupItem key={fieldArrIndex} tag="span" style={{padding:"0.1rem 0.5rem",border:"none"}}>{getLang(field)}</ListGroupItem>
                        })}
                      </ListGroup>
                    </React.Fragment>)
                  })}
                </ModalBody>
                <ModalFooter>
                  <Button color="primary" onClick={e=>{
                      //move to very first step where mandatory field is missing
                      //route to first step where missing mandatory field found
                      const {stepIndex}=mandatoryFieldsMissing[0];
                      this.props.setMandatoryFieldsMissing && this.props.setMandatoryFieldsMissing();
                      this.navigateToStep(stepIndex+1,{ currentStep: stepIndex+1,runValidateOnStepRender:true });
                    }}>
                    {" "}
                    {getLang("global.refreshWindow3.yesLabel","OK")}
                  </Button>                  
                </ModalFooter>
              </Modal>
          </React.Fragment>:null)
  }
  render() {
    const {clientInfo,showRefreshWindowPopup,mandatoryFieldsMissing}=this.state;
    const accessToken = OfflineStorage.getItem(
      STORAGE_KEY.ACCESS_TOKEN,
      STORAGE_TYPE.LOCAL
    );
    const publicRoutes =  (
      window.location.pathname === PAGES.LOGIN ||
      window.location.pathname === PAGES.LEAD_TO_APPLICATION_CONTINUE
    );
    return (
      <React.Fragment>
        {(this.props.isInProgress || this.state.isInProgress) && (
          <CustomLoader message={this.props.globalLoaderMessage} />
        )}
        {!isNil(this.state.htmlDocumentHeading) &&
        !isEmpty(this.state.htmlDocumentHeading) ? (
          <Helmet>
            <title>{getLang("htmlDocumentHeading", null)}</title>
          </Helmet>
        ) : null}
        {!isNil(
          OfflineStorage.getItem(STORAGE_KEY.CHATBOT_CONFIG, STORAGE_TYPE.LOCAL)
        ) &&
        !isNil(clientInfo) &&
        !!get(clientInfo, `chatBot.isActive`) &&
        !!get(clientInfo, `chatBot.chatConfigParameters`) &&
        !!get(clientInfo, `chatBot.js_script_path`) &&
        !document.getElementById("eeChatContainer") ? (
          <Helmet>
            <script src={clientInfo.chatBot.js_script_path}></script>
          </Helmet>
        ) : null}

        <Row className={`${window.innerWidth < 1300 ? "no-padding-margin-container" : ""}`}>
          <Header />
        </Row>
        <Row
          className={`route-container ${
            publicRoutes ? "custom-scroll public-routes-scroll appform-bg" : ""} ${this.props.isLeftSidebarExpanded && window.innerWidth < 1300 ? "small-screen-sidebar-effect" : ""} ${window.innerWidth < 1300 ? "no-negative-margins" : ""}`}
        >
          {this.props.isLeftSidebarExpanded && (<div className="overlay"></div>)}
          <Suspense fallback={<SuspendRoute />}>
            {accessToken && !publicRoutes && (
            
               <Col
                sm="2"
                key={"left-content"}
                className="sidebar-col left-content dashbaord mh-100 pl-0 pr-sm-0 col-sm-2 pt-2"
              >
                {/** Render side bar */}
                <LazyMenuNavigationComponent
                  domainConfig={this.props.domainConfig}
                  history={this.props.history}
                />
              </Col>
            )}
            <Switch>
              <Route path="/login" component={LazyLoginComponent} />
              <Route path="/continue" component={LazyCountinueComponent} />
              <Route
                path="/forgetpassword"
                component={LazyForgetPasswordComponent}
              />
              <ResetPassowrdRoute
                searchParams={this.searchParams}
                component={LazyResetPasswordComponent}
                path="/password"
              />
              <PrivateRoute path="/" exact component={LazyDashboardComponent} />
              <PrivateRoute path="/form" component={LazyFormComponent} />
              <PrivateRoute
                path="/paymentHistory"
                component={LazyPaymentHistoryComponent}
              />
              <PrivateRoute path="/query" component={this.isCounselorAccessActive ? LazyPageNotFoundComponent : LazyQueryComponent}/>
              <PrivateRoute path="/visaImmigration" component={LazyVisaAndImmigrationComponent} />
              <PrivateRoute path="/finance" component={LazyFinanceComponent} />
              <PrivateRoute path="/examAndProspectus" component={LazyExamAndProspectusComponent} />
              <PrivateRoute path="/allApplications" component={LazyAllApplicationsComponent} />
              <Route component={LazyPageNotFoundComponent} />
            </Switch>
          </Suspense>
          <ToastContainer className="custom-notify" />
        </Row>
        {publicRoutes && (
          <Row>
            <Footer />
          </Row>
        )}
        {showRefreshWindowPopup &&
          (isNil(
            OfflineStorage.getItem(
              STORAGE_KEY.WINDOW_REFRESH_COUNT,
              STORAGE_TYPE.LOCAL
            )
          ) ||
            !isNumber(
              parseInt(
                OfflineStorage.getItem(
                  STORAGE_KEY.WINDOW_REFRESH_COUNT,
                  STORAGE_TYPE.LOCAL
                )
              )
            ) ||
            (!isNil(
              OfflineStorage.getItem(
                STORAGE_KEY.WINDOW_REFRESH_COUNT,
                STORAGE_TYPE.LOCAL
              )
            ) &&
              isNumber(
                parseInt(
                  OfflineStorage.getItem(
                    STORAGE_KEY.WINDOW_REFRESH_COUNT,
                    STORAGE_TYPE.LOCAL
                  )
                )
              ) &&
              parseInt(
                OfflineStorage.getItem(
                  STORAGE_KEY.WINDOW_REFRESH_COUNT,
                  STORAGE_TYPE.LOCAL
                )
              ) < 2)) &&
          this.ForceRefreshWarningPopup()}
        {showRefreshWindowPopup &&
          !isNil(
            OfflineStorage.getItem(
              STORAGE_KEY.WINDOW_REFRESH_COUNT,
              STORAGE_TYPE.LOCAL
            )
          ) &&
          isNumber(
            parseInt(
              OfflineStorage.getItem(
                STORAGE_KEY.WINDOW_REFRESH_COUNT,
                STORAGE_TYPE.LOCAL
              )
            )
          ) &&
          parseInt(
            OfflineStorage.getItem(
              STORAGE_KEY.WINDOW_REFRESH_COUNT,
              STORAGE_TYPE.LOCAL
            )
          ) === 2 &&
          this.ForceRefreshFinalWarningPopup()}
        {mandatoryFieldsMissing &&
          Array.isArray(mandatoryFieldsMissing) &&
          mandatoryFieldsMissing.length > 0 &&
          this.MandatoryFieldsMissingOfAlreadyCompeletedSteps()}
        { !publicRoutes &&
          <WidgetPopup history={this.props.history} />
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  allApplicationsData: state.appDataReducer && state.appDataReducer.allApplicationsData,
  masterData: state.appDataReducer && state.appDataReducer.masterData,
  domainConfig: state.appDataReducer && state.appDataReducer.domainConfig,
  formData: state.formReducer && state.formReducer.formData,
  formError: state.formReducer && state.formReducer.formError,
  clientInfo: state.appDataReducer && state.appDataReducer.clientInfo,
  userDetail: state.appDataReducer && state.appDataReducer.userDetail,
  globalLoaderMessage: state.appDataReducer && state.appDataReducer.globalLoaderMessage,
  autoNavigateRouteDetails: state.appDataReducer && state.appDataReducer.autoNavigateRouteDetails, 
  isLeftSidebarExpanded:state.appDataReducer && state.appDataReducer.isLeftSidebarExpanded, 
  isInProgress:
    (state.dashboardReducer && state.dashboardReducer.isInProgress) ||
    (state.formReducer && state.formReducer.isInProgress) ||
    (state.loginReducer && state.loginReducer.isInProgress) ||
    (state.appDataReducer && state.appDataReducer.isInProgress) ||
    (state.appDataReducer && state.appDataReducer.isGlobalInProgress),
    showRefreshWindowPopup: state.appDataReducer && state.appDataReducer.showRefreshWindowPopup,
    mandatoryFieldsMissing: state.appDataReducer && state.appDataReducer.mandatoryFieldsMissing,
  userDetailApiError: state.appDataReducer && state.appDataReducer.userDetailError
});

const componentConnected = withRouter(
  connect(mapStateToProps, {
    setCounselorAccessState,
    updateCurrentStep,
    setMandatoryFieldsMissing,
    getAllApplicationInit,
    userDetailInit,
    getQueryCount,
    getTotalUnreadMessageCount,
  })(App)
);

export { componentConnected as default };

