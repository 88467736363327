import { API } from "../../infrastructure/network/HttpClient";
export const Service = {
  getConfig: function(url) {
    return fetch(url)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        return myJson;
      });
  },
  validateLogin: function(param) {
    var fd = {
      username: !!param.email ? param.email : null,
      password: !!param.password ? param.password : null,
      userrole: !!param.userrole ? param.userrole : null,
      deviceType: "Web"
    };
    //"Web" - identify device logic need to add here
    return API.post("/login/validate", fd);
  },  
  addLead: function(params, fd) {
    return API.post(params, fd);
  }
};
