import { replaceEmailWithStars, replaceMobileWithStars, replaceUniqueCheckFieldValueWithStars } from "./Utils";

export const APP_FORM_VERSIONS = {
  2: "2"
}

export const REQUEST_API = {
  BASE_URL:
    window &&
    window.extraaedge &&
    window.extraaedge.API_END_POINT &&
    !!window.extraaedge.API_END_POINT.BASE_URL
      ? window.extraaedge.API_END_POINT.BASE_URL
      : "https://api.extraaedge.com/api",
  QUERY_BASE_URL:
    window &&
    window.extraaedge &&
    window.extraaedge.APPFORM_V2_API_END_POINT &&
    !!window.extraaedge.APPFORM_V2_API_END_POINT.BASE_URL
      ? window.extraaedge.APPFORM_V2_API_END_POINT.BASE_URL
      : "https://api.extraaedge.com/token",
  TOKEN_URL:
    window &&
    window.extraaedge &&
    window.extraaedge.API_END_POINT &&
    !!window.extraaedge.API_END_POINT.TOKEN_URL
      ? window.extraaedge.API_END_POINT.TOKEN_URL
      : "https://api.extraaedge.com/token",
  TIMEOUT: 3000,
  MOTHOD: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
};
/* mentioned all pages route name in below PAGES for use in development */
export const PAGES = {
  HOME: "/home",
  APP: "/app",
  LOGIN: "/login",
  DASHBOARD: "/",
  FORM: "/form",
  ROOT: "/",
  FORGET_PASSWORD: "/forgetpassword",
  RESET_PASSWORD: "/password",
  LEAD_TO_APPLICATION_CONTINUE: "/continue",
  ERROR_PAGE: "/inst-missing",
  ALL_APPLICATIONS: "/allApplications",
  PAYMENT_HISTORY: "/paymentHistory"
};
export const DEFAULT_UNAUTHERIZED_PAGE = PAGES.LOGIN;
export const DEFAULT_AUTHENTICATED_PAGE = PAGES.HOME;
export const DEFAULT_LANGUAGE = "en";

export const TEMPATE_TYPES = {
  SIDEPANEL: {
    COLLASE: "collapse",
    SCROLL: "scroll",
    STEP_NAVIGATION: "stepNavigation",
  },
};
export const SIDEPANEL_DEFAULT_TEMPATE = TEMPATE_TYPES.SIDEPANEL.SCROLL;

/**
 * @description use in entire development of fetch user info from local storage
 * @summary Add all user info object's keys here and use in development to avoid future update of key name
 */
export const STORAGE_KEY = {
  VERIFIED_INST: "vi",
  VERIFIED_ACCESS_TOKEN:"vt",
  ACCESS_TOKEN: "accesstoken",
  AUTH_DETAILS: "auth_details",
  USER_DETAIL: "user_details",
  DASHBAORD_CONFIG: "dashbaord_config",
  LOGIN_CONFIG: "login_config",
  FORM_CONFIG: "form_config",
  MASTER_DATA: "master_data",
  USER_FIRST_NAME: "firstName",
  USER_LAST_NAME: "lastName",
  USER_MOBILE: "mobileNumber",
  USER_EMAIL: "email",
  USER_AVATAR: "avatarUrl",
  APPLIACTION_FORM_STATUS: "applicationFormStatus",
  APPLICATION_FORM_NUMBER: "applicationFormNumber",
  APPLICATION_FROM_CURRENT_STEP: "currentStep",
  APPLICATION_FROM_COMPLETED_STEP: "completedStep",
  DOMAIN_CONFIG:"domianConfig",
  CLIENT_INFO_REDUCER: "clientInfo",
  CLIENT_INFO: "Info",
  CLIENT_ID: "clientId",
  AUTH_TOEKN: "authToken",
  CLIENT_NAME: "clientName",
  SOURCE: "source",
  ALIAS: "alias",
  REQUEST_MASTER_DATA_FOR_FIELDS: "requiremasterfields",
  PAYMENT_DETAILS: "payment_details",
  APP_CRITERION_KEY: "AppCriterion",
  INST: "inst",
  PRN: "prn",
  APP_CRITERION: "appCriterion",
  APPLICATION_FORM_CRIETRIA: "applicationFormCriteria",
  PRICING_CRITERIA: "pricingCriterion",
  LEAD_STATUS_CONDITION_TO_ENABLE_STEP: "leadStatusConditionToEnableStep",
  CRM_CLIENT_ID: "crm_clientId",
  CRM_ALIAS: "crm_alias",
  IS_MASTER_DATA_FROM_JSON_FEATURE_ACTIVE:
    "masterData_from_JSON_Feature_isActive",
  REQUEST_MASTERDATA_FROM_JSON: "request_MasterData_From_JSON",
  REQUEST_STORE_SETTING_FROM_JSON: "request_Store_Setting_From_JSON",
  IS_GENERATE_JSON_FILE_API_KEY: "isGenerateFile",
  IS_GENERATE_MASTER_DATA_FILE_API_KEY: "isGenerateFile_MASTER_DATA",
  IS_GENERATE_STORE_SETTING_FILE_API_KEY: "isGenerateFile_STORE_SETTING",
  UNIQUE_KEY_CRITERIA: "uniqueKeyCriteria",
  UKC_URL_PARAMETER_KEY: "uk",
  UKC_VALUE_URL_PARAMETER_KEY: "ukc",
  ROLE: "role",
  COUNSELOR_ACCESS_LEAD_ID: "lid",
  COUNSELOR_ID: "cid",
  COUNSELOR_DETAILS: "counselorDetails",
  CHATBOT_CONFIG: "eeChatConfig",
  TEMPLATE_PDF_MASTER_KEY_NAME:"templatePdfs",
  IS_OVERWRITE_NAME_BY_IDS_IN_ADD_LEAD_REQUEST_PARAMS_ACTIVE:"isOverwriteNamesByIdsInAddLeadRequestParamsActive",
  APPFORM_COMPLETED_STEP_INDEX:"appFormCompletedStepIndex",
  APPFORM_NAVIGATION_STATUS:"appFormNavigationStatus",
  APPFORM_COMPLETION_PERCENTAGE: "appFormCompletionPercentage",
  WINDOW_REFRESH_COUNT:"windowRefreshCount",
  IS_MASTER_DATA_FROM_GZIP_FEATURE_ACTIVE:
    "masterData_from_GZIP_Feature_isActive",
  REQUEST_MASTERDATA_FROM_GZIP: "request_MasterData_From_GZIP",
  REQUEST_STORE_SETTING_FROM_GZIP: "request_Store_Setting_From_GZIP",
  REQUEST_CUSTOM_MASTER_FROM_JSON: "request_custom_MasterData_From_JSON",
  REQUEST_CUSTOM_MASTER_FROM_GZIP: "request_custom_MasterData_From_GZIP",
  MAPPED_CODE: "mappedCode",
  RESET_FORM_DATA: "resetFormData",
  ID:"id",
  SHOW_INSTRUCTION_MODAL:"show_instruction_modal",
  MILE_STONE_PAYMENT_RECEIPT_SEQUENCE_MASTER_KEY: "MilestonePaymentReceiptSequence",
  PART_PAYMENT_KEY:"ppKey",
  PART_PAYMENT_AMOUNT_KEY:"ppamt"
};
export const STEP_STATUS_CSS_CLASS = {
  COMPLETED: "completed",
  STARTED: "visited",
  DISABLED: "disabled",
  CURRENT_SELECTED: "current",
};
/**
 * @description use in entire development of specify on which storage [Local (permenent) or session(temp)] need to user for getItem, setItem, deleteItem or clear opreation
 * @summary Use this for doing local storage opration
 */
export const STORAGE_TYPE = {
  LOCAL: true,
  SESSION: false,
  COOKIES: "cookies"
};
/**
 * @description Used to set Page type at top (root) level of component
 */
export const PAGE_TYPE = {
  DASHBOARD: 1,
  STEP: 2,
  ADDCOURSE: 3,
};
/**
 * @description Used to set current step number
 */
export const STEP_NUMBER = {
  NO_SELECTED: 0,
  STEP_1: 1,
  STEP_2: 2,
  STEP_3: 3,
  STEP_4: 4,
};
/**
 * @description All form input UI types used in config
 */
export const FORM_INPUT_UI = {
  BUTTON: "button",
  CHECKBOX: "checkbox",
  DATE: "date",
  EMAIL: "email",
  FILE: "file",
  HIDDEN: "hidden",
  NUMBER: "number",
  PASSWORD: "password",
  RADIO: "radio",
  RANGE: "range",
  RESET: "reset",
  SEARCH: "search",
  SUBMIT: "submit",
  TEL: "tel",
  TEXT: "text",
  URL: "url",
  SELECT: "select",
  TEXTAREA: "textarea",
  GRID: "grid",
  GRID2: "grid2",
  GRID3: "grid3",
  BOOLEAN: "boolean",
  MOBILE: "mobile",
  MOBILE2: "mobile2",
  APPLIED_COURSE_LIST: "appliedCourseList",
  PROMO_CODE: "promoCode",
  STATIC: "static",
  COURSE_FEES_LIST: "courseFeesList",
  SLOT_BOOKING: "slotBooking",
  SELECT_LIST: "list",
  ACTION_BUTTON: "actionButton",
  PAYMENT_TRANSACTION_HISTORY_TABLE: "paymentTransactionHistoryTable",
  PREFERENCE_LIST_TABLE: "preferenceListTable",
  DATE_DURATION: "dateDuration",
  UNIQUECHECK:"uniqueCheckId",
  INSTALLMENTAMOUNT: "installmentAmount",
  INPUT_INSTALLMENTAMOUNT: "inputInstallmentAmount"
};
/**
 * @description All form input types used in config
 */
export const FORM_INPUT_TYPES = {
  STRING: "string",
  DATE: "date",
  NUMBER: "number",
  ARRAY: "array",
};
/**
 * @description Default values for form input used when any input's config will missing.
 */
export const FORM_INPUT_DEFAULT_PARAMETERS = {
  type: "text",
  ui: FORM_INPUT_UI.TEXT,
  maxLength: 200,
  layoutRules: {
    width: "HALF_BLOCK",
    moveToNewRow: false,
    inlineList: false,
  },
  captions: {
    yes: "Yes",
    no: "No",
  },
};

export const FORM_INPUT_VALUE_FORMAT = {
  date: "d-M-yyyy",
  TABLE_DATE: "DD-MM-YYYY",
  TABLE_DATE_YEAR: "YYYY-MM-DD",
  FULL_DATE_ON_SCREEN:"DD-MMM-YYYY hh:mm A",
  DATE_TIME: "lll"
};
/**
 * @description Default values for form input UI used when any input's UI config will missing.
 */
export const FORM_INPUT_DEFAULT_UI = {
  boolean: FORM_INPUT_UI.CHECKBOX,
  string: FORM_INPUT_UI.INPUT,
  date: FORM_INPUT_UI.INPUT,
};
/**
 * @description RegEx pattens which will bind with input UI type
 */
//email: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$",
//url: "https?://.+",
//password -  "(?=.*d)(?=.*[a-z])(?=.*[A-Z]).{8,}"
//mobile: /^(\+\d{1,3}[- ]?)?\d{6,13}$/,
//  email: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
//  email: /^\w+[a-zA-Z0-9_.][+@[a-zA-Z0-9_.-]+?\.[a-zA-Z]{2,3}$/, //new regex
export const FORM_INPUT_PATTERN = {
  email: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  url: "^http[s]?:\/\/(www\.)?(.*)?\/?(.)*",
  mobile: /\d*/,
  password: /^([1-zA-Z0-1@.\s]{1,255})$/ /*email@email.com | My Name | asdf12df */,
  text: /^([a-zA-Z]{1})(?=.*[a-zA-Z])([a-zA-Z0-9 ]+)$/,
  inputInstallmentAmount: /^[0-9]*$/
};
/**
 * @description Globally used configuration for all file upload
 */
export const FORM_INPUT_FILE_PARAMETERS = {
  VALID_FILE_TYPES: [".jpg", ".jpeg", ".png", ".pdf", ".doc", ".docx"],
  VALID_MAX_FILE_SIZE_IN_KB: 1000,
};

export const PHOTO_FILE_PARAMETERS = {
  VALID_FILE_TYPES: [".jpg", ".jpeg", ".png"],
  VALID_MAX_FILE_SIZE_IN_KB: 2000,
  VALID_FILENAME_LENGTH: 30,
};

export const FORM_LAYOUT_PARAMETERS_KEYS = {
  LAYOUT_RULES: "layoutRules",
  WIDTH: "width",
  MOVE_TO_NEW_ROW: "moveToNewRow",
  HALF_BLOCK: 6,
  FULL_BLOCK: 12,
  INLINE_LIST: "inlineList",
  OFFSET: "offset",
  IS_CLEARABLE: "isClearable"
};

export const FORM_INPUT_ANIMATION_DETAILS = {
  CSS_CLASS: " ",
};

//All Api Endpoints
export const API_END_POINTS = {
  //Upload media
  upload_Media: REQUEST_API.BASE_URL + "/email/uploadMedia/",
};
//button dynamic event types
export const BUTTON_EVENT_TYPE = {
  SUBMIT_FORM: "triggerSubmitForm",
  TRIGGER_FORGET_PASSWORD: "triggerForgetPassword",
  TRIGGER_LOGIN: "triggerLogin",
  TRIGGER_HYPERLINK: "triggerHyperlink",
  TRIGGER_DASHBAORD: "goToDashbaord",
  TRIGGER_PREVIEW: "preview",
  TRIGGER_SUBMIT_FORM: "submitForm",
  TRIGGER_SAVE_FORM: "saveForm",
  TRIGGER_SAVE_PARTIAL_FORM: "savePartialForm",
  TRIGGER_GOTO_BACK: "gotoBack",
  TRIGGER_GOTO_NEXT: "gotoNext",
  TRIGGER_GOTO_PREVIOUS_STEP: "gotoPreviousStep",
  TRIGGER_GOTO_NEXT_STEP: "gotoNextStep",
  TRIGGER_ADD_COURSE: "addCourse",
  TRIGGER_PAYNOW: "payNow",
  TRIGGER_VIEWPAY: "viewPay",
  TRIGGER_PAYNEXT: "payNext",
  TRIGGER_SUBSCRIBE: "subscribe",
  TRIGGER_FORM: "gotoForm",
  TRIGGER_CLOSE_POPUP: "closePopup",
  TRIGGER_LOGOUT: "triggerLogout",
  TRIGGER_RESETGRID3: "resetGrid3",
  TRIGGER_PAYNOW_COURSE_FEES: "payNowCourseFee",
  TRIGGER_PAYNEXT_COURSE_FEES: "payNextCourseFee",
  TRIGGER_VIEWPAY_COURSE_FEES: "viewPayCourseFee",
  TRIGGER_SUBSCRIBE_COURSE_FEES: "subscribeCourseFee",
  TRIGGER_PAYNOW_NOTIFICATION: "showNotificationPayNow",
  TRIGGER_PAYMENT_RECEIPT: "downloadPaymentReceipt",
  TRIGGER_GRID_ADD_MORE: "grid3AddMoreRow",
  ACTION_BUTTON: "actionButton",
  LEAD_DATA_ACTION_BUTTON: "leadDataActionButton",
  TRIGGER_MILESTONE_PAYMENT_RECEIPT: "downloadmileStonePaymentReceipt"
};
//validation error type
export const VALIDATION_ERROR = {
  REQUIRED: {
    errorCode: 1,
    errorMessage: "global.validationMessage.required",
  },
  INVALID_INPUT: {
    errorCode: 2,
    errorMessage: "global.validationMessage.invalid",
  },
  ALL_FIELD_REQUIRED: {
    errorCode: 3,
    errorMessage: "global.validationMessage.invalid",
  },
  ALREADY_EXIST: {
    errorCode: 4,
    errorMessage: "global.validationMessage.exist",
  },
  USER_NOT_FOUND: {
    errorCode: 5,
    errorMessage: "global.validationMessage.userNotFound",
  },
  UPDATE_LEAD_FAIL: {
    errorCode: 6,
    errorMessage: "global.validationMessage.updateLeadFail",
  },
  CONFIRM_PASSWORD_NOT_SAME: {
    errorCode: 7,
    errorMessage: "global.validationMessage.confirmPasswordNotSame",
  },
  RESET_PASSWORD_FAILED: {
    errorCode: 8,
    errorMessage: "global.validationMessage.resetPasswordFailed",
  },
  UNIQUE_FIELD_VALUE_INPUT_TYPE_FAILED: {
    errorCode: 9,
    errorMessage: "global.validationMessage.uniqueFieldValueByFieldTypeFailed",
  },
  GRID_REQUIRED: {
    errorCode: 10,
    errorMessage: "global.validationMessage.gridMandatory",
  },
  EMAIL_EXISTS_IN_OTHER_LEAD: {
    errorCode: 11,
    errorMessage: "global.validationMessage.emailAlreadyExistsInOtherLead",
  },
  MOBILE_EXISTS_IN_OTHER_LEAD: {
    errorCode: 11,
    errorMessage: "global.validationMessage.mobileAlreadyExistsInOtherLead",
  },
  UPDATE_LEAD_FAIL_MANDATORY_FIELD_MISSING: {
    errorCode: 12,
    errorMessage: "global.validationMessage.updateLeadFail",
  },
  DATE_RANGE_VALIDATION: {
    errorCode: 13,
    errorMessage: "global.validationMessage.invalidDateRangeMessage",
  },
  INPUT_GRID_REQUIRED: {
    errorCode: 14,
    errorMessage: "global.validationMessage.inputGridRequired",
  }, 
  RESTRICT_SAME_VALUE_VALIDATION: {
    errorCode: 15,
    errorMessage: `global.validationMessage.restrictSameValue`,
  }
};

/**
 * @description - Root path of config storage used to create page config file path
 */
 export const BLOB_STORAGE_URL = "http://localhost:3000";

 export const BLOB_NAMES = {
  EERESOURCES: "extraaedgeresources",
 }
export const VALIDATION_MESSAGE_TOKEN = {
  FIELD_NAME: "$FIELD_NAME$",
  FIELD_VALUE: "$FIELD_VALUE$",
};

export const MASTER_KEYS = {
  TYPE: {
    DOUBLE: "double",
    INTEGER: "int",
    BOOLEAN: "boolean",
    TEXT: "text",
    DATE: "date",
  },
};
export const META_DATA_TYPE = {
  INDEX_META_TYPE: "indexedKeyValueAttributes",
  NON_INDEX_META_TYPE: "keyValueAttributes",
};

export const ACCESS_TOKEN_CLAIMS = {
  [STORAGE_KEY.COUNSELOR_ACCESS_LEAD_ID]: "http://www.extraaedge.com/2016/claims/leadKey",
  [STORAGE_KEY.COUNSELOR_ID]: "http://www.extraaedge.com/2016/claims/CId",
  [STORAGE_KEY.PRN]: "http://www.extraaedge.com/2016/claims/Prn",
  [STORAGE_KEY.INST]: "http://www.extraaedge.com/2016/claims/appCriterion",
  [STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY]: "http://www.extraaedge.com/2016/claims/Ukc",
  [STORAGE_KEY.ROLE]: "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
}

export const ACCESS_TOKEN_LEAD_KEY =
  "http://www.extraaedge.com/2016/claims/leadKey";

export const STEP_NAVIGATION_TYPE = {
  LINEAR: "linear",
  NON_LINEAR: "nonlinear",
};
export const STATIC_MASTER_COLLECTION = {
  ExaminationTypes: [
    { id: 1, name: "Semester", maxEntriesAllow: 8 },
    { id: 2, name: "Year", maxEntriesAllow: 4 },
  ],
};
export const LEAD_STATUS_KEYS = {
  STEP_1_COMPLETED: "STEP_1_COMPLETED",
  STEP_2_COMPLETED: "STEP_2_COMPLETED",
  STEP_3_COMPLETED: "STEP_3_COMPLETED",
  PAYMENT_INITIATED: "STEP_PAYMENT",
  PAYMENT_DONE: "PAYMENT_DONE",
  PAYMENT_FAIL: "PAYMENT_FAIL",
  CASH_INITIATED: "CASH_INITIATED",
  STEP_PAYMENT: "STEP_PAYMENT",
  STEP_COURSE_FEES_PAYMENT:"STEP_COURSE_FEES_PAYMENT"
};

export const LEAD_STATUS_KEYS_BY_FEESTYPE = { 1: "STEP_PAYMENT", 2: "STEP_COURSE_FEES_PAYMENT"};

export const CONTENT_TOKEN = {
  APPLICATION_STATUS: "$APPLICATION_STATUS$",
  LOGGED_USER_FIRST_NAME: "$LOGGED_USER_FIRST_NAME$",
  APPLICATION_NUMBER: "$APPLICATION_NUMBER$",
  GENDER: "$GENDER$",
  COURSE_APPLIED: "$COURSE_APPLIED$",
  LOGGED_USER_EMAIL: "$LOGGED_USER_EMAIL$",
  LOGGED_USER_MOBILE: "$LOGGED_USER_MOBILE$",
  LOGGED_USER_ENTITY1: "$LOGGED_USER_ENTITY1$",
  LOGGED_USER_ENTITY2: "$LOGGED_USER_ENTITY2$",
  LOGGED_USER_ENTITY3: "$LOGGED_USER_ENTITY3$",
  LOGGED_USER_ENTITY4: "$LOGGED_USER_ENTITY4$",
  LOGGED_USER_FULL_NAME: "$LOGGED_USER_FULL_NAME$",
  FIELD_KEY: "$FIELD_KEY$",
  TIME_STAMP: "$TIME_STAMP$",
};

/**
 * Use of this const is to fetch master data collection name against Criteria value set in store setting.
 * Ex. Application form Criteria or Unique key criteria will be `entity2` then
 * master data collection name against respective field will be fetch from
 * masterData.Entity2
 * masterData[MasterDataCollectionByCriteriaValue[`entity2`]] which is equal to masterData[`Entity2`]
 */
export const MasterDataCollectionByCriteriaValue = {
  entity1: "Entity1",
  entity2: "Entity2",
  entity3: "Entity3",
  entity4: "Entity4",
};
/**
 * Use of this const is to fetch field name against Criteria value from lead object.
 * Ex. Application form Criteria or Unique key criteria will be `entity2` then
 * value of against respective field will be fetch from
 * userDetails.Lead.entity2Id using below line of code
 * userDetails.Lead[EntityFieldNameByCriteriaValue[`entity2`]] which is equal to userDetails.Lead[`entity2Id`]
 */
export const EntityFieldNameByCriteriaValue = {
  entity1: "entity1Id",
  entity2: "entity2Id",
  entity3: "entity3Id",
  entity4: "entity4Id",
};
export const MATH_OPERATIONS = {
  DEFAULT: "sum",
  SUM: "sum",
  AVERAGE: "avg",
  MIN: "min",
  MAX: "max",
};
export const PAYMENT_SUCCESS_STATUS = [
  "Success",
  "success",
  "TXN_SUCCESS",
  "active",
  "Loan Application Filled",
  "Loan Approved",
  "Settled",
  "Loan Disbursed",
  "Loan Agreement Signed",
  "Setup Done",
  "pending",
  "SUCCESS",
  "Captured",
  "captured",
  "Shipped",
  "Successful",
  "PAID"
];
export const PAYMENT_FAIL_STATUS = [
  "Fail",
  "FailStatus",
  "Failure",
  "fail",
  "FAILED",
  "Failed",
  "Aborted",
  "Unsuccessful",
  "Invalid",
  "Timeout"
];

export const PAYMENT_TRANSACTION_HISTORY_STATUS = {
  SUCCESS: "success",
  FAILED: "failed"
}

export const PROMOCODE_RESPONSE = {
  EXPIRED: "expired",
  INVALID: "invalid",
  ALREADY_USED: "used",
  APPLIED_SUCCESSFULLY: "success",
};
export const PROMOCODE_DISCOUNT_TYPE = {
  AMOUNT: "amount",
  PERCENTAGE: "percentage",
};
export const HARD_CODED_FIELD_KEYS = {
  TOTAL_AMOUNT_TO_PAY: "totalAmountToPay",
  TOTAL_DISCOUNTED_AMOUNT_TO_PAY: "totalDiscountedAmountToPay",
  INSTALLMENT_AMOUNT_TO_PAY: "installmentAmountToPay",
  INSTALLMENT_AMOUNT: "installmentAmount",
  SELECTED_COURSE_FOR_PAYMENT: "selectedCourseForPayment",
  APPLIED_PROMOCODE: "appliedPormoCode",
  ZERO_AMOUNT_TO_PAY: "zeroAmountToPay",
  NON_ZERO_AMOUNT_TO_PAY: "nonZeroAmountToPay",
  ZERO_AMOUNT_TO_PAYMENT_METHOD_ID: "zeroAmountToPaymentMethodId",
  FEES_TYPE: "feesType",
  I_AGREE_PAYMENT_TERMS: "iAgreePaymentTerm",
  PAYMENT_MODE: "paymentMethod",
  PROMO_CODE: "promocode",
  PREF_COURSES_INFO: "prefCourseInfo",
  CURRENCY_FIELD: "currency",
  TOTAL_AMOUNT_TO_PAY_ON_CURRENCY_APPLIED: "totalAmountToPayOnCurrencyApplied",
  TOTAL_DISCOUNTED_AMOUNT_TO_PAY_ON_CURRENCY_APPLIED: "totalDiscountedAmountToPayOnCurrencyApplied",

  TOTAL_AMOUNT_TO_PAY_CF: "totalAmountToPay_cf",
  TOTAL_DISCOUNTED_AMOUNT_TO_PAY_CF: "totalDiscountedAmountToPay_cf",
  INSTALLMENT_AMOUNT_TO_PAY_CF: "installmentAmountToPay_cf",
  INSTALLMENT_AMOUNT_CF: "installmentAmount_cf",
  SELECTED_COURSE_FOR_PAYMENT_CF: "selectedCourseForPayment_cf",
  APPLIED_PROMOCODE_CF: "appliedPormoCode_cf",
  ZERO_AMOUNT_TO_PAY_CF: "zeroAmountToPay_cf",
  NON_ZERO_AMOUNT_TO_PAY_CF: "nonZeroAmountToPay_cf",
  ZERO_AMOUNT_TO_PAYMENT_METHOD_ID_CF: "zeroAmountToPaymentMethodId_cf",
  FEES_TYPE_CF: "feesType_cf",
  I_AGREE_PAYMENT_TERMS_CF: "iAgreePaymentTerm_cf",
  PAYMENT_MODE_CF: "paymentMethod_cf",
  PROMO_CODE_CF: "promocode_cf",
  CURRENCY_FIELD_CF: "currency_cf",
  TOTAL_AMOUNT_TO_PAY_ON_CURRENCY_APPLIED_CF: "totalAmountToPayOnCurrencyApplied_cf",
  TOTAL_DISCOUNTED_AMOUNT_TO_PAY_ON_CURRENCY_APPLIED_CF: "totalDiscountedAmountToPayOnCurrencyApplied_cf",
};
export const HIDE_FIELDS_FOR_INCORRECT_AND_MAX_INSTALLMENT = [
  "zeroAmountToPay",
  "nonZeroAmountToPay",
  "zeroAmountToPaymentMethodId",
  "iAgreePaymentTerm",
  "paymentMethod",
  "appliedPormoCode",
  "promocode",
  "prefCourseInfo",
  "currency",
  "appliedPormoCode_cf",
  "zeroAmountToPay_cf",
  "nonZeroAmountToPay_cf",
  "zeroAmountToPaymentMethodId_cf",
  "iAgreePaymentTerm_cf",
  "paymentMethod_cf",
  "promocode_cf",
  "currency_cf",
]
export const FEES_TYPE_2_ALL_FILDES_KEYS=["feesType_cf","courseFeesList_cf","nonZeroAmountToPay_cf","zeroAmountToPay_cf","zeroAmountToPaymentMethodId_cf","selectedCourseForPayment_cf","appliedPormoCode_cf","totalAmountToPay_cf","totalDiscountedAmountToPay_cf","paymentMethod_cf","promoCode_cf","iAgreePaymentTerm_cf"]
export const PRICING_CRITERIA_2_KEYS = {
  DATA_KEY: "dataKey",
  DATA_TYPE: "dataType",
  CATEGORY: "category",
  CRITERIA_VALUE: "criterionValue",
  AMOUNT: "amount",
  AMOUNT2: "amount2",
  FEES_TYPE: "feesType",
};

export const FEES_TYPE = {
  APPLICATION_FORM_FEES: 1,
  COURSE_FEES: 2,
};
export const MOBILE_VALIDATE_LAST_TEN_DIGIT = true;
export const STEP_COMPLETION_POPUP_APPEARANCE = {
  ALWAYS: "ALWAYS",
  ONCE: "ONCE",
};

export const CONDITIONAL_SHOWHIDE_KEYS = [
  "showFields",
  "hideFields",
  "showPanels",
  "hidePanels",
  "conditional",
];

export const LEAD_EVENT_MASTER_KEYS = {
  APPLICATION_FIRST_LOGIN_DATE: "ApplicationFirstLoginDate",
  APPLICATION_LAST_ACTIVE_DATE: "ApplicationLastActiveDate",
  APPLICATION_ONLINE_STATUS: "isLeadOnline",
};

export const HISTORY_VALUES_KEY = {
  LEAD_STATUS: "statusId",
};
export const OPERATOR = {
  AND: "AND",
  OR: "OR",
};
export const REQUEST_TYPE = {
  get: "get",
  post: "post",
  delete: "delete",
};

export const DEFAULT_PROMO_CODE_MASTER_KEY = {
  1: "DefaultCouponCodeFeeTypeOne",
  2: "DefaultCouponCodeFeeTypeTwo",
};

export const GRID_MANDATORY_VALIDATION_MSG = "Please fill up atleast 1 row in the given table";

export const TABLE_MODAL_BUTTONS_NAME = {
  ADD_MORE: "btnAddNewEntry",
  SAVE_AND_CLOSE: "btnSaveAndCloseEntry",
  OPEN_MODAL_BTN_LABEL: "ADD DETAILS",
}

export const OVERWRITE_NAMES_BY_IDS_IN_ADD_LEAD_REQUEST_PARAMS={
  "mapping":{
    "ArrE1":"ArrE1Ids",
    "ArrE2":"ArrE2Ids",
    "ArrE3":"ArrE3Ids",
    "ArrE4":"ArrE4Ids"
    }
}
export const PAYMENT_RECEIPT_TEMPLATE={
  APPLICATION_FORM_FEES:"payment_receipt_template.html",
  COURSE_FEES:"payment_receipt_template_feestype_2.html"
}
export const API_REQUEST_BODY={
  GENERATE_AND_SEND_MAIL_RECEIPT:{
      "AuthToken": "$AUTH_TOKEN$",
      "IsEmailSend": "$IS_EMAIL_SEND$",
      "IsPaymentDone": "$IS_PAYMENT_DONE$",
      "Source": "$SOURCE$",
      "inst": "$INST$",
      "leadId": "$LEAD_ID$",
      "ukc": "$UKC$",
      "pdfNameToken": "Payment_Receipt_$RECEIPT_NUMBER$",
      "receiptNumber": "$RECEIPT_NUMBER$",
      "templateFileName": "$TEMPLATE_HTML$",
      "emailTemplateName": "ApplicationFormPaymentReceiptEmailPdf",
      "feesType" : "$FEES_TYPE$",
      "isDebugMode":"$IS_DEBUG_MODE$"
  }
}
export const PRESENT_ADDRESS_KEYS = {
  PRESENT_COUNTRY :"presentCountry",
  PRESENT_STATE:"presentState",
  PRESENT_DISTRICT:"presentDistrict",
  PRESENT_CITY:"presentCity",
  PRESENT_ADDRESS_LINE_ONE:"presentAddressLine",
  PRESENT_ADDRESS_LINE_TWO:"presentAddressLineTwo",
  PRESENT_PINCODE:"presentPincode",
  PRESENT_PINCODE_ID:"presentPinCodeId"
}
export const PERMANENT_ADDRESS_KEYS = {
  PERMANENT_COUNTRY :"permenentCountry",
  PERMANENT_STATE:"permenentState",
  PERMANENT_DISTRICT:"permenentDistrict",
  PERMANENT_CITY:"permenentCity",
  PERMANENT_ADDRESS_LINE_ONE:"permenentAddressLine1",
  PERMANENT_ADDRESS_LINE_TWO:"permenentAddressLineTwo",
  PERMANENT_PINCODE:"permenentPincode",
  PERMANENT_PINCODE_NAME:"permenentPinCodeName"
}

export const ADD_NEW_GRID_BUTTON_CONTROLS = [
    {
        "name": "openModalButtonLabel",
        "label": "ADD DETAILS",
        "cssClass": ""
    }
]

export const GRID_INSTRUCTIONS = {
  ADDING_DETAILS: "Click to add the details",
  FILLUP_DETAILS: "Enter the details"
}

export const GRID_MODAL_OPEN_BUTTON_CONTROLS = [
  {
    "cssClass": "btn-saveAndCloseEntry btn-optional",
    "label":"Save & Close",       
    "name": "btnSaveAndCloseEntry",
    "triggerEvent": "saveAndCloseEntry"
   },
   {
    "cssClass": "btn-addNewEntry",
    "label": "+ Add More",
    "name": "btnAddNewEntry",
    "triggerEvent": "addNewEntry"
   }
   
]

export const PAYMENT_METHOD_ID = {
  KHALTI: 10,
  ADCB: 11,
  PAYTM_JS: 12,
  JODO: 14,
  GRAYQUEST: 16,
}

export const PAYMENT_METHOD_IDS_WHICH_HAS_DUPLICATE_REFRENCE_KEY = [
  PAYMENT_METHOD_ID.JODO,
];

export const FILE_CHOSEN_LABEL = {
  "noFileChosen": "No file chosen",
  "chooseFileBtnLabel": "Choose File"
}

export const MOBILE_REGEX_COUNTRY_WISE = {
  'PH': /^(\+?63|0)?9\d{9}$/,
  'AM': /^(\+?374|0)((10|[9|7][0-9])\d{6}$|[2-4]\d{7}$)/,
  'AE': /^((\+?971)|0)?5[024568]\d{7}$/,
  'BH': /^(\+?973)?(3|6)\d{7}$/,
  'DZ': /^(\+?213|0)(5|6|7)\d{8}$/,
  'EG': /^((\+?20)|0)?1[0125]\d{8}$/,
  'IQ': /^(\+?964|0)?7[0-9]\d{8}$/,
  'JO': /^(\+?962|0)?7[789]\d{7}$/,
  'KW': /^(\+?965)[569]\d{7}$/,
  'SA': /^(!?(\+?966)|0)?5\d{8}$/,
  'SY': /^(!?(\+?963)|0)?9\d{8}$/,
  'TN': /^(\+?216)?[2459]\d{7}$/,
  'BY': /^(\+?375)?(24|25|29|33|44)\d{7}$/,
  'BG': /^(\+?359|0)?8[789]\d{7}$/,
  'BD': /^(\+?880|0)1[13456789][0-9]{8}$/,
  'CZ': /^(\+?420)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'DK': /^(\+?45)?\s?\d{2}\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'DE': /^(\+49)?0?1(5[0-25-9]\d|6([23]|0\d?)|7([0-57-9]|6\d))\d{7}$/,
  'AT': /^(\+43|0)\d{1,4}\d{3,12}$/,
  'GR': /^(\+?30|0)?(69\d{8})$/,
  'AU': /^(\+?61|0)4\d{8}$/,
  'GB': /^(\+?44|0)7\d{9}$/,
  'GG': /^(\+?44|0)1481\d{6}$/,
  'GH': /^(\+233|0)(20|50|24|54|27|57|26|56|23|28)\d{7}$/,
  'HK': /^(\+?852[-\s]?)?[456789]\d{3}[-\s]?\d{4}$/,
  'MO': /^(\+?853[-\s]?)?[6]\d{3}[-\s]?\d{4}$/,
  'IE': /^(\+?353|0)8[356789]\d{7}$/,
  'IN': /^(\+?91|0)?[6789]\d{9}$/,
  'KE': /^(\+?254|0)(7|1)\d{8}$/,
  'MT': /^(\+?356|0)?(99|79|77|21|27|22|25)[0-9]{6}$/,
  'MU': /^(\+?230|0)?\d{8}$/,
  'NG': /^(\+?234|0)?[789]\d{9}$/,
  'NZ': /^(\+?64|0)[28]\d{7,9}$/,
  'PK': /^((\+92)|(0092))-{0,1}\d{3}-{0,1}\d{7}$|^\d{11}$|^\d{4}-\d{7}$/,
  'RW': /^(\+?250|0)?[7]\d{8}$/,
  'SG': /^(\+65)?[89]\d{7}$/,
  'SL': /^(?:0|94|\+94)?(7(0|1|2|5|6|7|8)( |-)?\d)\d{6}$/,
  'TZ': /^(\+?255|0)?[67]\d{8}$/,
  'UG': /^(\+?256|0)?[7]\d{8}$/,
  'US': /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
  'ZA': /^(\+?27|0)\d{9}$/,
  'ZM': /^(\+?26)?09[567]\d{7}$/,
  'CL': /^(\+?56|0)[2-9]\d{1}\d{7}$/,
  'EC': /^(\+?593|0)([2-7]|9[2-9])\d{7}$/,
  'ES': /^(\+?34)?(6\d{1}|7[1234])\d{7}$/,
  'MX': /^(\+?52)?(1|01)?\d{10,11}$/,
  'PA': /^(\+?507)\d{7,8}$/,
  'PY': /^(\+?595|0)9[9876]\d{7}$/,
  'UY': /^(\+598|0)9[1-9][\d]{6}$/,
  'EE': /^(\+?372)?\s?(5|8[1-4])\s?([0-9]\s?){6,7}$/,
  'IR': /^(\+?98[\-\s]?|0)9[0-39]\d[\-\s]?\d{3}[\-\s]?\d{4}$/,
  'FI': /^(\+?358|0)\s?(4(0|1|2|4|5|6)?|50)\s?(\d\s?){4,8}\d$/,
  'FJ': /^(\+?679)?\s?\d{3}\s?\d{4}$/,
  'FO': /^(\+?298)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'FR': /^(\+?33|0)[67]\d{8}$/,
  'GF': /^(\+?594|0|00594)[67]\d{8}$/,
  'GP': /^(\+?590|0|00590)[67]\d{8}$/,
  'MQ': /^(\+?596|0|00596)[67]\d{8}$/,
  'RE': /^(\+?262|0|00262)[67]\d{8}$/,
  'IL': /^(\+972|0)([23489]|5[012345689]|77)[1-9]\d{6}$/,
  'HU': /^(\+?36)(20|30|70)\d{7}$/,
  'ID': /^(\+?62|0)8(1[123456789]|2[1238]|3[1238]|5[12356789]|7[78]|9[56789]|8[123456789])([\s?|\d]{5,11})$/,
  'IT': /^(\+?39)?\s?3\d{2} ?\d{6,7}$/,
  'JP': /^(\+81[ \-]?(\(0\))?|0)[6789]0[ \-]?\d{4}[ \-]?\d{4}$/,
  'KZ': /^(\+?7|8)?7\d{9}$/,
  'GL': /^(\+?299)?\s?\d{2}\s?\d{2}\s?\d{2}$/,
  'KR': /^((\+?82)[ \-]?)?0?1([0|1|6|7|8|9]{1})[ \-]?\d{3,4}[ \-]?\d{4}$/,
  'LT': /^(\+370|8)\d{8}$/,
  'MY': /^(\+?6?01){1}(([0145]{1}(\-|\s)?\d{7,8})|([236789]{1}(\s|\-)?\d{7}))$/,
  'NO': /^(\+?47)?[49]\d{7}$/,
  'NP': /^(\+?977)?9[78]\d{8}$/,
  'BE': /^(\+?32|0)4?\d{8}$/,
  'NL': /^(\+?31|0)6?\d{8}$/,
  'PL': /^(\+?48)? ?[5-8]\d ?\d{3} ?\d{2} ?\d{2}$/,
  'BR': /(?=^(\+?5{2}\-?|0)[1-9]{2}\-?\d{4}\-?\d{4}$)(^(\+?5{2}\-?|0)[1-9]{2}\-?[6-9]{1}\d{3}\-?\d{4}$)|(^(\+?5{2}\-?|0)[1-9]{2}\-?9[6-9]{1}\d{3}\-?\d{4}$)/,
  'PT': /^(\+?351)?9[1236]\d{7}$/,
  'RO': /^(\+?4?0)\s?7\d{2}(\/|\s|\.|\-)?\d{3}(\s|\.|\-)?\d{3}$/,
  'RU': /^(\+?7|8)?9\d{9}$/,
  'SI': /^(\+386\s?|0)(\d{1}\s?\d{3}\s?\d{2}\s?\d{2}|\d{2}\s?\d{3}\s?\d{3})$/,
  'SK': /^(\+?421)? ?[1-9][0-9]{2} ?[0-9]{3} ?[0-9]{3}$/,
  'RS': /^(\+3816|06)[- \d]{5,9}$/,
  'SE': /^(\+?46|0)[\s\-]?7[\s\-]?[02369]([\s\-]?\d){7}$/,
  'TH': /^(\+66|66|0)\d{9}$/,
  'TR': /^(\+?90|0)?5\d{9}$/,
  'UA': /^(\+?38|8)?0\d{9}$/,
  'VN': /^(\+?84|0)((3([2-9]))|(5([2689]))|(7([0|6-9]))|(8([1-6|89]))|(9([0-9])))([0-9]{7})$/,
  'CN': /^((\+|00)86)?1([358][0-9]|4[579]|6[67]|7[01235678]|9[189])[0-9]{8}$/,
  'TW': /^(\+?886\-?|0)?9\d{8}$/,
  'QA': /^(974)?\d{8}$/,
  'PE': /^(\+?51|0)?9\d{8}$/,
  'BO': /^(\+?591|0)?(6|7)\d{7}$/,
  'VE': /^(\+?58|0)?(2|4)\d{9}$/,
  'GT': /^(\+?502)?[45]\d{7}$/,
  'CR': /^(\+?506)?[2-8]\d{7}$/,
  'HN': /^(\+?504)?[9]\d{7}$/,
  'NI': /^(\+?505)?[8]\d{7}$/,
  'SV': /^(\+?503)?[67]\d{7}$/,
  'BN': /^(\+?673|0)?8\d{6}$/,
  'KH': /^(\+?855|0)?\d{8,9}$/,
  'LA': /^(\+?856|0)?2[0-9]{7,8}$/,
  'BT': /^(\+?975|0)?1[7-9]\d{6}$/,
  'NP': /^(\+?977|0)9[7-8]\d{8}$/,
  'MV': /^(\+?960|0)?7[0-9]{6}$/,
  'LK': /^(\+?94|0)?7[0-9]{8}$/,
  'LR': /^(\+?231)?0?\d{8,9}$/,
  'ZW': /^(\+?263|0)?7[1-9]\d{7,8}$/
};

export const VERIFY_EMAIL_MODAL_CONFIG = {
  step_1: {
    title: "<h4>VERIFY YOUR EMAIL ID</h4>",
    description: "Please verify your Email Id before proceeding. If you do not verify it now, you may need to verify it in the next step.",
    body: [{
      id: "email",
      label: "Email Id",
      value: "$EMAIL$",
      disabled: true,
      type: "text"
    }],
    message: {
      title: "Do you want to edit your email id? ",
      action: "Go back to Dashboard"
    },
    footer: [{
      id: "sendOtp",
      className: "button-primary",
      label: "Send OTP"
    }]
  },
  step_2: {
    title: "<h4>VERIFY YOUR EMAIL ID</h4>",
    description: "Enter 4 digit OTP sent to your registered Email Id, <strong>$EMAIL$</strong>. If you are unable to find it, you may need to check your spam folder.",
    body: [{
      id: "otp",
      label: "OTP",
      value: "",
      disabled: false,
      type: "otpBox"
    }],
    footer: [{
      id: "resend",
      label: "RESEND",
      className: "button-secondary",
    },
    {
      id: "verifyOtp",
      label: "VERIFY",
      className: "button-primary",
      disabled: true
    }]
  },
  step_3: {
    title: "<div class='d-flex justify-content-center verified-title'><h4>EMAIL ID VERIFIED</h4></div>",
    description: "<div class='d-flex justify-content-center flex-column ml-auto mr-auto text-center w-75 verified-description'><br/>Your Email id - <strong>$EMAIL$</strong> has been succesfully verified </div>",
  }
}
export const VERIFY_MOBILE_MODAL_CONFIG = {
  step_1: {
    title: "<h4>VERIFY YOUR MOBILE NUMBER</h4>",
    description: "Please verify your Mobile No before proceeding. If you do not verify it now, you may need to verify it in further steps.",
    body: [{
      id: "mobileNumber",
      label: "Mobile Number",
      value: "$MOBILE_NUMBER$",
      disabled: true,
      type: "text"
    }],
    message: {
      title: "Do you want to edit your mobile number? ",
      action: "Go back to Dashboard"
    },
    footer: [{
      id: "sendOtp",
      className: "button-primary",
      label: "Send OTP"
    }]
  },
  step_2: {
    title: "<h4>VERIFY YOUR MOBILE NUMBER</h4>",
    description: "Enter 4 digit OTP sent to your registered Mobile No, <strong>$MOBILE_NUMBER$</strong>.",
    body: [{
      id: "otp",
      label: "OTP",
      value: "",
      disabled: false,
      type: "otpBox"
    }],
    footer: [{
      id: "resend",
      label: "RESEND",
      className: "button-secondary",
    },
    {
      id: "verifyOtp",
      label: "VERIFY",
      className: "button-primary",
      disabled: true
    }]
  },
  step_3: {
    title: "<div class='d-flex justify-content-center verified-title'><h4>MOBILE NUMBER VERIFIED</h4></div>",
    description: "<div class='d-flex justify-content-center flex-column ml-auto mr-auto text-center w-75 verified-description'><br/>Your Mobile Number - <strong>$MOBILE_NUMBER$</strong> has been succesfully verified </div>",
  }
}
export const EDIT_EMAIL_MODAL_CONFIG = {
  step_1: {
    title: "<h4>CHANGE EMAIL ID</h4>",
    description: "Please click below to recieve 4 digit OTP on your registered Mobile No to change your registered Email Id <strong>$EMAIL$</strong>.",
    body: [{
      id: "mobileNumber",
      label: "Mobile Number",
      value: "$MOBILE_NUMBER$",
      disabled: true,
      type: "text"
    }],
    footer: [{
      id: "sendOtp",
      className: "button-primary",
      label: "Send OTP"
    }]
  },
  step_2: {
    title: "<h4>CHANGE EMAIL ID</h4>",
    description: "Enter 4 digit OTP sent to your registered Mobile No to change your registerd Email Id <strong>$EMAIL$</strong>.",
    body: [{
      id: "otp",
      label: "OTP",
      value: "",
      disabled: false,
      type: "otpBox",
      typePattern: /^([0-9]{4})$/
      
    }],
    footer: [{
      id: "resend",
      label: "RESEND",
      className: "button-secondary",
    },
    {
      id: "verifyOtp",
      label: "VERIFY",
      className: "button-primary",
      disabled: true
    }]
  },
  step_3: {
    title: "<h4>CHANGE EMAIL ID</h4>",
    description: "Enter details to change your registered Email Id.",
    body: [{
        id: "oldEmailId",
        label: "Old Email Id",
        value: "$EMAIL$",
        disabled: true,
        type: "text"
      },
      {
        id: "newEmailId",
        label: "New Email Id",
        value: "",
        disabled: false,
        type: "text",
        typePattern: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      }],
    footer: [{
        id: "cancel",
        className: "button-secondary",
        label: "CANCEL"
      },
      {
        id: "change",
        className: "button-primary",
        label: "CHANGE"
      }]
  },
  step_4: {
    title: "<div class='d-flex justify-content-center verified-title'><h4>EMAIL ID CHANGED</h4></div>",
    description: "<div class='d-flex justify-content-center flex-column ml-auto mr-auto text-center w-75 verified-description'><br/>Your Email Id has been succesfully changed to <strong>$EMAIL$</strong>.</div>",
  }
}
export const EDIT_MOBILE_MODAL_CONFIG = {
  step_1: {
    title: "<h4>CHANGE MOBILE NUMBER</h4>",
    description: "Please click below to receive 4 digit OTP on your registered Email Id to change your registered Mobile No <strong>$MOBILE_NUMBER$</strong>.",
    body: [{
      id: "email",
      label: "Email Id",
      value: "$EMAIL$",
      disabled: true,
      type: "text"
    }],
    footer: [{
      id: "sendOtp",
      className: "button-primary",
      label: "Send OTP"
    }]
  },
  step_2: {
    title: "<h4>CHANGE MOBILE NUMBER</h4>",
    description: "Enter 4 digit OTP sent to your registered Email Id to change your registered Mobile No <strong>$MOBILE_NUMBER$</strong>.",
    body: [{
      id: "otp",
      label: "OTP",
      value: "",
      disabled: false,
      type: "otpBox",
      typePattern: /^([0-9]{4})$/,
      
    }],
    footer: [{
      id: "resend",
      label: "RESEND",
      className: "button-secondary",
    },
    {
      id: "verifyOtp",
      label: "VERIFY",
      className: "button-primary",
      disabled: true
    }]
  },
  step_3: {
    title: "<h4>CHANGE MOBILE NUMBER</h4>",
    description: "Enter details to change your registered Mobile No.",
    body: [{
        id: "oldMobileNumber",
        label: "Old Mobile Number",
        value: "$MOBILE_NUMBER$",
        disabled: true,
        type: "text"
      },
      {
        id: "newMobileNumber",
        label: "New Mobile Number",
        value: "",
        disabled: false,
        type: "mobile2"
      }],
    footer: [{
        id: "cancel",
        className: "button-secondary",
        label: "CANCEL"
      },
      {
        id: "change",
        className: "button-primary",
        label: "CHANGE"
      }]
  },
  step_4: {
    title: "<div class='d-flex justify-content-center verified-title'>MOBILE NUMBER CHANGED</div>",
    description: "<div class='d-flex justify-content-center flex-column m-auto text-center w-75 verified-description'><br/>Your registered Mobile No has been succesfully changed to <strong>$MOBILE_NUMBER$</strong>.</div>",
  }
}

export const VERIFICATION_AND_EDIT_ACTION_ITEMS = {
  VERIFY_EMAIL: "verifyEmail",
  EDIT_EMAIL: "editEmail",
  VERIFY_MOBILE: "verifyMobile",
  EDIT_MOBILE: "editMobile",
  WIDGET_VERIFY_MOBILE: "widgetVerifyMobile",
  SEND_OTP:"sendOtp"
}

export const OTP_VERIFICATION_API_RESPONSE = {
  SUCCESS : "success"
}

export const EMAIL_AND_MOBILE_VERIFICATION_ERRORS = {
  EMPTY : "$INPUT_LABEL$ cannot be empty. Please try again.",
  INVALID : "$INPUT_LABEL$ is invalid. Please try again.",
  ALREADY_EXISTS: "$INPUT_LABEL$ already exists.",
  API_ERROR: "Network Error",
  NO_CHANGE : "Old and $INPUT_LABEL$ are same. Please try again.",
  DEFAULT_ERROR: "Something went wrong!",
  OTP : {
    MISMATCH : "Entered OTP is incorrect. Please try again.",
    SEND_ERROR: "Error in sending the OTP. Please try again."
  }
}

export const COMPLETION_PERCENTAGE = {
  FEES_TYPE_1: 0,
  FEES_TYPE_2: 0
}

export const META_DATA_INDEXED_VALUE_TYPE = {
  DATE: "dateValue",
  DOUBLE: "doubleValue",
  INTEGER: "integerValue",
  BOOLEAN: "boolValue",
  STRING: "textValue" 
}
export const CONNECTION_ERROR = {
  "errorCheckCount": 1,
  "Modal": {
    "ModalHeader": "Unable to connect!",
    "ModalBody": "Please refresh the page",
    "ModalBtnLabel": "Refresh"
  }
}

export const MISSING_VALUES=`MISSING_VALUES`;

export const RESTRICTION_BEFORE_PAYMENT_MODAL = {
  title: "Unable to Pay",
  description: "Please make the changes on the given fields before proceeding to payment"
};

export const ENTITY_FIELD_COLLECTION = {
  Entity1: ["Entity1", "Entity1Id", "entity1", "entity1Id"],
  Entity2: ["Entity2", "Entity2Id", "entity2", "entity2Id"],
  Entity3: ["Entity3", "Entity3Id", "entity3", "entity3Id"],
  Entity4: ["Entity4", "Entity4Id", "entity4", "entity4Id"]
}

//Below is exception case base different names of data collection names come in below two kind of master data fetch flow
//from `required fields` master data response collection name come as `Religions` and `CitizenShips`
//but in `JSON` & `GZIP` master data response collection name come as `Religion` & `CitizenShip`
export const EXCEPTIONAL_DATACOLLECTION_MAPPING={Religions:"Religion",CitizenShips:"CitizenShip"};
export const API_FIELD_VALIDATION_TOKENS_AND_APIKEY = [
  {"token" : "%leadId%", "apiKey": "lead.id"}
]

export const DEFAULT_CURRENCY = {
  CurrencyName: "Indian Rupee",
  ISOCode: "INR"
}
export const PAYTM_BASE_URL = {
  TEST: "securegw-stage.paytm.in",
  PROD: "securegw.paytm.in"
}

export const REGEX = {
  TOKEN_REGEX: /%([^%]+)%/g,
  TOKEN_REGEX1: /\$(.*?)\$/g,
}

export const COMMUNICATON_ACTIVITY_TYPE_IDS = [2, 13, 105, 6]

export const ACTIVITIES_TYPES_MASTER_DATA_MAPPING = {
  6 :  "emailTemplates",
  13 :  "smsTemplates",
  105 :  "whatsAppTemplates",
}

export const  SORT_ORDER = {
  ASC : "asc",
  DESC : "desc"
}

export const COMMUNICATION_TABS = {
  ALL: "All",
  CALL: "Calls",
  SMS: "SMS",
  WHATSAPP: "WhatsApp",
  EMAIL: "Email",
};

export const VISIBLE_MY_APPLICATION_CARDS = 4;

export const TOKEN_VALUES_SEPEARTOR = " | ";

export const TOKEN_MAPPING_WITH_SINGLE_SELECT_FIELD_DATA_KEY = {
  Country: {
    dataKey: "countryId",
    masterDataCollectionName: "countries",
  },
  State: {
    dataKey: "stateId",
    masterDataCollectionName: "states",
  },
  City: {
    dataKey: "cityId",
    masterDataCollectionName: "cities",
  },
  District: {
    dataKey: "districtId",
    masterDataCollectionName: "districts",
  },
  Field1: {
    dataKey: "field1Id",
    masterDataCollectionName: "field1",
  },
  Field2: {
    dataKey: "field2Id",
    masterDataCollectionName: "field2",
  },
  Field3: {
    dataKey: "field3Id",
    masterDataCollectionName: "field3",
  },
  Field4: {
    dataKey: "field4Id",
    masterDataCollectionName: "field4",
  },
  Field5: {
    dataKey: "field5Id",
    masterDataCollectionName: "field5",
  },
  Field6: {
    dataKey: "field6Id",
    masterDataCollectionName: "field6",
  },
  Field7: {
    dataKey: "field7Id",
    masterDataCollectionName: "field7",
  },
  Field8: {
    dataKey: "field8Id",
    masterDataCollectionName: "field8",
  },
  Field10: {
    dataKey: "field10Id",
    masterDataCollectionName: "field10",
  },
  Field11: {
    dataKey: "field11Id",
    masterDataCollectionName: "field11",
  },
  Field12: {
    dataKey: "field12Id",
    masterDataCollectionName: "field12",
  },
  Field13: {
    dataKey: "field13Id",
    masterDataCollectionName: "field13",
  },
  Field14: {
    dataKey: "field14Id",
    masterDataCollectionName: "field14",
  },
  Field15: {
    dataKey: "field15Id",
    masterDataCollectionName: "field15",
  },
  Status: {
    dataKey: "leadStatusId",
    masterDataCollectionName: "leadStatuses",
  },
};

export const TOKEN_MAPPING_WITH_MULTI_SELECT_FIELD_DATA_KEY = {
  E1: {
    dataKey: "entity1Id",
    masterDataCollectionName: "entity1",
  },
  E2: {
    dataKey: "entity2Id",
    masterDataCollectionName: "entity2",
  },
  E3: {
    dataKey: "entity3Id",
    masterDataCollectionName: "entity3",
  },
  E4: {
    dataKey: "entity4Id",
    masterDataCollectionName: "entity4",
  },
  LeadChannelName: {
    dataKey: "leadChannelId",
    masterDataCollectionName: "leadChannels",
  },
  LeadCampaignName: {
    dataKey: "leadCampaignId",
    masterDataCollectionName: "leadCampaigns",
  },
  LeadMediumName: {
    dataKey: "leadMediumId",
    masterDataCollectionName: "leadMediums",
  },
  Source: {
    dataKey: "leadSourceId",
    masterDataCollectionName: "leadSources",
  },
  Type: {
    dataKey: "leadSourceTypeId",
    masterDataCollectionName: "sourceTypes",
  },
  SourceTo: {
    dataKey: "sourceToId",
    masterDataCollectionName: "leadSourceToIds",
  },
};

export const TOKEN_MAPPING_WITH_MASKING_FIELD_DATA_KEY = {
  ContactNumber: {
    dataKey: "mobileNumber",
    maskingConfigKey: "mobile",
    customFunction: replaceMobileWithStars
  },
  UniqueCheckId: {
    dataKey: "uniqueCheckId",
    maskingConfigKey: "uniqueCheckField",
    customFunction: replaceUniqueCheckFieldValueWithStars
  },
  Email: {
    dataKey: "email",
    maskingConfigKey: "email",
    customFunction: replaceEmailWithStars
  },
  Username: {
    dataKey: "email",
    maskingConfigKey: "email",
    customFunction: replaceEmailWithStars
  },
};

export const TOKEN_MAPPING_WITH_PORTAL_FIELDS = {

}

export const PAYMENT_RECEIPT_PATH = "ApplicationForm/Payment_Receipt";

export const BLOB_URL = "https://eeresources-cdn.azureedge.net/";

export const BLOB_FOLDERS = {
  EMAIL_ATTACHMENTS: "EmailAttachments",
  EMAIL_ATTACHMENTS_APPLICATION_FORM : "EmailAttachments/ApplicationForm"
}

export const DEFAULT_OPEN_APPLICATION_BTN_TEXT = "Open Application"

export const OPEN_APPLICATION_STEP_POSITION = {
  CURRENT: "current",
  FIRST: "first"
}
export const DATE_DUARATION_FORMAT = ["year", "month"];

export const MOVE_LEFT_RIGHT_ANIMATION_TIME = 100;

export const DASHBOARD_MEDIA_TYPES = {
  MEDIA_CAROUSEL: "mediaCarousel",
}

export const CAROUSEL_CARD_TYPE = {
  LINK_CARD: "link",
  PDF_CARD: "pdf"
}

export const SCROLL_BUTTONS = {
  LEFT: "left",
  RIGHT: "right"
}
export const MATH_INVALID_VALUES = ["Infinity", "Nan", "infinity", "nan", Infinity, NaN];

export const MAXIMUM_NUMBER_OF_TIMES_TO_CALL_LEAD_DTAIL_API = 2;

export const MANDATORY_PAYMENT_PARAM_KEYS = ["ppkey", "ppamt", "ppStatus", "ppReason"];

export const WEBHOOK_ADD_LEAD_API_KEY_MAPPED_WITH_DATA_KEY = {
  FirstName: "user.firstName",
  LastName: "user.lastName",
  DateField2: "user.dateField2",
  DateField3: "user.dateField3",
  Email: "user.email",
  MobileNumber: "user.mobileNumber",
  Field1: "user.field1Id",
  Field2: "user.field2Id",
  Field3: "user.field3Id",
  Field4: "user.field4Id",
  Field5: "user.field5Id",
  Field6: "user.field6Id",
  Field7: "user.field7Id",
  Field8: "user.field8Id",
  Field9: "user.field9Id",
  Field10: "user.field10Id",
  Field11: "user.field11Id",
  Field12: "user.field12Id",
  Field13: "user.field13Id",
  Field14: "user.field14Id",
  Field15: "user.field15Id",
  TextB1: "user.textb1",
  TextB2: "user.textb2",
  TextB3: "user.textb3",
  TextB4: "user.textb4",
  TextB5: "user.textb5",
  TextB6: "user.textb6",
  TextB7: "user.textb7",
  TextB8: "user.textb8",
  TextB9: "user.textb9",
  TextB10: "user.textb10",
  Textb1: "user.textb1",
  Textb2: "user.textb2",
  Textb3: "user.textb3",
  Textb4: "user.textb4",
  Textb5: "user.textb5",
  Textb6: "user.textb6",
  Textb7: "user.textb7",
  Textb8: "user.textb8",
  Textb9: "user.textb9",
  Textb10: "user.textb10",
  AlternateEmailAddress: "user.alternateEmailAddress",
  Entity4: "lead.entity4Id",
  ArrE1: "lead.entity1Id",
  ArrE2: "lead.entity2Id",
  ArrE3: "lead.entity3Id",
  ArrE4: "lead.entity4Id",
  Address: "user.address",
  BloodGroup: "user.bloodGroup",
  District: "user.districtId",
  PinCodeId: "user.pinCodeId",
  City: "user.cityId",
  Country: "user.countryId",
  DateOfBirth: "user.dateOfBirth",
  FathersName: "user.fathersName",
  FathersEmailId: "user.fathersEmail",
  FathersPhoneNumber: "user.fathersMobile",
  MiddleName: "user.middleName",
  MothersEmailId: "user.mothersEmail",
  MothersName: "user.middleName",
  MothersPhoneNumber: "user.mobileNumber",
  PinCode: "user.pincode",
  State: "user.stateId",
  AlternateMobileNumber: "user.alternateMobileNumber",
  DateField1: "user.dateField1",
  ArrE1Ids: "lead.entity1Id",
  ArrE2Ids: "lead.entity2Id",
  ArrE3Ids: "lead.entity3Id",
  ArrE4Ids: "lead.entity4Id",
  Gender: "user.gender",
  LeadChannel: "lead.leadChannelId",
  LeadCampaign: "lead.leadCampaignId",
  LeadMedium: "lead.leadMediumId",
  LeadSource: "lead.sourceId",
  LeadSources: "lead.sourceId",
  Source: "lead.sourceId",
  SourceTo: "lead.sourceToId",
  LeadType: "lead.sourceTypeId",
};

export const SKIP_LOCAL_STORAGE = [STORAGE_KEY.VERIFIED_INST, STORAGE_KEY.VERIFIED_ACCESS_TOKEN]

// Use for Mobile Numbers Default Value
export const DEFAULT_COUNTRY_CODE = {
  COUNTRY_CODE: "91",
  ISO_CODE: "IN",
}
export const API_KEY_NAME = {
  MobileNumber : "MobileNumber",
  MOBILE_OTP: "mobileOTP",
  MOBILE: "mobile",
  EMAIL : "email"
}
export const VERIFICATION_KEY_IDS = {
  PRIMARY_MOBILE_NUMBER : 5,
  PRIMARY_EMAIL : 1,
  ALTERNATE_MOBILE_NUMBER : 6,
  ALTERNATE_EMAIL : 2,
  FATHER_MOBILE_NUMBER : 7,
  FATHER_EMAIL : 3,
  MOTHERS_MOBILE_NUMBER : 8,
  MOTHERS_EMAIL_NUMBER : 4,
 }

 export const OTP_VERFICATION_SMS_TEMPLATE = "Hello, ##OTP## is your One Time Password(OTP). This OTP is valid till 3 mins - SRM University Delhi-NCR, Sonepat";

/**
 * API keys for the API's
 * key decode value of apiKey1Name 
 */
export const apiKey1s = "L2dNwK@7tYz!8F6b";
/**
 * API keys for the API's
 * apiKey1Name - API key 1 name is used for security purpose
*/
export const apiKey1Name = `X-Ee-Correlation-id`;
/**
 * API keys for the API's
 * apiKey2Name - API key 2 name is used for security purpose
*/
export const apiKey2Name = `x-er-id`;

 export const DB_KEY_IDS = {
  LEAD_SLOT_ALLOTMENT:"leadSlotAllotment"
 }
 
 export const DATA_COLLECTION_NAMES = {
  ALLOTMENT_SLOTS:"AllotmentSlots"
 }
export const LEAD_EESOURCE_IDS = {
  APPFORM : 16
};

/**
 * These payment methods open payment modal on same screen without redirecting to next page,
 * Global loader will stop for these payment methods 
 * //KHALTI: 10, ADCB: 11, PAYTM_MODAL: 12
*/
export const PAYMENT_METHODS_WITH_MODAL_ON_SAMEPAGE = [10,11,12];
