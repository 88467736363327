import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in hello world component */
export const actionTypes = {
  GET_CONTINUE_CONFIG: "app/pages/continue/config/GET",
  CONTINUE_CONFIG_REQUESTED: "app/pages/continue/config/REQUESTED",
  CONTINUE_CONFIG_SUCCESS: "app/pages/continue/config/SUCCESS",
  CONTINUE_CONFIG_FAILED: "app/pages/continue/config/FAILED",
  UPDATE_SERVER_ERROR: "app/pages/continue/server/ERROR"
};
/* Declear here all actions used in component */

/*****************************/
/* Get continue form config - actions */
/*****************************/
export const getConfig = createActionCreator(
  actionTypes.GET_CONTINUE_CONFIG,
  "data",
  "urlParam"
);
export const configRequested = createActionCreator(
  actionTypes.CONTINUE_CONFIG_REQUESTED,
  ""
);
export const configSuccess = createActionCreator(
  actionTypes.CONTINUE_CONFIG_SUCCESS,
  "data"
);
export const configFailed = createActionCreator(
  actionTypes.CONTINUE_CONFIG_FAILED,
  "errorMessage"
);

export const updateServerError = createActionCreator(
  actionTypes.UPDATE_SERVER_ERROR,
  "error"
);
