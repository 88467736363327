// Function to fetch a single JSON file
export const fetchJson = (url) => {
  return fetch(url).then((response) => {
    if (!response.ok) {
      throw new Error(`Failed to fetch ${url}`);
    }
    return response.json();
  });
};

// Function to fetch multiple JSON files in parallel
export const fetchMultipleJson = (urls) => {
      const fetchPromises = urls.map(url => fetchJson(url));

      return Promise.allSettled(fetchPromises)
        .then(results => {
          // Filter out only the resolved promises
          const resolvedResults = results.filter(result => result.status === 'fulfilled');
    
          // Extract the data from resolved promises
          const jsonResponses = resolvedResults.map(result => result.value);
    
          // Process the JSON responses as needed
          console.log('Successfully fetched all JSON files in parallel');
          console.log('JSON Responses:', jsonResponses);
    
          return jsonResponses; // You can return the data if needed for further processing
        });
  }
