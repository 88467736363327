import { call, put, select, takeLatest } from "redux-saga/effects";
import { logError } from "../../infrastructure/logging/Logger";
import {
  actionTypes,
  configFailed,
  configRequested,
  configSuccess,
  forgetPasswordFail,
  forgetPasswordRequested,
  forgetPasswordSuccess
} from "./Actions";
import { Service } from "./Services";
export const getStoredConfig = state => state.forgetPasswordReducer.config;
export function* getConfig(action) {
  //CAll api
  try {
    yield put(configRequested(""));
    const storedConfig = yield select(getStoredConfig); // <-- get reducer data

    if (storedConfig && Object.keys(storedConfig).length > 0) {
      yield put(configSuccess(storedConfig));
    } else {
      const result = yield call(
        Service.getConfig,
        action.payload && !!action.payload.data ? action.payload.data : ""
      );
      yield put(configSuccess(result));
    }
  } catch (error) {
    logError(error);
    yield put(configFailed(JSON.stringify(error)));
  }
}
export function* validateForgetPassword(action) {
  //CAll api
  try {
    yield put(forgetPasswordRequested(""));
    const result = yield call(Service.forgetPassword, action.payload.loginInfo);
    !!result && (yield put(forgetPasswordSuccess(result)));
  } catch (error) {
    logError(error);
    yield put(forgetPasswordFail(error));
  }
}
export function* watchConfigData() {
  yield takeLatest(actionTypes.GET_FORGET_PASSWORD_CONFIG, getConfig);
}
export function* watchForgetPassword() {
  yield takeLatest(actionTypes.FORGET_PASSWORD_INTI, validateForgetPassword);
}
