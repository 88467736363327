/*
 * <copyright company="Extraaedge Pvt Ltd">
 *        copyright (c) Extraaedge Pvt Ltd.  All rights reserved.
 * </copyright>
 */

import { createStore, applyMiddleware, compose } from "redux";
import createSagaMiddleware, { END } from "redux-saga";
import { rootReducer } from "./RootReducers";
import { rootSaga } from "./RootSagas";
export function configureStore(initialState) {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(rootSaga);
  store.close = () => store.dispatch(END);
  return store;
}
