import { mockData, mockSetting, mockToken, MockUserInfo_v2 } from "./mockData";

export function setupGetMasterMock(mock) {
  mock
    .onGet(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getAllMaster
        ? window.extraaedge.API_END_POINT.getAllMaster
        : "/common/GetRequiredMasters"
    )
    .reply(200, mockData);
  mock
    .onGet(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getAllMasterSetting
        ? window.extraaedge.API_END_POINT.getAllMasterSetting
        : "/common/GetStoreSettings"
    )
    .reply(200, mockSetting);
  mock
    .onPost(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.login
        ? window.extraaedge.API_END_POINT.login
        : "/login/validate"
    )
    .reply(200, mockToken);
  mock
    .onGet(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getuserdetails
        ? window.extraaedge.API_END_POINT.getuserdetails
        : "/leads/leadDetails/"
    )
    .reply(200, MockUserInfo_v2);
}
