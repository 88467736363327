import { Configuration } from "../configuration/appsettings";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { renderApp } from "../..";
import { fetchMultipleJson } from "../network/HttpUtilities";
import { checkIfArray } from "../../shared/utilties/Utils";

i18n.use(LanguageDetector).init({
  fallbackLng: "en",
  debug: false,
  detection: { order: ["navigator", "htmlTag"] },
  // have a common namespace used around the full app
  ns: ["translations"],
  defaultNS: "translations",
  keySeparator: ".", // we use content as keys
  backend: {
    crossDomain: true
  },
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ","
  },
  react: {
    wait: true
  }
});

//Add all languages
let totalLnResources = Object.keys(Configuration.supportedLanguages).length;
let loadedLnResource = 0;
Object.keys(Configuration.supportedLanguages).forEach(async (index) => {
  const lang = Configuration.supportedLanguages[index];
  /*i18n.addResourceBundle(
    lang,
    "translations",
    require("../../shared/resources/" + lang + ".json"),
    true
  );*/
  let url =
    window &&
    window.extraaedge &&
    window.extraaedge.client &&
    window.extraaedge.client.lang &&
    !!window.extraaedge.client.lang[lang]
      ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.lang[lang]}`
      : "";
      
  let url_root_folder =
  window &&
  window.extraaedge &&
  window.extraaedge.client &&
  window.extraaedge.client.lang &&
  !!window.extraaedge.client.lang[`${lang}_root`]
    ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.lang[`${lang}_root`]}`
    : "";
    if(!!url_root_folder && url_root_folder!== url){
      //load mulitple lang file i.e. from inst folder and `appform` folder  
      const JsonFilesData = await fetchMultipleJson([url,url_root_folder])
      .then((data) => {
        if(checkIfArray(data)){
          return data[0];
        }
      }).then(function (myJson) {
        i18n.addResourceBundle(lang, "translations", myJson, true);
        loadedLnResource++;
        loadedLnResource === totalLnResources &&
          renderApp({ langFilesLoaded: true });
        return myJson;
      })
      .catch((error) => {
        // Handle the error if needed        
      });
    }else{
      fetch(url)
    .then(function(response) {
      return response.json();
    })
    .then(function(myJson) {
      i18n.addResourceBundle(lang, "translations", myJson, true);
      loadedLnResource++;
      loadedLnResource === totalLnResources && renderApp({langFilesLoaded:true});
      return myJson;
    });
    }  
});

//i18n.changeLanguage('fr');
export const t = (key, defaultValue, options) => {
  const {namespace = i18n.options.defaultNS} = options || {};
  const translation = i18n.t(key, { ns: namespace });
  return translation !== key ? translation : defaultValue || key;
};

export default i18n;
