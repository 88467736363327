import { API } from "../../infrastructure/network/HttpClient";
import { logError } from "../../infrastructure/logging/Logger";
import { OfflineStorage } from "../../shared/utilties/OfflineStorage";
import { STORAGE_KEY, STORAGE_TYPE } from "../../shared/utilties/Constants";
export const Service = {
  getConfig: function(url) {
    //return API.get("/login/config").finally(() => {});
    return fetch(url)
      .then(function(response) {
        return response.json();
      })
      .then(function(myJson) {
        return myJson;
      });
  },

  resetPassword: function(param) {
    /*let fd = new FormData();
    if (param && Object.keys(param).length) {
      Object.keys(param).map(key => {
        fd.append(key, param && !!param[key] ? param[key] : "");
        return true;
      });
      fd.append("deviceType", "Web"); //"Web" - identify device logic need to add here
      return API.post(
        window &&
          window.extraaedge &&
          window.extraaedge.API_END_POINT &&
          !!window.extraaedge.API_END_POINT.resetPassword
          ? window.extraaedge.API_END_POINT.resetPassword
          : "",
        fd
      );
    } else {
      logError(new Error("Reset password request found without parameter"));
    }*/
    let fd = {};
    if (param && Object.keys(param).length) {
      Object.keys(param).map(key => {
        fd[key] = param && !!param[key] ? param[key].toString() : "";
        return true;
      });
    }
    if (!!OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)) {
      fd[STORAGE_KEY.APP_CRITERION_KEY] = parseInt(
        OfflineStorage.getItem(STORAGE_KEY.INST, STORAGE_TYPE.LOCAL)
      );
    }
    
    if (!!OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, STORAGE_TYPE.LOCAL)) {
      fd[STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY] = parseInt(
        OfflineStorage.getItem(STORAGE_KEY.UKC_VALUE_URL_PARAMETER_KEY, STORAGE_TYPE.LOCAL)
      );
    }
    //"Web" - identify device logic need to add here
    if (fd && Object.keys(fd).length) {
      return API.post(
        window &&
          window.extraaedge &&
          window.extraaedge.API_END_POINT &&
          !!window.extraaedge.API_END_POINT.resetPassword
          ? window.extraaedge.API_END_POINT.resetPassword
          : "",
        fd
      );
    } else {
      logError(new Error("Reset password request found without parameter"));
    }
  }
};
