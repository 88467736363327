import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import ButtonLoader from "../ButtonLoader";
const GenricModal = (props) => {
  const {
    show,
    toggleModal,
    isTitleHtml,
    modalTitle,
    id,
    isBodyHtml,
    modalBody,
    isFooterVisible = true,
    cancelBtnLable,
    disabledModalOkBtn,
    modalProps = { className: "generic-modal" },
    modalHeaderProps = {},
    modalFooterProps = {},
    okFunction,
    cancelFunction,
    okFunParams,
    cancelFunParams,
    actionBtnlabel,
    showButtonLoader = false,
  } = props;

  const onSubmit = () => {
    if (okFunction) {
      !!okFunParams ? okFunction(okFunParams) : okFunction();
    } else {
      toggleModal();
    }
  };
  const onCancel = () => {
    if (cancelFunction) {
      !!cancelFunParams ? cancelFunction(cancelFunParams) : cancelFunction();
    } else {
      toggleModal();
    }
  };

  return (
    <Modal isOpen={show} {...(modalProps || {})} id={id} toggle={toggleModal}>
      <ModalHeader toggle={toggleModal} {...modalHeaderProps}>
        {!!isTitleHtml ? (
          <div
            className="modal-container"
            dangerouslySetInnerHTML={{ __html: modalTitle }}
          />
        ) : (
          modalTitle
        )}
      </ModalHeader>
      <ModalBody>
        {!!isBodyHtml ? (
          <div
            className="modal-container "
            dangerouslySetInnerHTML={{ __html: modalBody }}
          />
        ) : (
          modalBody
        )}
      </ModalBody>
      {isFooterVisible && (
        <ModalFooter {...modalFooterProps}>
          {cancelBtnLable ? (
            <Button
              id="cancelModalBtn"
              className="btn-cancel"
              onClick={onCancel}
              key="btnCancel"
              style={{
                backgroundColor: "white",
                border: "1px solid rgb(216, 216, 216)",
                borderRadius: "8px",
              }}
            >
              {cancelBtnLable}
            </Button>
          ) : null}
          {actionBtnlabel ? (
            <ButtonLoader
              buttonId="submitModalBtn"
              buttonClassName="btn-Save"
              onClickFunction={onSubmit}
              disabled={disabledModalOkBtn ? disabledModalOkBtn : false}
              buttonColor={"primary"}
              buttonLabel={actionBtnlabel}
              isInProgress={showButtonLoader}
            />
          ) : null}
        </ModalFooter>
      )}
    </Modal>
  );
};
export default GenricModal;
