import { all, call, put, select, take, takeLatest } from "redux-saga/effects";
import { logError } from "../../infrastructure/logging/Logger";
import { actionTypes as appActionTypes, masterDataCompleted, masterDataFail, userDetailFailed, userDetailSuccess } from "../../shared/services/appData/Actions";
import { isEmpty } from "../../shared/utilties/Utils";
import { actionTypes, configFailed, configRequested, configSuccess, getSectionAdvtDataFailed, getSectionAdvtDataRequest, getSectionAdvtDataSuccess } from "./Actions";
import { Service } from "./Services";
import { get } from "lodash";

export const getconfigData = state => state.dashboardReducer.data;
export const getAllMasterData = state => state.appDataReducer.masterData;
export const getUserDetailData = state => state.appDataReducer.userDetail;

export function* getConfig(action) {
  //CAll api
  try {
    const masterData = yield select(getAllMasterData); // <-- get reducer data
    const userDetail = yield select(getUserDetailData); // <-- get reducer data
    if (isEmpty(masterData)) {
      yield put({
        type: appActionTypes.MASTERDATA_INIT,
        payload: {
          data: {
            nextAction: actionTypes.GET_CONFIG,
            payload: action.payload && action.payload
          }
        }
      });
      yield take([masterDataFail, masterDataCompleted]);
    }
    //  if (isEmpty(userDetail)) {
    //   yield put({
    //     type: appActionTypes.USER_DETAIL_INIT,
    //     payload: {
    //       data: {
    //         nextAction: actionTypes.GET_CONFIG,
    //         payload: action.payload && action.payload
    //       },
    //       logFrom:"dashbaord-saga-getConfig"
    //     }
    //   });
    //   yield take([userDetailFailed, userDetailSuccess]);
    // } 
    yield put(configRequested(""));
    const config = yield select(getconfigData); // <-- get reducer data
    if (config && Object.keys(config).length > 0) {
      yield put(configSuccess(config));
    } else {
      let validResult = null;
      const rootFolderURL = get(window, "extraaedge.client.pageConfiguration.dashboard_root")
        ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.pageConfiguration.dashboard_root}`
        : ""    
      if(!!rootFolderURL && action.payload.data!==rootFolderURL){
        const resultAll = yield all([call(Service.getConfig,action.payload.data),call(Service.getConfig, rootFolderURL)]); 
        if(resultAll && Array.isArray(resultAll) && resultAll.length > 0){
          if(resultAll[0]!==null){
            validResult=resultAll[0];
          }else if(resultAll[1]!==null){
            validResult=JSON.parse(JSON.stringify(resultAll[1]));
          }
        }
      }else if(action.payload && !!action.payload.data){
        validResult = yield call(Service.getConfig, action.payload.data);
      }
      yield put(configSuccess(validResult));
    }

  } catch (error) {
    logError(error);
    yield put(configFailed(JSON.stringify(error)));
  }
}

export function* watchDashboardConfigData() {
  yield takeLatest(actionTypes.GET_CONFIG, getConfig);
}

export function* getSectionAdvtDataSaga(action) {
  //CAll api
  try {
    yield put(getSectionAdvtDataRequest(""));
    const result = yield call(
      Service.getSection,
      action.payload && action.payload.data
    );
    if (result) {
      yield put(getSectionAdvtDataSuccess(result));
    } else {
      yield put(getSectionAdvtDataFailed("Error"));
    }
  } catch (error) {
    logError(error);
    yield put(getSectionAdvtDataFailed(error));
  }
}

export function* watchgetSectionAdvtData() {
  yield takeLatest(actionTypes.GET_SECTION, getSectionAdvtDataSaga);
}