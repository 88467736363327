import React, { useEffect, useState } from "react";
import { CustomInput, FormFeedback, FormGroup, Label } from "reactstrap";
import { CustomCheckBoxProps } from "../../CustomPropsType";
const CustomCheckBox = ({
  id,
  type,
  label,
  visible,
  handleChange,
  error,
  value,
  disabled,
  required,
  cols,
  cssClass,
  pattern,
  addAppformGroupClass = false
}) => {
  const [isChecked, setIsChecked] = useState(!!value);
  const [className, setclassName] = useState(
    `col-12 ${addAppformGroupClass ? "ap-form-group" : ""} ${!!cssClass ? " " + cssClass : ""}${
      !!disabled ? " disabled" : ""
    }${!!error ? " is-invalid" : ""} col-md-${
      !cssClass || cssClass.indexOf("full-width") === -1 ? cols : "12"
    }`
  );
  const onChange = (event) => {
    handleChange(id, !!event.target.checked);
    //call props handle change
  };
  useEffect(() => {
    // domRef.current.focus();
    setIsChecked(!!value);
  }, [value]);
  useEffect(() => {
    // domRef.current.focus();
    setclassName(`col-12  ${addAppformGroupClass ? "ap-form-group" : ""} ${!!cssClass ? " " + cssClass : ""}${
      !!disabled ? " disabled" : ""
    }${!!error ? " is-invalid" : ""} col-md-${
      !cssClass || cssClass.indexOf("full-width") === -1 ? cols : "12"
    }`);
  }, [disabled]);
  return visible ? (
    <FormGroup key={id} className={className}>
      <CustomInput
            type="checkbox"
            name={id}
            id={id}
            disabled={!!disabled}
            className={
              !!disabled ? "custom-react-input disabled" : "custom-react-input"
            }
            defaultChecked={isChecked}
            onChange={onChange}            
            invalid={!!error}
            label={
               !!label
                ? <span dangerouslySetInnerHTML={{
                  __html: label
                }} />
                : ""
            }
          />
      {error && (
        <FormFeedback className="input-validation-error-messsage">
          {error}
        </FormFeedback>
      )}
    </FormGroup>
  ) : null;
};
CustomCheckBox.prototype = CustomCheckBoxProps;

export default CustomCheckBox;
