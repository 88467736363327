import React, { useRef, useState } from "react";
import "./profileImageWithLoader.scss";
import DefaultUserAvatar from "../../../shared/icons/default-user-avatar.svg";

const ProfileImageWithLoader = (props) => {
  const {
    src,
    alt,
    imageClassName,
    divClassName,
    showLoader = true,
  } = props || {};
  const [loading, setLoading] = useState(true);
  const [showDefaultAvatar, setShowDefaultAvatar] = useState(null);
  const isErrorOccuredOnProfileImageLoad = useRef(false);
  const handleImageLoad = () => {
    if (!isErrorOccuredOnProfileImageLoad.current) {
      setShowDefaultAvatar(false);
      setLoading(false);
    }
  };

  return (
    <div
      className={`image-container ${divClassName || ""}`}
      id="student-profile-image-container"
    >
      {loading &&
        (showLoader ? (
          <div className="image-spinner-container">
            <div className="image-spinner"></div>
            <img
              src={DefaultUserAvatar}
              alt="default-avatar"
              className={`${imageClassName}`}
            />
          </div>
        ) : (
          <img
            src={DefaultUserAvatar}
            alt="default-avatar"
            className={`${imageClassName}`}
          />
        ))}
      <img
        className={`${imageClassName} profile-image`}
        src={showDefaultAvatar ? DefaultUserAvatar : src}
        alt={alt}
        onError={() => {
          // If the main image fails to load, set loading to false
          setShowDefaultAvatar(true);
          setLoading(false);
          isErrorOccuredOnProfileImageLoad.current = true;
        }}
        onLoad={handleImageLoad}
        style={loading ? { display: "none" } : {}}
      />
    </div>
  );
};

export default ProfileImageWithLoader;
