import { createActionCreator } from "../../shared/store/Utilities";
export const actionTypes = {
  RESET_ALL_QUERY_DATA: "app/pages/query/reset",

  SUBMIT_QUERY: "app/pages/query/submit/query",
  SUBMIT_QUERY_REQUESTED: "app/pages/query/submit/query/REQUESTED",
  SUBMIT_QUERY_SUCCESS: "app/pages/query/submit/query/SUCCESS",
  SUBMIT_QUERY_FAILED: "app/pages/query/submit/query/FAILED",

  GET_ALL_QUERY: "app/pages/query/getAll/query",
  GET_ALL_QUERY_REQUESTED: "app/pages/query/getAll/query/REQUESTED",
  GET_ALL_QUERY_SUCCESS: "app/pages/query/getAll/query/SUCCESS",
  GET_ALL_QUERY_FAILED: "app/pages/query/getAll/query/FAILED",

  GET_QUERY_COUNT: "app/pages/query/getQuery/count",
  GET_QUERY_COUNT_REQUESTED: "app/pages/getQuery/count/REQUESTED",
  GET_QUERY_COUNT_SUCCESS: "app/pages/getQuery/count/SUCCESS",
  GET_QUERY_COUNT_FAILED: "app/pages/getQuery/count/FAILED",

  GET_TOTAL_UNREAD_MESSAGE_COUNT: "app/pages/query/getTotalUnreadMessage/count",
  GET_TOTAL_UNREAD_MESSAGE_COUNT_REQUESTED:
    "app/pages/query/getTotalUnreadMessage/count/REQUESTED",
  GET_TOTAL_UNREAD_MESSAGE_COUNT_SUCCESS:
    "app/pages/query/getTotalUnreadMessage/count/SUCCESS",
  GET_TOTAL_UNREAD_MESSAGE_COUNT_FAILED:
    "app/pages/query/getTotalUnreadMessage/count/FAILED",

  GET_ALL_CONVERSATION: "app/pages/query/getAll/conversation",
  GET_ALL_CONVERSATION_REQUESTED:
    "app/pages/query/getAll/conversation/REQUESTED",
  GET_ALL_CONVERSATION_SUCCESS: "app/pages/query/getAll/conversation/SUCCESS",
  GET_ALL_CONVERSATION_FAILED: "app/pages/query/getAll/conversation/FAILED",

  CREATE_CONVERSATION: "app/pages/query/create/conversation",
  CREATE_CONVERSATION_REQUESTED:
    "app/pages/query/create/conversation/REQUESTED",
  CREATE_CONVERSATION_SUCCESS: "app/pages/query/create/conversation/SUCCESS",
  CREATE_CONVERSATION_FAILED: "app/pages/query/create/conversation/FAILED",

  CONVERSATION_MARK_AS_READ: "app/pages/query/conversationMasrkAsRead",
  CONVERSATION_MARK_AS_READ_REQUESTED:
    "app/pages/query/conversationMasrkAsRead/REQUESTED",
  CONVERSATION_MARK_AS_READ_SUCCESS:
    "app/pages/query/conversationMasrkAsRead/SUCCESS",
  CONVERSATION_MARK_AS_READ_FAILED:
    "app/pages/query/conversationMasrkAsRead/FAILED",
};

//Reset query reducer
export const resetQueryReducerData = createActionCreator(
  actionTypes.RESET_ALL_QUERY_DATA
);

//Submit query actions
export const submitQuery = createActionCreator(
  actionTypes.SUBMIT_QUERY,
  "params",
  "onSuccessSubmitquery"
);

export const submitQueryRequested = createActionCreator(
  actionTypes.SUBMIT_QUERY_REQUESTED,
  ""
);
export const submitQuerySuccess = createActionCreator(
  actionTypes.SUBMIT_QUERY_SUCCESS,
  "data"
);
export const submitQueryFailed = createActionCreator(
  actionTypes.SUBMIT_QUERY_FAILED,
  "errorMessage"
);

//Get all query actions
export const getAllQuery = createActionCreator(
  actionTypes.GET_ALL_QUERY,
  "userId",
  "clientAlias"
);

export const getAllQueryRequested = createActionCreator(
  actionTypes.GET_ALL_QUERY_REQUESTED,
  ""
);
export const getAllQuerySuccess = createActionCreator(
  actionTypes.GET_ALL_QUERY_SUCCESS,
  "data"
);
export const getAllQueryFailed = createActionCreator(
  actionTypes.GET_ALL_QUERY_FAILED,
  "errorMessage"
);

//Get query count actions
export const getQueryCount = createActionCreator(
  actionTypes.GET_QUERY_COUNT,
  "userId",
  "clientAlias"
);

export const getQueryCountRequested = createActionCreator(
  actionTypes.GET_QUERY_COUNT_REQUESTED,
  ""
);
export const getQueryCountSuccess = createActionCreator(
  actionTypes.GET_QUERY_COUNT_SUCCESS,
  "data"
);
export const getQueryCountFailed = createActionCreator(
  actionTypes.GET_QUERY_COUNT_FAILED,
  "errorMessage"
);

//Get all converstion actions
export const getAllConversation = createActionCreator(
  actionTypes.GET_ALL_CONVERSATION,
  "ticketId",
  "clientAlias",
  "onSuccessGetAllConversations"
);

export const getAllConversationRequested = createActionCreator(
  actionTypes.GET_ALL_CONVERSATION_REQUESTED,
  "ticketId"
);
export const getAllConversationSuccess = createActionCreator(
  actionTypes.GET_ALL_CONVERSATION_SUCCESS,
  "data"
);
export const getAllConversationFailed = createActionCreator(
  actionTypes.GET_ALL_CONVERSATION_FAILED,
  "errorMessage"
);

//Craete converstion actions
export const createConversation = createActionCreator(
  actionTypes.CREATE_CONVERSATION,
  "params",
  "onSuccessReply"
);

export const createConversationRequested = createActionCreator(
  actionTypes.CREATE_CONVERSATION_REQUESTED,
  "ticketId"
);
export const createConversationSuccess = createActionCreator(
  actionTypes.CREATE_CONVERSATION_SUCCESS,
  "data"
);
export const createConversationFailed = createActionCreator(
  actionTypes.CONVERSATION_MARK_AS_READ_FAILED,
  "errorMessage"
);

//Get all converstion actions
export const conversationMarkAsRead = createActionCreator(
  actionTypes.CONVERSATION_MARK_AS_READ,
  "ticketId",
  "counselorId",
  "clientAlias",
  "isReadBy",
  "userId"
);

export const conversationMarkAsReadRequested = createActionCreator(
  actionTypes.CONVERSATION_MARK_AS_READ_REQUESTED,
  ""
);
export const conversationMarkAsReadSuccess = createActionCreator(
  actionTypes.CONVERSATION_MARK_AS_READ_SUCCESS,
  "ticketId"
);
export const conversationMarkAsReadFailed = createActionCreator(
  actionTypes.CONVERSATION_MARK_AS_READ_FAILED,
  "errorMessage"
);

//Get totak  unread message count actions
export const getTotalUnreadMessageCount = createActionCreator(
  actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT,
  "userId",
  "clientAlias"
);

export const getTotalUnreadMessageCountRequested = createActionCreator(
  actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT_REQUESTED,
  ""
);
export const getTotalUnreadMessageCountSuccess = createActionCreator(
  actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT_SUCCESS,
  "totalUnreadMessageCount"
);
export const getTotalUnreadMessageCountFailed = createActionCreator(
  actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT_FAILED,
  "errorMessage"
);
