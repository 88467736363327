import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import "./Footer.scss";
import { Container } from "reactstrap";
import { getLang } from "../../utilties/Utils";
import { get } from "lodash";

const Footer = ({ clientInfo }) => {
  const termsAndCondition = get(clientInfo, "links.termsAndCondition", {});
  const poweredBy = get(clientInfo, "links.poweredBy", {});
  const isValidHtml = (htmlContent) => {
    return htmlContent && htmlContent.trim() !== "";
  };
  const shouldRenderFooter =
    (termsAndCondition.visible &&
      isValidHtml(getLang(termsAndCondition.text))) ||
    (poweredBy.visible && isValidHtml(getLang(poweredBy.text)));

  return (
    shouldRenderFooter && (
      <Container fluid={true} className="footer p-2 m-0">
        {termsAndCondition.visible && (
          <span
            dangerouslySetInnerHTML={{
              __html: getLang(termsAndCondition.text),
            }}
            className="terms text-dark"
          ></span>
        )}
        {poweredBy.visible && (
          <span
            dangerouslySetInnerHTML={{ __html: getLang(poweredBy.text) }}
            className="ee-link text-dark"
          ></span>
        )}
      </Container>
    )
  );
};

const mapStateToProps = (state) => ({
  clientInfo: state.appDataReducer && state.appDataReducer.clientInfo,
});

const FooterComponentConnected = withRouter(
  connect(mapStateToProps, {})(Footer)
);

export default FooterComponentConnected;