import { actionTypes } from "./Actions";
import { actionTypes as appActionTypes } from "../../shared/services/appData/Actions";
const defaultState = {
  isInProgress: false,
  config: {},
  errorMessage: "",
  serverError: null,
  updateOn: new Date().toLocaleString()
};
export function reducer(state = defaultState, action) {
  switch (action.type) {
    case appActionTypes.LOGOUT: {
      return {
        ...state,
        defaultState
      };
    }
    case actionTypes.CONTINUE_CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true
      };
    }
    case actionTypes.CONTINUE_CONFIG_SUCCESS: {
      return {
        ...state,
        serverError: null,
        isInProgress: false,
        config: action.payload.data,
        updateOn: new Date().toLocaleString()
      };
    }
    case actionTypes.CONTINUE_CONFIG_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isInProgress: false,
        updateOn: new Date().toLocaleString()
      };
    }
    case actionTypes.UPDATE_SERVER_ERROR: {
      return {
        ...state,
        serverError: action.payload.error
      };
    }
    default: {
      return state;
    }
  }
}
