import { createActionCreator } from "../../store/Utilities";

export const actionTypes = {
  MASTERDATA_INIT: "app/store/masterdata/INIT",
  MASTERDATA_REQUESTED: "app/store/masterdata/REQUESTED",
  MASTERDATA_COMPLETED: "app/store/masterdata/COMPLETED",
  MASTERDATA_FAILED: "app/store/masterdata/FAILED",

  USER_DETAIL_INIT: "app/pages/user_detail/INIT",
  USER_DETAIL_REQUESTED: "app/pages/user_detail/REQUESTED",
  USER_DETAIL_SUCCESS: "app/pages/user_detail/SUCCESS",
  USER_DETAIL_FAILED: "app/pages/user_detail/FAILED",

  USER_INFO_UPDATE: "app/pages/user_info/UPDATE",
  USER_INFO_UPDATE_REQUESTED: "app/pages/user_info/UPDATE/REQUESTED",
  USER_INFO_UPDATE_SUCCESS: "app/pages/user_info/UPDATE/SUCCESS",
  USER_INFO_UPDATE_FAILED: "app/pages/user_info/UPDATE/FAILED",

  LOGOUT: "app/LOGOUT",

  BROWSER_REFRESH: "app/browser/REFRESH",

  CLIENT_INFO_INIT: "app/store/clientinfo/INIT",
  CLIENT_INFO_REQUESTED: "app/store/client_info/REQUESTED",
  CLIENT_INFO_SUCCESS: "app/store/client_info/SUCCESS",
  CLIENT_INFO_FAILED: "app/store/client_info/FAILED",

  UPDATE_CLIENT_INFO: "app/store/update/client_info/SUCCESS",

  DOMAIN_CONFIG_INIT: "app/store/domainConfig/INIT",
  DOMAIN_CONFIG_REQUESTED: "app/store/domainConfig/REQUESTED",
  DOMAIN_CONFIG_SUCCESS: "app/store/domainConfig/SUCCESS",
  DOMAIN_CONFIG_FAILED: "app/store/domainConfig/FAILED",

  
  GET_ACTIVITIES_INIT: "app/store/getActivites/INIT",
  GET_ACTIVITIES_REQUESTED: "app/store/getActivites/REQUESTED",
  GET_ACTIVITIES_SUCCESS: "app/store/getActivites/SUCCESS",
  GET_ACTIVITIES_FAILED: "app/store/getActivites/FAILED",

  VALIADTE_LOGIN_INTI: "app/pages/auth_details/INIT",
  AUTH_DETAILS_REQUESTED: "app/store/auth_details/REQUESTED",
  AUTH_DETAILS_SUCCESS: "app/store/auth_details/SUCCESS",
  AUTH_DETAILS_FAILED: "app/store/auth_details/FAILED",

  UPDATE_CURRENT_STEP_NUMBER: "app/store/UPDATE_CURRENT_STEP_NUMBER",
  UPDATE_CURRENT_STEP_NUMBER_SUCCESS:
    "app/store/UPDATE_CURRENT_STEP_NUMBER_SUCESS",

  GENERATE_PDF_INTI: "app/store/generate/PDF",
  GENERATE_PDF_REQUESTED: "app/store/generate/PDF/REQUESTED",
  GENERATE_PDF_SUCCESS: "app/store/generate/PDF/SUCCESS",
  GENERATE_PDF_FAILED: "app/store/generate/PDF/FAILED",
  
  USER_METADATA_UPDATE: "app/pages/user/METADATA/UPDATE",
  USER_METADATA_UPDATE_REQUESTED: "app/pages/user/METADATA/UPDATE/REQUESTED",
  USER_METADATA_UPDATE_SUCCESS: "app/pages/user/METADATA/UPDATE/SUCCESS",
  USER_METADATA_UPDATE_FAILED: "app/pages/user/METADATA/UPDATE/FAILED",

  GENERATE_PREVIEW_PDF_INTI: "app/store/generate/preview/PDF",
  GENERATE_PREVIEW_PDF_REQUESTED: "app/store/generate/preview/PDF/REQUESTED",
  GENERATE_PREVIEW_PDF_SUCCESS: "app/store/generate/preview/PDF/SUCCESS",
  GENERATE_PREVIEW_PDF_FAILED: "app/store/generate/preview/PDF/FAILED",

  UPLOAD_PROFILE_PICTURE_INIT: "app/pages/user/PROFILE/UPLOAD",
  UPLOAD_PROFILE_PICTURE_REQUESTED: "app/pages/user/PROFILE/REQUESTED",
  UPLOAD_PROFILE_PICTURE_SUCCESS: "app/pages/user/PROFILE/SUCCESS",
  UPLOAD_PROFILE_PICTURE_FAILED: "app/pages/user/PROFILE/FAILED",
  
  GENERATE_TEMPLATE_PDF_INIT: "app/store/generate/template/PDF",
  GENERATE_TEMPLATE_PDF_REQUESTED: "app/store/generate/template/PDF/REQUESTED",
  GENERATE_TEMPLATE_PDF_SUCCESS: "app/store/generate/template/PDF/SUCCESS",
  GENERATE_TEMPLATE_PDF_FAILED: "app/store/generate/template/PDF/FAILED",  

  COUNSELOR_DETAIL_INIT: "app/pages/counselor_detail/INIT",
  COUNSELOR_ACCESS_GLOBAL_KEY_TOGGLE: "app/pages/counselor_detail/TOGGLE/KEY",
  COUNSELOR_DETAIL_REQUESTED: "app/pages/counselor_detail/REQUESTED",
  COUNSELOR_DETAIL_SUCCESS: "app/pages/counselor_detail/SUCCESS",
  COUNSELOR_DETAIL_FAILED: "app/pages/counselor_detail/FAILED",  

  ADD_LEAD_EVENT_INTI: "app/store/add/leadEvent/INIT",
  ADD_LEAD_EVENT_REQUESTED: "app/store/add/leadEvent/REQUESTED",
  ADD_LEAD_EVENT_SUCCESS: "app/store/add/leadEvent/SUCCESS",
  ADD_LEAD_EVENT_FAILED: "app/store/add/leadEvent/FAILED",

  CHECK_VALUES_IN_HISTORY_INTI: "app/store/check/values/in/history/INIT",
  CHECK_VALUES_IN_HISTORY_REQUESTED: "app/store/check/values/in/history/REQUESTED",
  CHECK_VALUES_IN_HISTORY_SUCCESS: "app/store/check/values/in/history/SUCCESS",
  CHECK_VALUES_IN_HISTORY_FAILED: "app/store/check/values/in/history/FAILED",
  ADD_SLOT_BOOKING_DATA_IN_MASTERDATA: "slot/booking/masterdata/set",
  IS_SELECTED_SLOT_EXPIRED_KEY_TOGGLE: "app/pages/isSelectedSlotExpired/TOGGLE/KEY",  
  SLOT_BOOKING_API_CALL_ENABLED: "slot/booking/API/call",  
  SUBMIT_BTN_TRIGERRED: "app/form/submit/button/triggered",  
  SLOT_BOOKING_API_CALL_FUNC: "slot/booking/API/call/func",  

  SWAP_COURSE_EDIT: "app/pages/SWAP_COURSE/EDIT/LEAD",
  SWAP_COURSE_EDIT_REQUESTED: "app/pages/SWAP_COURSE/EDIT/LEAD/REQUESTED",
  SWAP_COURSE_EDIT_SUCCESS: "app/pages/SWAP_COURSE/EDIT/LEAD/SUCCESS",
  SWAP_COURSE_EDIT_FAILED: "app/pages/SWAP_COURSE/EDIT/LEAD/FAILED",

  GLOBAL_LOADER_TOGGLE: "app/pages/GLOBAL_LOADER/TOGGLE",
  
  PUSH_APP_FORM_LEAD_INIT: "app/store/push/appform/lead",
  PUSH_APP_FORM_LEAD_REQUESTED: "app/store/push/appform/lead/REQUESTED",
  PUSH_APP_FORM_LEAD_SUCCESS: "app/store/push/appform/lead/SUCCESS",
  PUSH_APP_FORM_LEAD_FAILED: "app/store/push/appform/lead/FAILED",
  UPDATE_VERIFICATION_DATA: "app/store/update/appform/lead/verificationData",

  UPDATE_ONLINE_STATUS: "app/store/update/onlineStatus",
  SHOW_REFRESH_WINDOW_POPUP: "app/store/show/refresh/window/popup",
  HIDE_REFRESH_WINDOW_POPUP: "app/store/hide/refresh/window/popup",
  
  SET_MISSING_MANDATORY_FIELDS: "app/store/set/missing/mandatory/fields",  
  SET_LEAD_HISTORY_STATUSID: "app/store/set/lead/history/statusId",  
  BOOKMARK_ORIGIN_STEP_FORM_MISSING_FIELDS_POPUP_FIRSTTIME_REQUESTED: "app/store/bookmark/origin/step/from/missing/fields/popup/firsttime/requested",
  SET_AUTO_NAVIGATE_ROUTE_DETAILS: "app/store/set/auto/navigate/ruote/details",

  GET_PAYMENT_TRANSACTION_HISTORY: "app/pages/payment_transaction_history/INIT",
  PAYMENT_TRANSACTION_HISTORY_REQUESTED: "app/pages/payment_transaction_history/REQUESTED",
  PAYMENT_TRANSACTION_HISTORY_SUCCESS: "app/pages/payment_transaction_history/SUCCESS",
  PAYMENT_TRANSACTION_HISTORY_FAILED: "app/pages/payment_transaction_history/FAILED",
  UPDATE_VALUES_IN_MASTERDATA: "update/masterdata/values",
  
  SET_LEFT_NAVIGATION_ACTIVE_MENU: "set/left_navigation_active-menu",
  SET_LEFT_NAVIGATION_BAR_ACTIVE : "set/left_side_navigation_bar_open",
  SET_APPLICATION_CARD_DATA: "set/application_card_data_for_form",

  ALL_APPLICATION_DETAIL_INIT: "app/store/all_application_detail/INIT",
  ALL_APPLICATION_REQUESTED: "app/store/all_application_detail/REQUESTED",
  ALL_APPLICATION_SUCCESS: "app/store/all_application_detail/SUCCESS",
  ALL_APPLICATIONL_FAILED: "app/store/all_application_detail/FAILED",

  UPDATE_ALL_APPLICATION_DATA: "app/store/all_application_detail/UPDATE_ALL_APPLICATION_DATA",

  ALL_APPLICATIONS_CONFIG_INIT: "app/store/all_applications_config/INIT",
  ALL_APPLICATIONS_CONFIG_REQUESTED: "app/store/all_applications_config/REQUESTED",
  ALL_APPLICATIONS_CONFIG_SUCCESS: "app/store/all_applications_config/SUCCESS",
  ALL_APPLICATIONS_CONFIG_FAILED: "app/store/all_applications_config/FAILED",

  UPDATE_ALL_CONFIGURATIONS_ACCORDING_TO_INST: "app/store/update_all_configurations_according_to_inst",
  UPDATE_FORM_DATA_IN_UKC : "app/pages/form/UPDATE_FORM_DATA_IN_UKC",

  UPDATE_APPLICATIONS_AUTH_DETAILS_ACCORDING_LEAD_ID: "app/store/UPDATE_APPLICATIONS_AUTH_DETAILS_ACCORDING_LEAD_ID",
  GET_AND_UPDATE_TOKEN_IN_UKC: "app/store/GET_AND_UPDATE_TOKEN_IN_UKC",

  GET_LEAD_DOCUMENTS: "app/store/GET_LEAD_DOCUMENTS",
  GET_LEAD_DOCUMENTS_REQUESTED: "app/store/GET_LEAD_DOCUMENTS_REQUESTED",
  GET_LEAD_DOCUMENTS_SUCCESS: "app/store/GET_LEAD_DOCUMENTS_SUCCESS",
  GET_LEAD_DOCUMENTS_FAILED: "app/store/GET_LEAD_DOCUMENTS_FAILED",

  SET_LEADID_WISE_ACTION_INPROGRESS: "app/store/SET_LEADID_WISE_ACTION_INPROGRESS",
  SET_LEADWISE_HISTORY_VALUES_API_NOT_CALLED: "app/store/SET_LEADWISE_HISTORY_VALUES_API_NOT_CALLED",

  UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION: "app/store/UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION",
  UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION_SUCCESS: "app/store/UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION_SUCCESS"

};
export const masterDataInit = createActionCreator(
  actionTypes.MASTERDATA_INIT,
  "data"
);
export const masterDataRequested = createActionCreator(
  actionTypes.MASTERDATA_REQUESTED,
  ""
);
export const masterDataCompleted = createActionCreator(
  actionTypes.MASTERDATA_COMPLETED,
  "data"
);
export const masterDataFail = createActionCreator(
  actionTypes.MASTERDATA_FAILED,
  "message"
);
/**************************/
/*     USER INFO - actions     */
/**************************/
export const userDetailInit = createActionCreator(
  actionTypes.USER_DETAIL_INIT,
  "data",
  "logFrom"
);
export const userDetailRequested = createActionCreator(
  actionTypes.USER_DETAIL_REQUESTED,
  ""
);
export const userDetailSuccess = createActionCreator(
  actionTypes.USER_DETAIL_SUCCESS,
  "data"
);
export const userDetailFailed = createActionCreator(
  actionTypes.USER_DETAIL_FAILED,
  "errorMessage"
);
export const userDetailUpdate = createActionCreator(
  actionTypes.USER_INFO_UPDATE,
  "data",
  "callback",
  "formData",
  "nextStepIndex",
  "generatePDFDetails",
  "generateTemplatePDFDetails",
  "isAutoSaveRequest",
  "completionPercentage",
  "logFrom"
);
export const userDetailUpdateRequested = createActionCreator(
  actionTypes.USER_INFO_UPDATE_REQUESTED,
  "isAutoSaveRequest"
);
export const userDetailUpdateSuccess = createActionCreator(
  actionTypes.USER_INFO_UPDATE_SUCCESS,
  "data"
);
export const userDetailUpdateFailed = createActionCreator(
  actionTypes.USER_INFO_UPDATE_FAILED,
  "errorMessage"
);
/**************************/
/*     LOG OUT - actions     */
/**************************/

export const logout = createActionCreator(actionTypes.LOGOUT, "");

/**
 * @description client reducer actions
 */
export const clientInfoInit = createActionCreator(
  actionTypes.CLIENT_INFO_INIT,
  "data",
  "rootFolderURL"
);
export const clientInfoRequested = createActionCreator(
  actionTypes.CLIENT_INFO_REQUESTED,
  ""
);
export const clientInfoSuccess = createActionCreator(
  actionTypes.CLIENT_INFO_SUCCESS,
  "data"
);
export const clientInfoFail = createActionCreator(
  actionTypes.CLIENT_INFO_FAILED,
  "message"
);
export const updateClientInfo = createActionCreator(
  actionTypes.UPDATE_CLIENT_INFO,
  "data"
)

/**
 * @description Domain reducer actions
 */
export const domainConfigInit = createActionCreator(
  actionTypes.DOMAIN_CONFIG_INIT,
  "data"
);
export const domainConfigRequested = createActionCreator(
  actionTypes.DOMAIN_CONFIG_REQUESTED,
  ""
);
export const domainConfigSuccess = createActionCreator(
  actionTypes.DOMAIN_CONFIG_SUCCESS,
  "data"
);
export const domainConfigFail = createActionCreator(
  actionTypes.DOMAIN_CONFIG_FAILED,
  "message"
);

/**
 * @description Activities reducer actions
 */
export const getActiviesInit = createActionCreator(
  actionTypes.GET_ACTIVITIES_INIT,
  "data"
);
export const  getActiviesRequested = createActionCreator(
  actionTypes.GET_ACTIVITIES_REQUESTED,
  ""
);
export const  getActiviesSuccess = createActionCreator(
  actionTypes.GET_ACTIVITIES_SUCCESS,
  "data"
);
export const  getActiviesFail = createActionCreator(
  actionTypes.GENERATE_PDF_FAILED,
  "message"
);

/**
 * @description auth details actions
 */

export const validateLogin = createActionCreator(
  actionTypes.VALIADTE_LOGIN_INTI,
  "loginInfo"
);
export const authDetailsRequested = createActionCreator(
  actionTypes.AUTH_DETAILS_REQUESTED,
  ""
);
export const authDetailsSuccess = createActionCreator(
  actionTypes.AUTH_DETAILS_SUCCESS,
  "data"
);
export const authDetailsFail = createActionCreator(
  actionTypes.AUTH_DETAILS_FAILED,
  "message"
);

/**
 * @description update current step number actions
 */

export const updateCurrentStep = createActionCreator(
  actionTypes.UPDATE_CURRENT_STEP_NUMBER_SUCCESS,
  "data"
);
/**  GENERATE PDF   */

export const generatePDF = createActionCreator(
  actionTypes.GENERATE_PDF_INTI,
  "data"
);
export const generatePDFRequested = createActionCreator(
  actionTypes.GENERATE_PDF_REQUESTED,
  ""
);
export const generatePDFSuccess = createActionCreator(
  actionTypes.GENERATE_PDF_SUCCESS,
  "data"
);
export const generatePDFFail = createActionCreator(
  actionTypes.GENERATE_PDF_FAILED,
  "message"
);

/**  UPDATE META DATA OF USER */

export const userMetaDataUpdate = createActionCreator(
  actionTypes.USER_METADATA_UPDATE,
  "data",
  "formData",
  "callback",
  "nextStepIndex",
  "generateTemplatePDFDetails",
  "logFrom"
);
export const userMetaDataUpdateRequested = createActionCreator(
  actionTypes.USER_METADATA_UPDATE_REQUESTED,
  ""
);
export const userMetaDataUpdateSuccess = createActionCreator(
  actionTypes.USER_METADATA_UPDATE_SUCCESS,
  "data"
);
export const userMetaDataUpdateFailed = createActionCreator(
  actionTypes.USER_METADATA_UPDATE_FAILED,
  "errorMessage"
);

/**  GENERATE PREVIEW PDF   */

export const generatePreviewPDF = createActionCreator(
  actionTypes.GENERATE_PREVIEW_PDF_INTI,
  "data"
);
export const generatePreviewPDFRequested = createActionCreator(
  actionTypes.GENERATE_PREVIEW_PDF_REQUESTED,
  ""
);
export const generatePreviewPDFSuccess = createActionCreator(
  actionTypes.GENERATE_PREVIEW_PDF_SUCCESS,
  "data"
);
export const generatePreviewPDFFail = createActionCreator(
  actionTypes.GENERATE_PREVIEW_PDF_FAILED,
  "message"
);

/** UPLOAD PROFILE PICTURE */

export const uploadProfilePicture = createActionCreator(
  actionTypes.UPLOAD_PROFILE_PICTURE_INIT,
  "url",
  "file",
  "userId",
  "clientAlias"
);

export const uploadProfilePictureRequested = createActionCreator(
  actionTypes.UPLOAD_PROFILE_PICTURE_REQUESTED,
  ""
);

export const uploadProfilePictureSuccess = createActionCreator(
  actionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS,
  "data"
);

export const uploadProfilePictureFailed = createActionCreator(
  actionTypes.UPLOAD_PROFILE_PICTURE_FAILED,
  "message"
);

/**  GENERATE TEMPLATE PDF   */

export const generateTemplatePDF = createActionCreator(
  actionTypes.GENERATE_TEMPLATE_PDF_INIT,
  "data"
);
export const generateTemplatePDFRequested = createActionCreator(
  actionTypes.GENERATE_TEMPLATE_PDF_REQUESTED,
  ""
);
export const generateTemplatePDFSuccess = createActionCreator(
  actionTypes.GENERATE_TEMPLATE_PDF_SUCCESS,
  "data"
);
export const generateTemplatePDFFail = createActionCreator(
  actionTypes.GENERATE_TEMPLATE_PDF_FAILED,
  "message"
);

/**************************/
/*     COUNSELOR INFO - actions     */
/**************************/

export const setCounselorAccessState = createActionCreator(
  actionTypes.COUNSELOR_ACCESS_GLOBAL_KEY_TOGGLE,
  "isCounselorAccessActive"
);
export const counselorDetailInit = createActionCreator(
  actionTypes.COUNSELOR_DETAIL_INIT,
  "cid"
);
export const counselorDetailRequested = createActionCreator(
  actionTypes.COUNSELOR_DETAIL_REQUESTED,
  ""
);
export const counselorDetailSuccess = createActionCreator(
  actionTypes.COUNSELOR_DETAIL_SUCCESS,
  "data"
);
export const counselorDetailFailed = createActionCreator(
  actionTypes.COUNSELOR_DETAIL_FAILED,
  "errorMessage"
);


/**  ADD LEAD EVENT - First Login date time, Last Active date time, Last login on date time, last updated by Id   */

export const addLeadEvent = createActionCreator(
  actionTypes.ADD_LEAD_EVENT_INTI, 
  "data"
);
export const addLeadEventRequested = createActionCreator(
  actionTypes.ADD_LEAD_EVENT_REQUESTED,
  ""
);
export const addLeadEventSuccess = createActionCreator(
  actionTypes.ADD_LEAD_EVENT_SUCCESS,
  "data"
);
export const addLeadEventFail = createActionCreator(
  actionTypes.ADD_LEAD_EVENT_FAILED,
  "message"
);


/**  get values from history  */

export const getValuesFromHistory = createActionCreator(
  actionTypes.CHECK_VALUES_IN_HISTORY_INTI, 
  "data"
);
export const getValuesFromHistoryRequested = createActionCreator(
  actionTypes.CHECK_VALUES_IN_HISTORY_REQUESTED,
  ""
);
export const getValuesFromHistorySuccess = createActionCreator(
  actionTypes.CHECK_VALUES_IN_HISTORY_SUCCESS,
  "data"
);

export const getValuesFromHistoryFail = createActionCreator(
  actionTypes.CHECK_VALUES_IN_HISTORY_FAILED,
  "message"
);

/**
* @author Krushna Sharma
* @creationDate 02 Apr 2021
* @description Adds slot booking list in masterdata
*/
export const setSlotBookingDataInMasterdata = createActionCreator(
  actionTypes.ADD_SLOT_BOOKING_DATA_IN_MASTERDATA,
  "data"
)
/**
* @author Pradip Udavant
* @creationDate 10 Apr 2021
* @description Description of method
* @param 
*/
export const setSelectedSlotExpired = createActionCreator(
  actionTypes.IS_SELECTED_SLOT_EXPIRED_KEY_TOGGLE,
  "isSelectedSlotExpired"
);
export const setSlotBookingAPICallEnabled = createActionCreator(
  actionTypes.SLOT_BOOKING_API_CALL_ENABLED,
  "data"
);
export const setSubmitBtnTriggered = createActionCreator(
  actionTypes.SUBMIT_BTN_TRIGERRED,
  "data"
);
export const setSlotBookingAPICallFunc = createActionCreator(
  actionTypes.SLOT_BOOKING_API_CALL_FUNC,
  "data"
);
/**
 * SWAP COURSE
 */

 export const swapCourseEditLead = createActionCreator(
  actionTypes.SWAP_COURSE_EDIT,
  "data",
  "swapCallback",
  "formData",
  "generatePDFDetails",
  "FeesType_1_Receipt_PDF_Details",
  "FeesType_2_Receipt_PDF_Details"
);
export const swapCourseEditLeadRequested = createActionCreator(
  actionTypes.SWAP_COURSE_EDIT_REQUESTED,
  "isAutoSaveRequest"
);
export const swapCourseEditLeadSuccess = createActionCreator(
  actionTypes.SWAP_COURSE_EDIT_SUCCESS,
  "data"
);
export const swapCourseEditLeadFailed = createActionCreator(
  actionTypes.SWAP_COURSE_EDIT_FAILED,
  "errorMessage"
);


export const toggleGlobalLoader = createActionCreator(
  actionTypes.GLOBAL_LOADER_TOGGLE,
  "flag",
  "messaage"
);


/**  GENERATE TEMPLATE PDF   */

export const pushAppformLead = createActionCreator(
  actionTypes.PUSH_APP_FORM_LEAD_INIT,
  "data"
);
export const pushAppformLeadRequested = createActionCreator(
  actionTypes.PUSH_APP_FORM_LEAD_REQUESTED,
  ""
);
export const pushAppformLeadSuccess = createActionCreator(
  actionTypes.PUSH_APP_FORM_LEAD_SUCCESS,
  "data"
);
export const pushAppformLeadFail = createActionCreator(
  actionTypes.PUSH_APP_FORM_LEAD_FAILED,
  "message"
);
export const updateVerificationData = createActionCreator(
  actionTypes.UPDATE_VERIFICATION_DATA,
  "data"
);

/**************************/
/*     REFRESH - actions     */
/**************************/

export const triggerRefreshWindowPopup = createActionCreator(actionTypes.SHOW_REFRESH_WINDOW_POPUP, "");

export const setMandatoryFieldsMissing = createActionCreator(actionTypes.SET_MISSING_MANDATORY_FIELDS, "data");
export const setLeadHistoryStatusId = createActionCreator(actionTypes.SET_LEAD_HISTORY_STATUSID, "data");
export const bookmarkOriginStepFromMissingFieldsPopupFirsttimeRequested = createActionCreator(actionTypes.BOOKMARK_ORIGIN_STEP_FORM_MISSING_FIELDS_POPUP_FIRSTTIME_REQUESTED, "data");
export const setAutoNavigateRuote = createActionCreator(actionTypes.SET_AUTO_NAVIGATE_ROUTE_DETAILS, "data");


/**  PAYMENT TRANSACTION HISTORY  **/

export const getPaymentTransactionHistory = createActionCreator(
  actionTypes.GET_PAYMENT_TRANSACTION_HISTORY,
  "data"
);

export const paymentTransactionHistoryRequested = createActionCreator(
  actionTypes.PAYMENT_TRANSACTION_HISTORY_REQUESTED,
  ""
);
export const paymentTransactionHistorySuccess = createActionCreator(
  actionTypes.PAYMENT_TRANSACTION_HISTORY_SUCCESS,
  "data"
);
export const paymentTransactionHistoryFailed = createActionCreator(
  actionTypes.PAYMENT_TRANSACTION_HISTORY_FAILED,
  "errorMessage"
);

export const updateValuesInMasterdata = createActionCreator(
  actionTypes.UPDATE_VALUES_IN_MASTERDATA,
  "data"
)

export const setLeftNavigationActiveMenu = createActionCreator(
  actionTypes.SET_LEFT_NAVIGATION_ACTIVE_MENU,
  "data"
)

export const setLeftSideBarActiveMenu = createActionCreator(
  actionTypes.SET_LEFT_NAVIGATION_BAR_ACTIVE,
  "data"
)

export const setApplicationCardData = createActionCreator(
  actionTypes.SET_APPLICATION_CARD_DATA,
  "data"
);

/**
 * @description All Application reducer actions
 */
export const getAllApplicationInit = createActionCreator(
  actionTypes.ALL_APPLICATION_DETAIL_INIT,
  "data"
);
export const  getAllApplicationRequested = createActionCreator(
  actionTypes.ALL_APPLICATION_REQUESTED,
  ""
);
export const  getAllApplicationSuccess = createActionCreator(
  actionTypes.ALL_APPLICATION_SUCCESS,
  "data",
  "allApplicatonsInstData"
);
export const  getAllApplicationFail = createActionCreator(
  actionTypes.ALL_APPLICATIONL_FAILED,
  "message"
);


/**
 * @description All Application reducer actions
 */
export const getAllApplicationsConfigInit = createActionCreator(
  actionTypes.ALL_APPLICATIONS_CONFIG_INIT,
  "data",
  "allApplicationsData",
  "allApplicatonsInstData"
);
export const  getAllApplicationsCofigRequested = createActionCreator(
  actionTypes.ALL_APPLICATIONS_CONFIG_REQUESTED,
  ""
);
export const  getAllApplicationsConfigSuccess = createActionCreator(
  actionTypes.ALL_APPLICATIONS_CONFIG_SUCCESS,
  "data"
);
export const  getAllApplicationsConfigFail = createActionCreator(
  actionTypes.ALL_APPLICATIONS_CONFIG_FAILED,
  "message"
);

export const updateAllConfigurationsAccordingToInst = createActionCreator(
  actionTypes.UPDATE_ALL_CONFIGURATIONS_ACCORDING_TO_INST,
  "data"
);

export const updateAllApplicationData = createActionCreator(
  actionTypes.UPDATE_ALL_APPLICATION_DATA,
  "data"
)

export const updateFormDataInUkc = createActionCreator(
  actionTypes.UPDATE_FORM_DATA_IN_UKC
)

export const updateApplicationsAuthDetailsAccordingToLeadId = createActionCreator(
  actionTypes.UPDATE_APPLICATIONS_AUTH_DETAILS_ACCORDING_LEAD_ID,
  "data"
)

export const getAndUpdateTokenInUKC = createActionCreator(
  actionTypes.GET_AND_UPDATE_TOKEN_IN_UKC,
  "data"
)

export const  getLeadDocuments = createActionCreator(
  actionTypes.GET_LEAD_DOCUMENTS,
  "data"
);

export const  getLeadDocumentsRequested = createActionCreator(
  actionTypes.GET_LEAD_DOCUMENTS_REQUESTED,
  "leadId"
);
export const  getLeadDocumentsSuccess = createActionCreator(
  actionTypes.GET_LEAD_DOCUMENTS_SUCCESS,
  "leadId"
);
export const  getLeadDocumentsFail = createActionCreator(
  actionTypes.GET_LEAD_DOCUMENTS_FAILED,
  "leadId"
);

export const setLeadIdWiseHistoryValuesApiNotCalled = createActionCreator(
  actionTypes.SET_LEADWISE_HISTORY_VALUES_API_NOT_CALLED,
  "leadId"
);

export const setLeadIdWiseActionInProgress = createActionCreator(
  actionTypes.SET_LEADID_WISE_ACTION_INPROGRESS,
  "leadId",
  "actionKey"
);

export const updateIndexMetaDataInAllApplication = createActionCreator(
  actionTypes.UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION,
  "data"
)

export const updateIndexMetaDataInAllApplicationSuccess = createActionCreator(
  actionTypes.UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION_SUCCESS,
  "data"
)