/*
 * <copyright company="Extraaedge Pvt Ltd">
 *        copyright (c) Extraaedge Pvt Ltd.  All rights reserved.
 * </copyright>
 */

const logHelper = (msg, consoleFn, props) => {
  consoleFn("[INFO] " + msg, props);
};

const logErrorHandler = (err, consoleFn, props) => {
  consoleFn("[ERROR] " + err.message, props);
};

export const logInfo = (msg, props = null) =>
  logHelper(msg, console.info, props ? props : "");
export const logTelemetry = (msg, props = null) =>
  logHelper(msg, console.info, props ? props : "");
export const logError = (error, props = null) =>
  logErrorHandler(error, console.error, props ? props : "");
