export const mockData = {
         Gender: [
           { id: 1, alias: "M", name: "Male" },
           { id: 2, alias: "F", name: "Female" },
           { id: 2, alias: "O", name: "Other" }
         ],
         Entity1: [
           { id: 1, name: "University 01" },
           { id: 2, name: "University 02" },
           { id: 3, name: "University 03" },
           { id: 4, name: "University 04" },
           { id: 5, name: "University 05" },
           { id: 6, name: "University 06" }
         ],
         Entity2: [
           { id: 1, name: "Institute 01", entity1_id: 1 },
           { id: 2, name: "Institute 02", entity1_id: 2 },
           { id: 3, name: "Institute 03", entity1_id: 3 },
           { id: 4, name: "Institute 04", entity1_id: 4 },
           { id: 5, name: "Institute 05", entity1_id: 5 },
           { id: 6, name: "Institute 06", entity1_id: 6 }
         ],
         Entity3: [
           { id: 1, name: "Stream 01", entity2_id: 1, entity1_id: 1 },
           { id: 2, name: "Stream 02", entity2_id: 2, entity1_id: 2 },
           { id: 3, name: "Stream 03", entity2_id: 3, entity1_id: 3 },
           { id: 4, name: "Stream 04", entity2_id: 4, entity1_id: 4 },
           { id: 5, name: "Stream 05", entity2_id: 5, entity1_id: 5 },
           { id: 6, name: "Stream 06", entity2_id: 6, entity1_id: 6 }
         ],
         Entity4: [
           { id: 1, name: "Course 01", entity3_id: 1, entity2_id: 1 },
           { id: 2, name: "Course 02", entity3_id: 1, entity2_id: 1 },
           { id: 3, name: "Course 03", entity3_id: 2, entity2_id: 2 },
           { id: 4, name: "Course 04", entity3_id: 2, entity2_id: 2 },
           { id: 5, name: "Course 05", entity3_id: 3, entity2_id: 3 },
           { id: 6, name: "Course 06", entity3_id: 3, entity2_id: 3 },
           { id: 7, name: "Course 07", entity3_id: 4, entity2_id: 4 },
           { id: 8, name: "Course 08", entity3_id: 4, entity2_id: 4 },
           { id: 9, name: "Course 09", entity3_id: 5, entity2_id: 5 },
           { id: 10, name: "Course 10", entity3_id: 5, entity2_id: 5 },
           { id: 11, name: "Course 11", entity3_id: 6, entity2_id: 6 },
           { id: 12, name: "Course 12", entity3_id: 6, entity2_id: 6 }
         ],
         PricingList: [
           { id: 1, entityId: 1, courseCode: "XXX001", amount: 100 },
           { id: 2, entityId: 2, courseCode: "XXX002", amount: 200 },
           { id: 3, entityId: 3, courseCode: "XXX003", amount: 300 },
           { id: 4, entityId: 4, courseCode: "XXX004", amount: 400 },
           { id: 5, entityId: 5, courseCode: "XXX005", amount: 500 },
           { id: 6, entityId: 6, courseCode: "XXX006", amount: 600 },
           { id: 7, entityId: 7, courseCode: "XXX007", amount: 700 },
           { id: 8, entityId: 8, courseCode: "XXX008", amount: 800 },
           { id: 9, entityId: 9, courseCode: "XXX009", amount: 900 },
           { id: 10, entityId: 10, courseCode: "XXX010", amount: 1000 },
           { id: 11, entityId: 11, courseCode: "XXX011", amount: 1100 },
           { id: 12, entityId: 12, courseCode: "XXX012", amount: 1200 }
         ],
         Nationality: [
           { id: 1, name: "Nationality 01" },
           { id: 2, name: "Nationality 02" }
         ],
         CitizenShip: [
           { id: 1, name: "Citizenship 01" },
           { id: 2, name: "Citizenship 02" }
         ],
         Category: [
           { id: 1, name: "General" },
           { id: 2, name: "NRI Community" },
           { id: 3, name: "OBC" },
           { id: 4, name: "SC/ST" },
           { id: 5, name: "Other" }
         ],
         PreferredCities: [{ id: 1, name: "Pune" }, { id: 2, name: "Mumbai" }],
         ExamBoard: [
           { id: 1, name: "CBSC" },
           { id: 2, name: "Maharashtra Board" }
         ],
         ModeOfStudy: [
           { id: 1, name: "Regular" },
           { id: 2, name: "External" },
           { id: 3, name: "Other" }
         ],
         Domicile: [
           { id: 1, name: "Domicile 01" },
           { id: 2, name: "Domicile 02" }
         ],
         Religion: [
           { id: 1, name: "Hinduisum" },
           { id: 2, name: "Islam" },
           { id: 3, name: "Christianity" },
           { id: 4, name: "Sikhism" },
           { id: 5, name: "Buddhism" },
           { id: 6, name: "Jainism" },
           { id: 7, name: "Others" }
         ],
         EvaluationTypes: [
           {
             name: "Grade",
             id: 1
           },
           {
             name: "Percentage",
             id: 2
           }
         ],
         Caste: [{ id: 1, name: "Caste 01" }, { id: 2, name: "Caste 02" }],
         BloodGroups: [
           { id: 1, name: "A+" },
           { id: 2, name: "A-" },
           { id: 3, name: "B+" },
           { id: 4, name: "B-" },
           { id: 5, name: "AB+" },
           { id: 6, name: "AB-" },
           { id: 7, name: "O+" },
           { id: 8, name: "O-" }
         ],
         ExaminationTypes: [
           { id: 1, name: "Semester" },
           { id: 2, name: "Year" }
         ],
         graduation_type: [
           { id: 1, name: "Degree" },
           { id: 2, name: "Diploma" }
         ],
         post_Graduation_type: [
           { id: 1, name: "Degree" },
           { id: 2, name: "Diploma" }
         ],
         Countries: [
           { id: 1, name: "Country 1" },
           { id: 2, name: "Country 2" }
         ],
         States: [{ id: 1, name: "State 1" }, { id: 2, name: "State 2" }],
         Cities: [{ id: 1, name: "City 1" }, { id: 2, name: "City 2" }],
         Year: [
           { id: 2018, name: "2018" },
           { id: 2017, name: "2017" },
           { id: 2016, name: "2016" },
           { id: 2015, name: "2015" },
           { id: 2014, name: "2014" }
         ],
         applicationFormStepStatus: [
           { id: 1, name: "Not started" },
           { id: 2, name: "Step 1 started" },
           { id: 3, name: "Step 1 completed" },
           { id: 4, name: "Step 2 started" },
           { id: 5, name: "Step 2 completed" },
           { id: 6, name: "Step 3 started" },
           { id: 7, name: "Step 4 completed" },
           { id: 8, name: "Step 4 started" },
           { id: 9, name: "Step 4 completed" },
           { id: 10, name: "Payment done", key: "STEP_1_COMPLETED" }
         ],
         ModesOfPayment: [
           { id: 1, name: "Paytm" },
           { id: 2, name: "Cash" },
           { id: 3, name: "Internet Transfer" }
         ],
         MasterKeys: [
           {
             id: 1,
             name: "nationality",
             masterName: "Nationalities",
             type: "int"
           },
           {
             id: 5,
             name: "religion",
             masterName: "religions",
             type: "int"
           },
           {
             id: 6,
             name: "caste",
             masterName: "Caste",
             type: "int"
           },
           {
             id: 7,
             name: "adhaarDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 8,
             name: "CitizenShipId",
             masterName: "",
             type: "int"
           },
           {
             id: 9,
             name: "domicile",
             masterName: "",
             type: "text"
           },
           {
             id: 10,
             name: "categoryId",
             masterName: "",
             type: "int"
           },
           {
             id: 11,
             name: "preferredCityId",
             masterName: "",
             type: "int"
           },
           {
             id: 12,
             name: "isSamePermenentAddress",
             masterName: "",
             type: "text"
           },
           {
             id: 13,
             name: "currentAddressDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 14,
             name: "parentDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 15,
             name: "gardianDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 16,
             name: "sscDetails",
             masterName: "",
             type: "text"
           },
           {
             Id: 17,
             name: "hscDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 18,
             name: "entranceExaminationDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 19,
             name: "graduationDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 20,
             name: "postGraduationDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 21,
             name: "wordkExpirenceDetails",
             masterName: "",
             type: "text"
           },
           {
             id: 22,
             name: "ishostelAccomodationRequire",
             masterName: "",
             type: "text"
           },
           {
             id: 23,
             name: "attachments",
             masterName: "",
             type: "text"
           },
           {
             id: 24,
             name: "passportDetails",
             masterName: "",
             type: "text"
           }
         ]
       };
export const mockSetting = {
  pricingCriterion: "entity4",
  entityMapping: {
    entity1: [],
    entity2: ["entity1"],
    entity3: ["entity1", "entity2"],
    entity4: ["entity1", "entity2", "entity3"]
  },
  fileUploadWebURL: "http://192.168.1.180:8090"
};
export const mockToken = {
         accesstoken:
           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbmZvQHRoZWV4dHJhYWVkZ2UuY29tIiwianRpIjoiZWNiZWU5OTUtNmMyZi00ZDg3LThlZDItYWViNjZkYTZhNjFjIiwiaWF0IjoxNTY5NTc5NTU1LCJodHRwOi8vc2NoZW1hcy5taWNyb3NvZnQuY29tL3dzLzIwMDgvMDYvaWRlbnRpdHkvY2xhaW1zL3JvbGUiOiI0IiwiaHR0cDovL3d3dy5leHRyYWFlZGdlLmNvbS8yMDE2L2NsYWltcy9jdXN0b21lcmlkZW50aWZpZXIiOiIyNDhjZWIzYy01MzYwLTQzN2EtODMxZC1hYTdlMTQ0ZGNjNTAiLCJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9uYW1lIjoiRXh0cmFhRWRnZSBBZG1pbiIsImh0dHA6Ly9zY2hlbWFzLnhtbHNvYXAub3JnL3dzLzIwMDUvMDUvaWRlbnRpdHkvY2xhaW1zL21vYmlsZXBob25lIjoiODU1MDkwMDM4NCIsImh0dHA6Ly93d3cuZXh0cmFhZWRnZS5jb20vMjAxNi9jbGFpbXMvY3VzdG9tZXJpZCI6MywiaHR0cDovL3d3dy5leHRyYWFlZGdlLmNvbS8yMDE2L2NsYWltcy90aW1lem9uZWlkIjoyLCJuYmYiOjE1Njk1Nzk1NTUsImV4cCI6MTU2OTYyMjc1NSwiaXNzIjoiRXh0cmFhZWRnZSIsImF1ZCI6Imh0dHBzOi8vYXBpLmV4dHJhYWVkZ2UuY29tIn0.gxxCfiYnzJQ84B9KOMz4Rzn2hbv6HqnutshqhGk9Fjo",
         refreshtoken:
           "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbmZvQHRoZWV4dHJhYWVkZ2UuY29tIiwianRpIjoiMTIyYzBjY2YtNWYzZi00ZDc2LWE4YzMtNzdlYzJhZmVjNjdjIiwiaWF0IjoxNTY4MTg3ODc3LCJodHRwOi8vd3d3LmV4dHJhYWVkZ2UuY29tLzIwMTYvY2xhaW1zL2N1c3RvbWVyaWRlbnRpZmllciI6IjI0OGNlYjNjLTUzNjAtNDM3YS04MzFkLWFhN2UxNDRkY2M1MCIsIm5iZiI6MTU2ODE4Nzg3NywiZXhwIjoxNTY4NjE5ODc3LCJpc3MiOiJFeHRyYWFlZGdlIiwiYXVkIjoiaHR0cHM6Ly9hcGkuZXh0cmFhZWRnZS5jb20ifQ.56V7Vyqv2RK9RL-_kCwdXogV1XFWqgJl13uVrSh5lt0",
         expiresin: 43200,
         isAlreadyLoggedIn: false,
         preSessionKey: "",
         isAllowMultiSessions: false
       };

export const MockUserInfo_v2 = {
         applicationFormStatus: 4,
         currentStep: 4,
         completedStep: 4,
         user: {
           email: "Test01@test.com",
           mobileNumber: "2653514585",
           createdOn: "2019-07-11T11:01:32.127",
           prn: "qjfqk0",
           firstName: "Test01",
           lastName: "Test01",
           address: "Main road",
           countryId: 1,
           stateId: 1,
           cityId: 1,
           pincode: 411041,
           alternateMobileNumber: "9876543210",
           landLine: null,
           alternateEmailAddress: "alternateemail@example.com",
           bloodGroup: 1,
           fathersName: "Rock",
           nationality: 1,
           salutation: null,
           acceptTerms: false,
           avatarUrl:
             "https://eequeuestorage.blob.core.windows.net/staticfiles/reacttestappform/media/img/HarryPotter.jpg",
           designation: null,
           gender: "M",
           isContactable: null,
           bestTime: 1,
           mothersName: "Janni",
           emergencyContactName: null,
           emergencyPhoneNumber: null,
           emergencyRelationship: null,
           placeOfBirth: "Sydney",
           batchApplied: 1,
           dateOfBirth: "2000-07-11T01:01:32.127",
           highSchoolName: null,
           highSchoolExaminationName: null,
           highSchoolPercentage: null,
           underGraduateInstituteName: null,
           underGraduateSpecialization: null,
           underGraduatePercentage: null,
           experience: null,
           fathersMobile: "9999999999",
           mothersMobile: "9888888888",
           fathersEmail: "myfather@example.com",
           mothersEmail: "mymother@example.com",
           userQualified: null,
           highestQualification: "",
           isMobileNumberVerified: null,
           lastLoginIpAddress: null,
           isNDNC: null,
           userRoleId: 0,
           isForeignStudent: null,
           fileAttachment: "",
           registrationNumber: null,
           password: null,
           field1Id: null,
           field2Id: 1,
           field3Id: null,
           field4Id: 1,
           field5Id: null,
           field6Id: null,
           field7Id: null,
           field8Id: null,
           field9Id: null,
           field10Id: null,
           field11Id: null,
           field12Id: null,
           field13Id: null,
           field14Id: null,
           field15Id: null,
           field1Name: null,
           field2Name: null,
           field3Name: null,
           field4Name: null,
           field5Name: null,
           field6Name: null,
           field7Name: null,
           field8Name: null,
           field9Name: null,
           field10Name: null,
           field11Name: null,
           field12Name: null,
           field13Name: null,
           field14Name: null,
           field15Name: null,
           geoLocation: "",
           geoLatitude: "",
           geoLongitude: "",
           updatedOn: "2019-07-11T11:01:32.127",
           middleName: null,
           textb1: "",
           textb2: "",
           textb3: "",
           textb4: "",
           textb5: "",
           textb6: "",
           textb7: "",
           textb8: "",
           textb9: "",
           textb10: "",
           dateField1: null,
           pdfAttachment: null,
           reEnquiryCount: null,
           reEnquiryDate: null,
           otp: null,
           otpCreationTime: "2019-07-11T11:01:30.993",
           currentUrl: null,
           isPushToERP: null,
           sendEmail: false,
           countryCode: null,
           actualMobileNumber: "2653514585",
           groupReferenceKey: "b4d472a49a600239",
           id: 4457
         },
         lead: {
           campaignName: "sd",
           statusId: 1,
           sourceId: [1],
           sourceToId: [1],
           sourceTypeId: 3,
           priorityId: 1,
           referredFromId: 99,
           referredToId: 99,
           remarks: "",
           userId: 4457,
           reasonId: [1],
           allowTest: null,
           testAppeared: null,
           updatedOn: "2019-07-11T11:01:33.533",
           tags: null,
           referrerAlumniId: null,
           entity1Id: [1, 2, 4],
           entity2Id: [1, 2, 4],
           entity3Id: [1, 2, 4],
           entity4Id: [1, 4, 8],
           eeSourceId: 1,
           fathersMobile: "9777777777",
           alternateMobileNumber: null,
           mothersMobile: "9888888888",
           entity1Name: null,
           entity2Name: null,
           entity3Name: null,
           entity4Name: null,
           createdOn: "2019-07-11T11:01:33.533",
           bulkUploadId: null,
           statusComment: "",
           masterStatusId: null,
           sendWelcomeEmail: true,
           sendWelcomeSms: true,
           sessionKey: null,
           sourceName: null,
           sourceToName: null,
           leadTypeName: null,
           leadScore: 0.0,
           isReEnquiredLead: null,
           documents: null,
           mergedPDF: null,
           statusName: null,
           referredToEmail: null,
           updatedBy: 0,
           milestones: [
             {
               activityTitle: "Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T05:32:00",
               comments: "test",
               createdOn: "2019-07-11T11:27:06.297",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T11:49:30.377",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 2
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-23T05:34:00",
               comments: "ddd",
               createdOn: "2019-07-11T11:27:27.323",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T11:29:02.393",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 3
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-24T05:34:00",
               comments: "ddd01",
               createdOn: "2019-07-11T11:29:02.45",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T11:51:59.793",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 4
             },
             {
               activityTitle: "Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T05:32:00",
               comments: "test",
               createdOn: "2019-07-11T11:49:30.437",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T12:08:26.567",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 5
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-24T05:34:00",
               comments: "ddd01",
               createdOn: "2019-07-11T11:52:11.917",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T11:54:34.717",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 6
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-24T05:34:00",
               comments: "ddd01 updated",
               createdOn: "2019-07-11T11:54:34.823",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T11:54:52.973",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 8
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-24T05:34:00",
               comments: "ddd01 updated",
               createdOn: "2019-07-11T11:54:53.287",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T12:08:40.487",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 9
             },
             {
               activityTitle: "Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T05:32:00",
               comments: "test qqq",
               createdOn: "2019-07-11T12:08:26.81",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T12:12:42.697",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 10
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-24T05:34:00",
               comments: "ddd01 updated",
               createdOn: "2019-07-11T12:08:40.537",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T13:32:03.94",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 11
             },
             {
               activityTitle: "Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T05:37:00",
               comments: "test qqq",
               createdOn: "2019-07-11T12:12:42.76",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-11T12:12:56.567",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-23T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 12
             },
             {
               activityTitle: "Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T05:37:00",
               comments: "test qqqdrydfg",
               createdOn: "2019-07-11T12:12:56.76",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:41:52.58",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-23T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 13
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-16T03:58:00",
               comments: "m1",
               createdOn: "2019-07-14T03:58:55.147",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T03:59:07.123",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-17T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1002
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-16T03:58:00",
               comments: "m2",
               createdOn: "2019-07-14T03:59:07.143",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T05:12:53.28",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-17T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1003
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-17T05:16:00",
               comments: "m3",
               createdOn: "2019-07-14T05:12:53.313",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:20:55.02",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:33:00",
               savedFilteredListId: null,
               setting: null,
               id: 1004
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-16T05:30:00",
               comments: "test01",
               createdOn: "2019-07-14T05:30:48.19",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:21:07.55",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-17T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1005
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-17T05:16:00",
               comments: "m34",
               createdOn: "2019-07-14T06:20:55.19",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:41:52.58",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-16T05:33:00",
               savedFilteredListId: null,
               setting: null,
               id: 1006
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-16T05:30:00",
               comments: "test012",
               createdOn: "2019-07-14T06:21:07.64",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T13:32:28.23",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-17T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1007
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-16T06:22:00",
               comments: "test01",
               createdOn: "2019-07-14T06:22:44.197",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:22:52.34",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1008
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-16T06:22:00",
               comments: "test1",
               createdOn: "2019-07-14T06:22:52.46",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:32:32.877",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1009
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-16T06:23:00",
               comments: "test02",
               createdOn: "2019-07-14T06:23:19.31",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:23:27.177",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-18T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1010
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-16T06:23:00",
               comments: "test2",
               createdOn: "2019-07-14T06:23:27.22",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T13:38:38.257",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-18T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1011
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-23T06:32:00",
               comments: "test",
               createdOn: "2019-07-14T06:32:32.893",
               createdForUserId: 99,
               leadId: 1,
               isDone: false,
               createdBy: 99,
               updatedOn: "2019-07-14T06:32:32.893",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-24T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1012
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-30T06:41:00",
               comments: "dcdcds",
               createdOn: "2019-07-14T06:41:52.613",
               createdForUserId: 99,
               leadId: 1,
               isDone: false,
               createdBy: 99,
               updatedOn: "2019-07-14T06:41:52.613",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-26T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1013
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-24T06:45:00",
               comments: "m1",
               createdOn: "2019-07-14T06:45:32.943",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:45:50.387",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1014
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-07-24T06:45:00",
               comments: "m2",
               createdOn: "2019-07-14T06:45:50.407",
               createdForUserId: 99,
               leadId: 1,
               isDone: false,
               createdBy: 99,
               updatedOn: "2019-07-14T06:45:50.407",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-25T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1015
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-07-25T06:45:00",
               comments: "m2",
               createdOn: "2019-07-14T06:46:11.35",
               createdForUserId: 99,
               leadId: 1,
               isDone: false,
               createdBy: 99,
               updatedOn: "2019-07-14T06:46:11.35",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-07-26T05:33:00",
               savedFilteredListId: null,
               setting: null,
               id: 1016
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2020-07-01T06:48:00",
               comments: "m1",
               createdOn: "2019-07-14T06:48:45.273",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:49:36.463",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2020-07-01T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1017
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2020-07-22T06:49:00",
               comments: "m2",
               createdOn: "2019-07-14T06:49:21.58",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:49:58.503",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2020-07-22T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1018
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2020-07-01T06:48:00",
               comments: "m12",
               createdOn: "2019-07-14T06:49:36.48",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:49:58.503",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2020-07-01T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1019
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-08-22T06:51:00",
               comments: "m1",
               createdOn: "2019-07-14T06:51:45.473",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:52:54.3",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2020-07-22T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1022
             },
             {
               activityTitle: "Smart Caller Calling Activity",
               activityTypeId: 11,
               nextActionDateTime: "2019-10-02T06:51:00",
               comments: "m2",
               createdOn: "2019-07-14T06:51:59.93",
               createdForUserId: 99,
               leadId: 1,
               isDone: true,
               createdBy: 99,
               updatedOn: "2019-07-14T06:52:54.3",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2019-09-19T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1023
             },
             {
               activityTitle: "Manual - Take Follow Up Call",
               activityTypeId: 10,
               nextActionDateTime: "2019-11-13T06:54:00",
               comments: "m1",
               createdOn: "2019-07-14T06:54:12.133",
               createdForUserId: 99,
               leadId: 1,
               isDone: false,
               createdBy: 99,
               updatedOn: "2019-07-14T06:54:12.133",
               deliveryStatusId: null,
               deliveryStatus: null,
               parentId: null,
               campaignId: null,
               isEmailEscalate: null,
               deliveryTime: null,
               callTime: null,
               segmentId: null,
               referenceKey: null,
               isMissedEmailEscalate: null,
               templateid: null,
               attachments: null,
               currentActionDateTime: "2022-07-13T05:30:00",
               savedFilteredListId: null,
               setting: null,
               id: 1025
             }
           ],
           id: 1
         },
         leadActivityModel: {
           activityTitle: "Online",
           activityTypeId: 9,
           nextActionDate: "2019-07-22T05:30:00",
           activityComment: "sdfg  bb",
           leadId: 1,
           isDone: false,
           createdOn: "2019-07-11T11:53:43.323",
           createdBy: 99,
           androidActivityId: 0,
           deliveryStatusId: null,
           deliveryStatus: null,
           parentId: null,
           campaignId: null,
           trackingFormModel: null,
           callDuration: null,
           callTime: null,
           subject: null,
           attachments: null,
           leadScore: null,
           referenceKey: null,
           createdForUserId: 99,
           loggedInUserId: 0,
           clientAlias: null,
           templateId: null,
           isFollowupRequired: true,
           isPreviousActivityDone: false,
           currentActionDateTime: null,
           isMilestone: false,
           savedFilteredListId: null,
           setting: null,
           category: null,
           id: 7
         },
         counselorActivityCount: 41,
         prospectActivityCount: 0,
         message: null,
         result: null,
         counselorName: null,
         metaData: {
           indexedKeyValueAttributes: [
             {
               keyId: 5,
               leadId: 1,
               integerValue: 2,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             },
             {
               keyId: 5,
               leadId: 1,
               integerValue: 1,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             },
             {
               keyId: 8,
               leadId: 1,
               integerValue: 1,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             },
             /*{
               keyId: 6,
               leadId: 1,
               integerValue: 1,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             },*/
             {
               keyId: 10,
               leadId: 1,
               integerValue: 1,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             },
             {
               keyId: 11,
               leadId: 1,
               integerValue: 1,
               boolValue: null,
               textValue: null,
               dateValue: null,
               id: 1
             }
           ],
           keyValueAttributes: [
             {
               keyId: 1,
               value: '{"id":2,"name":"Nationality 02"}',
               leadId: 1,
               id: 1
             },
             {
               keyId: 7,
               leadId: 1,
               value:
                 '{"aadharNumber":"1122343223445","aadharPlaceOfIssue":"Pune"}',
               id: 2
             },
             {
               keyId: 9,
               value:
                 '[{"id":1,"name":"Domicile 01"},{"id":2,"name":"Domicile 02"}]',
               leadId: 1,
               id: 3
             },
             {
               keyId: 12,
               value: "yes",
               leadId: 1,
               id: 4
             },
             {
               keyId: 13,
               leadId: 1,
               value:
                 '{"currentAddressLine":"Main road","currentPincode":"125245","currentCountry":"1","currentState":"1","currentCity":"1"}',
               id: 5
             },
             {
               keyId: 14,
               leadId: 1,
               value:
                 '{"fatherOccupation":"Private Employee","fatherQualification":"Graduate","fatherIncome":"10000","motherOccupation":"House wife","motherQualification":"Primary school passed"}',
               id: 6
             },
             {
               keyId: 15,
               leadId: 1,
               value:
                 '{"guardianName":"Tom C.","guardianCity":1,"relationGuardian":"Uncle","guardianEmail":"mygardian@test.com","guardianAddressLine":"Main road","guardianPincode":"431525","GuardianNumber":9876543210}',
               id: 7
             },
             {
               keyId: 16,
               leadId: 1,
               value:
                 '{"sscNameOfBoard":"Pune Board.","sscYearOfPassing":"2016","sscModeOfStudy":1,"sscBoardType":1,"sscRegistrationNumber":"AC125154","sscPercentageGrade":"90"}',
               id: 8
             },
             {
               keyId: 17,
               leadId: 1,
               value:
                 '{"hscSchoolName":"New English School","hscBoardType":1,"hscResultOutStatus":"yes","hscModeOfStudy":1,"hscRegistrationNumber":"AC125154","hscYearOfPassing":"2014","hscPercentageGrade":50,"hscEvaluationType":"percentage","hscSubjects":[{"subject": "English","maxMarks": "100","markObtain": "50","percentage": "50","id": 1},{"subject": "Marathi","maxMarks": "100","markObtain": "60","percentage": "60","id": 2}]}',
               id: 9
             },
             {
               keyId: 18,
               leadId: 1,
               value:
                 '{"entranceExaminations":[{"examName":"CET","maxMarks":"500","markObtain":"300","percentageMark":"60","id":1}]}',
               id: 10
             },
             {
               keyId: 19,
               leadId: 1,
               value:
                 '{"isStudentHaveGradutionDiplomaDetails":"yes","typeOfGraduation":"degree","graduationDegreeDiplomaName":"B.Sc.","graduationDegreeDiplomaAreaOfSepcialization":"Chemi","graduationDegreeDiplomaCollege":"Pune Collage","graduationDegreeDiplomaYearOfCompletion":"2017","graduationDegreeDiplomaModeofStudy":1,"graduationDegreeDiplomaExaminationType":1,"graduationDegreeDiplomaSubjects":[{"subject":"English","maxMarks":"100","markObtain":"60","percentage":"60","id":1},{"subject":"Maths","maxMarks":"120","markObtain":"75","percentage":"75","id":2}]}',
               id: 11
             },
             {
               keyId: 20,
               leadId: 1,
               value:
                 '{"isStudentHavePostGradutionDiplomaDetails":"yes","typeOfPostGraduation":"degree","postGraduationDegreeDiplomaName":"M.Sc.","postGraduationDegreeDiplomaAreaOfSepcialization":"Chemi","postGraduationDegreeDiplomaCollege":"PuneCollage","postGraduationDegreeDiplomaYearOfCompletion":"2018","postGraduationDegreeDiplomaModeofStudy":1,"postGraduationDegreeDiplomaExaminationType":1,"postGraduationDegreeDiplomaSubjects":[{"subject":"English","maxMarks":"100","markObtain":"60","percentage":"60","id":1},{"subject":"Maths","maxMarks":"120","markObtain":"75","percentage":"75","id":2}]}',
               id: 12
             },
             {
               keyId: 21,
               leadId: 1,
               value:
                 '{"isStudentHaveWorkExperinceDetails":"yes","experienceInMonths":18}',
               id: 13
             },
             {
               keyId: 22,
               leadId: 1,
               value: "yes",
               id: 14
             },
             {
               keyId: 23,
               leadId: 1,
               value:
                 '{"photo":"http://www.azurestorage.com/myphoto.jpg","sslc10thmarkscard":"http://www.azurestorage.com/mysslc10thmarkscard.png"}',
               id: 15
             },
             {
               keyId: 24,
               leadId: 1,
               value:
                 '{"passportNumber":"PO000410","passportDateofIssue":"9/23/2029","passportExpiryDate":"9/23/2029","domicile":[{"id":1,"name":"Domicile 01"},{"id":2,"name":"Domicile 02"}]}',
               id: 16
             }
           ]
         },
         isOtpSent: false,
         multipleLeadEntity: null,
         counselorId: 0,
         leadAlreadyExists: false
       };
