// AppInsights.js
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { get } from 'lodash';
//const instrumentationKey=`0cd23f6b-4994-4784-a94f-d07e6dfeb115`;//extraaedgev2-test
//const instrumentationKey=`14d3ab8c-12d1-42c4-bde3-9e432ce53e71`;//chatbot test
const appInsightConfig=get(window,`extraaedge.eeAppInsight`);
export const IS_APPINSIGHT_ACTIVE= !!appInsightConfig && appInsightConfig.isActive;
//const instrumentationKey=`7972ecf2-6f8f-4163-bf8f-dc4ee4e68c59`;//extraaedge-prodapi
const instrumentationKey=window?.extraaedge?.eeAppInsight?.instrumentationKey;//`c4b51ed1-c848-458f-b16f-d8ce869b1717`;//
const ai = new ApplicationInsights({
    config: { instrumentationKey,
        disableExceptionTracking:true,
        disableAjaxTracking:true,
        enableAutoRouteTracking:false,
        disableFetchTracking:true
    }
});
IS_APPINSIGHT_ACTIVE &&  ai.loadAppInsights();
export const appInsights = ai.appInsights