import { call, put, select, takeLatest } from "redux-saga/effects";
import {
  actionTypes,
  getUserDetailsByLeadId,
  webhookAddLeadFailed,
  webhookAddLeadRequested,
  webhookAddLeadSuccess,
  widgetFormconfigFailed,
  widgetFormconfigRequested,
  widgetFormconfigSuccess,
} from "./Actions";
import { Services } from "./Services";
import { logError } from "zipyai";
import { cloneDeep, get, isNil, uniq } from "lodash";
import { updateAllApplicationData } from "../../shared/services/appData/Actions";
import {
  EntityFieldNameByCriteriaValue,
  STORAGE_KEY,
} from "../../shared/utilties/Constants";
import { fetchConfigurationsForInst } from "../../shared/services/appData/Saga";
import {
  checkIfArray,
  checkIfObject,
  eeCustomAzureLogs,
} from "../../shared/utilties/Utils";
import { toast } from "react-toastify";
import { getClientInfoData } from "../form/Saga";
import * as rdd from "react-device-detect";

export const getwidgetFormconfigReducerData = (state) =>
  state.allApplicationsReducer.widgetFormConfig;

export const getAllApplicationData = (state) =>
  state.appDataReducer.allApplicationsData;

export const getAllApplicationIds = (state) =>
  state.appDataReducer.allApplicationsIds;

export const getAllApplicationInstData = (state) =>
  state.appDataReducer.allApplicatonsInstData;

export const getAllApplicationDataMappedWithLeadIds = (state) =>
  state.appDataReducer.allApplicationsDataMappedWithLeadIds;

export const getInstwiseAllConfigs = (state) =>
  state.appDataReducer.instwiseAllConfigs;

export const getAllMasterData = (state) => state.appDataReducer.masterData;

export const getuserDetailData = (state) => state.appDataReducer.userDetail;

export const getIsCounselorAccessActive = (state) => state.appDataReducer.isCounselorAccessActive;

export const getCounselorDetails = (state) => state.appDataReducer.counselorDetails;

export function* getWidgetFormConfigSaga(action) {
  try {
    const widgetFormConfigReducer = yield select(
      getwidgetFormconfigReducerData
    ); // <-- get reducer data
    if (!widgetFormConfigReducer) {
      yield put(widgetFormconfigRequested());
      const result = yield call(
        Services.callWidgetFormConfigAPI,
        !!action?.payload.data ? action.payload.data : ""
      );
      if (result) {
        yield put(widgetFormconfigSuccess(result));
      } else {
        yield put(widgetFormconfigFailed());
        logError(result);
      }
    } else {
      yield put(widgetFormconfigSuccess(widgetFormConfigReducer));
    }
  } catch (error) {
    logError(error);
  }
}

export function* watchGetWidgetFormConfig() {
  yield takeLatest(actionTypes.GET_WIDGET_FORM_CONFIG, getWidgetFormConfigSaga);
}

export function* webHookAddLeadSaga(action) {
  const {
    apiParams,
    onSucceessAddlead,
    webhookAddLeadCallFrom = "",
  } = action.payload.data || {};
  const clientInfo = yield select(getClientInfoData);
  const userDetail = yield select(getuserDetailData); // <-- get reducer data
  const isCounselorAccessActive = yield select(getIsCounselorAccessActive);
  const counselorDetails = yield select(getCounselorDetails);
  const CId = checkIfObject(counselorDetails) && get(counselorDetails, `id`) || null;
  // 
  let params = cloneDeep(apiParams);
  params = {
    ...params,
    isLeadLogin : !isCounselorAccessActive,
    ...(CId && { CId })
  }
  const { lead: { id: userLeadId } = {}, user: { prn, id: userId } = {} } =
    userDetail || {};
  const CLIENT_ALIAS =
    !isNil(clientInfo) &&
    !isNil(get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`))
      ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
      : "";
  const uniqueId =
    webhookAddLeadCallFrom === "allApplication" ? prn || userId : userLeadId;
  try {
    try {
      CLIENT_ALIAS &&
        uniqueId &&
        eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, uniqueId, {
          method: "webHookAddLeadReq",
          data: params,
          webhookAddLeadCallFrom,
          currentStep: 1,
          deviceDetect: rdd.deviceDetect(),
        });
    } catch (error) {}
    yield put(webhookAddLeadRequested());
    const result = yield call(
      Services.addLead,
      get(window, `extraaedge.API_END_POINT.webHookAddLead`),
      !!params ? params : ""
    );
    const isApplicationFormGenerated = get(
      result,
      "data.lead.applicationFormNumber"
    );
    if (isApplicationFormGenerated) {
      let applicaitonData = get(result, "data");
      const leadId = get(applicaitonData, "lead.id");
      try {
        CLIENT_ALIAS &&
          leadId &&
          eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
            method: "webHookAddLeadRes",
            data: applicaitonData,
            webhookAddLeadCallFrom,
            currentStep: 1,
            deviceDetect: rdd.deviceDetect(),
          });
      } catch (error) {}
      yield put(getUserDetailsByLeadId({ applicaitonData, onSucceessAddlead }));
    } else {
      yield put(webhookAddLeadFailed());
      logError(result);
      toast.error("Something went wrong kindly refresh the page.");
      try {
        CLIENT_ALIAS &&
          uniqueId &&
          eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, uniqueId, {
            method: "webHookAddLeadRes Error Application Form Not Generated",
            data: result,
            webhookAddLeadCallFrom,
            currentStep: 1,
            deviceDetect: rdd.deviceDetect(),
          });
      } catch (error) {}
    }
  } catch (error) {
    yield put(webhookAddLeadFailed());
    toast.error("Something went wrong kindly refresh the page.");
    try {
      CLIENT_ALIAS &&
        uniqueId &&
        eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, uniqueId, {
          method: "webHookAddLeadRes Error",
          data: params,
          webhookAddLeadCallFrom,
          currentStep: 1,
          deviceDetect: rdd.deviceDetect(),
        });
    } catch (error) {}
    logError(error);
  }
}

export function* watchWebHookAddLead() {
  yield takeLatest(actionTypes.WEBHOOK_ADD_LEAD, webHookAddLeadSaga);
}

export function* getUserDetailsByLeadIdSaga(action) {
  let { applicaitonData, onSucceessAddlead } = action.payload.data || {};
  const leadId = get(applicaitonData, "lead.id");
  const clientInfo = yield select(getClientInfoData); // <-- get reducer data
  if (leadId) {
    try {
      const CLIENT_ALIAS =
        !isNil(clientInfo) &&
        !isNil(
          get(clientInfo, `${STORAGE_KEY.CLIENT_INFO}.${STORAGE_KEY.ALIAS}`)
        )
          ? clientInfo[STORAGE_KEY.CLIENT_INFO][STORAGE_KEY.ALIAS]
          : "";
      const result = yield call(Services.getUserDetails, leadId);
      if (result.status === 200 || result.status === 201) {
        CLIENT_ALIAS &&
          leadId &&
          eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
            method: "leadApplicationDetailsSuccess After Webhook Add Lead",
            data: result,
          });
      } else {
        CLIENT_ALIAS &&
          leadId &&
          eeCustomAzureLogs.eeTrackEvent(CLIENT_ALIAS, leadId, {
            method: "leadApplicationDetailsError After Webhook Add Lead",
            error: result,
          });
      }
      applicaitonData = get(result, "data") ? result.data : applicaitonData;
      const masterData = yield select(getAllMasterData);
      const isUkc = get(masterData, STORAGE_KEY.UNIQUE_KEY_CRITERIA);
      let instwiseAllConfigs = yield select(getInstwiseAllConfigs);
      instwiseAllConfigs = checkIfObject(instwiseAllConfigs)
          ? instwiseAllConfigs
          : {};
      const applicationFormCriteria = get(
        masterData,
        STORAGE_KEY.APPLICATION_FORM_CRIETRIA
      );
      const applicationFormCriteriaValue = get(
        applicaitonData,
        `lead.${EntityFieldNameByCriteriaValue[applicationFormCriteria]}`
      );
      const updatedApplicationFormCriteriaValue = checkIfArray(
        applicationFormCriteriaValue
      )
        ? applicationFormCriteriaValue[0]
        : applicationFormCriteriaValue;
      if (isUkc) {
        let allApplicationsData = yield select(getAllApplicationData);
        let allApplicationsIds = yield select(getAllApplicationIds);
        let allApplicatonsInstData = yield select(getAllApplicationInstData);
        let allApplicationsDataMappedWithLeadIds = yield select(
          getAllApplicationDataMappedWithLeadIds
        );
        allApplicationsData = checkIfArray(allApplicationsData)
          ? allApplicationsData
          : [];
        allApplicationsIds = checkIfArray(allApplicationsIds)
          ? allApplicationsIds
          : [];
        const leadId = get(applicaitonData, "lead.id");
        if (applicationFormCriteriaValue) {
          let instName = "";
          checkIfArray(masterData[applicationFormCriteria]) &&
            masterData[applicationFormCriteria].some((masterObj) => {
              if (masterObj.id === updatedApplicationFormCriteriaValue) {
                allApplicatonsInstData = {
                  ...(allApplicatonsInstData || {}),
                  [masterObj.id]: masterObj,
                };
                instName = masterObj.name;
                return true;
              }
              return false;
            });
          applicaitonData = {
            ...applicaitonData,
            instValue: updatedApplicationFormCriteriaValue,
            instName,
          };
          const applicaitonDataIndex = allApplicationsData.findIndex(
            (data) => get(data, "lead.id") === get(applicaitonData, "lead.id")
          );
          if (applicaitonDataIndex >= 0) {
            allApplicationsData[applicaitonDataIndex] = applicaitonData;
          } else {
            allApplicationsData.push(applicaitonData);
          }
          allApplicationsDataMappedWithLeadIds = {
            ...(allApplicationsDataMappedWithLeadIds || {}),
            [leadId]: applicaitonData,
          };
          allApplicationsIds.push(leadId);
          allApplicationsIds = uniq(allApplicationsIds);
          if (!instwiseAllConfigs[updatedApplicationFormCriteriaValue]) {
            const config = yield call(
              fetchConfigurationsForInst,
              updatedApplicationFormCriteriaValue
            );
            instwiseAllConfigs[updatedApplicationFormCriteriaValue] = config;
          }
        }

        yield put(
          updateAllApplicationData({
            allApplicationsData,
            allApplicationsIds,
            allApplicatonsInstData,
            allApplicationsDataMappedWithLeadIds,
            instwiseAllConfigs,
          })
        );
      }else if (!instwiseAllConfigs[updatedApplicationFormCriteriaValue]){
        const config = yield call(
          fetchConfigurationsForInst,
          updatedApplicationFormCriteriaValue
        );
        instwiseAllConfigs[updatedApplicationFormCriteriaValue] = config;
      }
      yield put(webhookAddLeadSuccess(applicaitonData));
      onSucceessAddlead &&
        onSucceessAddlead(applicaitonData, instwiseAllConfigs);
    } catch (error) {}
  }
}

export function* watchGetUserDetailsByLeadId() {
  yield takeLatest(
    actionTypes.GET_USER_DETAILS_BY_LEAD_ID,
    getUserDetailsByLeadIdSaga
  );
}
