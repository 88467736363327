import { FORM_INPUT_UI, MASTER_KEYS, META_DATA_TYPE } from "./Constants";
import { ConvertToUTCUsingMoment, checkIfArray, checkIfObject, getMasterDataByKey, isEmpty } from "./Utils";
// Load the full build.
import {
  get,
  find,
  filter,
  set,
  isNil,
  isBoolean,
  isObject,
  map,
  isNumber,
  sortBy,
  isEqual,
  remove,
  unset,
  pick,
  forEach,
  has
} from "lodash";
export class LeadObjectManager {
  constructor() {
    this.userInfo = null;
    this.masterData = null;
    this.MasterKeys = null;
  }
  init(_userInfo, _masterData) {
    this.userInfo = _userInfo ? _userInfo : null;
    this.masterData = _masterData ? _masterData : null;
    this.MasterKeys = !isNil(this.masterData) && getMasterDataByKey("MasterKeys",this.masterData);
  }
  isAllParamsReady() {
    return (
      !isEmpty(this.userInfo) &&
      !isEmpty(this.masterData) &&
      !isEmpty(this.MasterKeys)
    );
  }
  getValue(mappingString, filter) {
    return filter
      ? find(get(this.userInfo, mappingString), filter)
      : get(this.userInfo, mappingString);
  }
  /*IsJsonString(str) {
    if (typeof str !== "string") return false;
    try {
      const result = JSON.parse(str);
      const type = Object.prototype.toString.call(result);
      return type === "[object Object]" || type === "[object Array]";
    } catch (err) {
      return false;
    }

  }*/

  IsJsonString(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }
  getInputValue(fieldKey, mappingString, db_key_id, fieldParams) {
    if (db_key_id || fieldKey) {
      //check direct value is available
      if (
        !isNil(get(
          this.userInfo,
          mappingString + "." + (!!db_key_id ? db_key_id : fieldKey)
        ))
      ) {
        return get(
          this.userInfo,
          mappingString + "." + (!!db_key_id ? db_key_id : fieldKey)
        );
      }
      let masterKey = find(this.MasterKeys, {
        name: !!db_key_id ? db_key_id : fieldKey
      });

      if (masterKey) {
        //if meta key found - find value in meta data object
        let meta = filter(get(this.userInfo, mappingString), {
          keyId: masterKey.id
        });

        if (meta && Array.isArray(meta) && meta.length > 0) {
          let values = meta.map(function(item, index) {
            if (!isNil(item.doubleValue)) return item.doubleValue;
            if (!isNil(item.integerValue)) return item.integerValue;
            if (!isNil(item.dateValue)) return item.dateValue;
            if (!isNil(item.textValue)) return item.textValue;
            if (!isNil(item.boolValue)) return item.boolValue;
            if (!isNil(item.value)) return item.value;
            return null;
          });
          /*values =
            fieldParams &&
            !fieldParams.db_key_id &&
            !!fieldParams.type &&
            fieldParams.type === FORM_INPUT_UI.SELECT &&
            !!fieldParams.mulitSelect &&
            !isNil(db_key_id) &&
            !isEmpty(db_key_id)
              ? values
              : values[0];*/
              values =
            fieldParams &&
            !!fieldParams.type &&
            fieldParams.type === FORM_INPUT_UI.SELECT &&
            !!fieldParams.mulitSelect
              ? values
              : values[0];
          if (
            !isNil(fieldParams) &&
            !isNil(get(fieldParams, "db_key")) &&
            !isEmpty(get(fieldParams, "db_key")) &&
            get(fieldParams, "db_key").indexOf(META_DATA_TYPE.INDEX_META_TYPE) >
              -1
          ) {
            return values;
          }
          if (values && this.IsJsonString(values)) {
            let metaValueJSON = JSON.parse(values);
            if (!isBoolean(metaValueJSON)) {
              if (!!get(metaValueJSON, fieldKey)) {
                values = get(metaValueJSON, fieldKey);
              } else if (
                !isObject(metaValueJSON) ||
                Array.isArray(metaValueJSON)
              ) {
                values = metaValueJSON;
              } else {
                values = get(metaValueJSON, fieldKey);
              }
              /*if (
                !!metaValueJSON &&
                Array.isArray(metaValueJSON) &&
                fieldParams &&
                fieldParams.type === FORM_INPUT_UI.SELECT
              ) {*/
              if (
                !!values &&
                Array.isArray(values) &&
                fieldParams &&
                fieldParams.type === FORM_INPUT_UI.SELECT
              ) {
                values = map(values, "id");
              } else if (Object.keys(metaValueJSON).includes(fieldKey)) {
                values = !isEmpty(metaValueJSON[fieldKey])
                  ? metaValueJSON[fieldKey]
                  : undefined;
              } else if (Object.keys(metaValueJSON).includes("id")) {
                values = !isEmpty(metaValueJSON["id"])
                  ? metaValueJSON["id"]
                  : undefined;
              }
              if (!isEmpty(values) && !isEmpty(values["id"])) {
                values = values["id"];
              }
            } else {
              values = metaValueJSON;
            }
          } else if (
            fieldParams &&
            !fieldParams.db_key_id &&
            !!fieldParams.type &&
            fieldParams.type === FORM_INPUT_UI.SELECT &&
            !!fieldParams.mulitSelect &&
            !isNumber(values[0])
          ) {
            values = JSON.parse(values[0]);
            values = map(values, "id");
          }
          return values;
        }
      }
      return get(this.userInfo, mappingString + "." + fieldKey);
    } else {
      return get(this.userInfo, mappingString + "." + fieldKey);
    }
  }
  setNormalData(fieldKey, fieldParams, fieldValue) {
    //master key is not mentioned so need to save value direct at specified db_key
    set(
      this.userInfo,
      fieldParams.db_key +
        "." +
        (!!fieldParams.db_key_id ? fieldParams.db_key_id : fieldKey),
      isNil(fieldValue) ? null : fieldValue
    );
  }
  setMetaDataIndexed(
    fieldKey,
    fieldParams,
    fieldValue,
    masterKey,
    isValueMultiSelect,
    updatedIndexMetaDataKey,
    dataTypeWiseValues={}
  ) {
    // if (!!fieldValue) {
    let existingValue = this.getInputValue(
      fieldKey,
      fieldParams.db_key,
      fieldParams.db_key_id,
      fieldParams
    );
    if (isValueMultiSelect) {
      if (
        !(!isNil(fieldValue) && !isNil(existingValue)) ||
        !isEqual(sortBy(fieldValue), sortBy(existingValue))
      ) {
        remove(get(this.userInfo, fieldParams.db_key), function(item) {
          return (
            item.keyId === masterKey.id &&
            (!fieldValue ||
              (item.boolValue && !fieldValue.includes(item.boolValue)) ||
              (item.textValue && !fieldValue.includes(item.textValue)) ||
              (item.doubleValue && !fieldValue.includes(item.doubleValue)) ||
              (item.integerValue && !fieldValue.includes(item.integerValue)) ||
              (item.dateValue && !fieldValue.includes(item.dateValue)))
          );
        });
        if (!isEmpty(fieldValue)) {
          let newValues = existingValue
            ? filter(fieldValue, item => {
                if (!existingValue.includes(item)) return item;
              })
            : fieldValue;
          let newMetaObjects = map(newValues, item => {
            return this.generateIndexKeyValue(
              Object.assign(fieldParams, {
                fieldKey,
                value: item,
                masterKey,
                dataTypeWiseValues
              })
            );
          });
          newMetaObjects &&
            get(this.userInfo, fieldParams.db_key) &&
            set(
              this.userInfo,
              fieldParams.db_key,
              get(this.userInfo, fieldParams.db_key).concat(newMetaObjects)
            );
          updatedIndexMetaDataKey.push(newMetaObjects);
        }
      }
    } else if (existingValue !== fieldValue) {
      if (!isNil(existingValue)) {
        remove(get(this.userInfo, fieldParams.db_key), function(item) {
          return item.keyId === masterKey.id;
        });
      }
      if (!isNil(fieldValue)) {
        let newMetaObject = this.generateIndexKeyValue(
          Object.assign(fieldParams, {
            fieldKey,
            value: fieldValue,
            masterKey,
            dataTypeWiseValues
          })
        );
        let collection = get(this.userInfo, fieldParams.db_key) || [];
        newMetaObject && collection && collection.push(newMetaObject);
        updatedIndexMetaDataKey.push(collection);
        set(this.userInfo, fieldParams.db_key, collection);
      }
    }
    // }
  }
  setMetaDataNonIndexed(
    fieldKey,
    fieldParams,
    fieldValue,
    masterKey,
    isValueMultiSelect,
    updatedKeyMetaDataKey
  ) {
    let existingValue = this.getInputValue(
      fieldKey,
      fieldParams.db_key,
      fieldParams.db_key_id,
      fieldParams
    );
    if (
      !isEmpty(get(fieldParams, "type")) &&
      !isEmpty(get(fieldParams, "dataCollectionName")) &&
      !isEmpty(
        getMasterDataByKey(
          get(fieldParams, "dataCollectionName"),
          this.masterData
        )
      )
    ) {
      fieldValue = this.getDropdownFieldSelectedItems(
        fieldParams,
        Array.isArray(fieldValue) ? sortBy(fieldValue) : fieldValue,
        isValueMultiSelect
      );
    }
    if (
      isEmpty(existingValue) ||
      isEmpty(fieldValue) ||
      (!isEmpty(existingValue) &&
        !isEmpty(fieldValue) &&
        JSON.stringify(existingValue) !== JSON.stringify(fieldValue))
    ) {
      let obj = null;
      if (isEmpty(fieldParams.db_key_id)) {
        if (isEmpty(existingValue) && !isEmpty(fieldValue)) {
          obj = this.generateKeyValue(
            Object.assign(fieldParams, {
              fieldKey,
              value: JSON.stringify(fieldValue), //this.IsJsonString(fieldValue)? JSON.stringify(fieldValue):fieldValue,
              masterKey
            })
          );
        } else if (!isEmpty(fieldValue)) {
          find(get(this.userInfo, fieldParams.db_key), {
            keyId: masterKey.id
          }).value = JSON.stringify(fieldValue); //this.IsJsonString(fieldValue) || Array.isArray(fieldValue)?JSON.stringify(fieldValue):fieldValue.toString();
        } else if (!isEmpty(existingValue) && isEmpty(fieldValue)) {
          //for empty value so remove from keyvalue collection if exist
          let collection = get(this.userInfo, fieldParams.db_key);
          remove(collection, function(item) {
            return item.keyId === masterKey.id;
          });
        }
        if (obj) {
          this.addUpdateEntryToMetaData(
            fieldParams,
            masterKey,
            obj,
            true,
            updatedKeyMetaDataKey
          );
        }
      } else if (!isEmpty(fieldParams.db_key_id)) {
        let parentObject = find(get(this.userInfo, fieldParams.db_key), {
          keyId: masterKey.id
        });
        if (!parentObject) {
          obj = this.generateKeyValue(
            Object.assign(fieldParams, {
              fieldKey: fieldParams.db_key_id,
              value: JSON.stringify({ [fieldKey]: fieldValue }),
              masterKey
            })
          );
          if (obj) {
            this.addUpdateEntryToMetaData(
              fieldParams,
              masterKey,
              obj,
              true,
              updatedKeyMetaDataKey
            );
          }
        } else {
          let parentObjectValue =
            !isEmpty(parentObject) && !isEmpty(get(parentObject, "value"))
              ? JSON.parse(get(parentObject, "value"))
              : null;
          parentObjectValue && !isEmpty(fieldValue)
            ? set(parentObjectValue, fieldKey, fieldValue)
            : unset(parentObjectValue, fieldKey);
          if (
            !isEmpty(parentObjectValue) &&
            (Object.keys(parentObjectValue).length > 0 ||
              (Array.isArray(parentObjectValue) &&
                parentObjectValue.length > 0))
          ) {
            set(parentObject, "value", JSON.stringify(parentObjectValue));
            this.addUpdateEntryToMetaData(
              fieldParams,
              masterKey,
              parentObject,
              true,
              updatedKeyMetaDataKey
            );
          } else {
            remove(get(this.userInfo, fieldParams.db_key), function(item) {
              return item.keyId === masterKey.id;
            });
          }
        }
      }
    }
  }
  getDropdownFieldSelectedItems(fieldParams, fieldValue, isValueMultiSelect) {
    if (fieldParams && !!fieldValue) {
      let selectedItems = getMasterDataByKey(
        get(fieldParams, "dataCollectionName"),
        this.masterData
      ).filter(item => {
        return Array.isArray(fieldValue) && fieldValue.length > 0
          ? fieldValue.includes(item.id)
          : fieldValue.toString() === item.id.toString();
      });
      selectedItems = map(selectedItems, o => pick(o, ["id", "name"])); // pick(selectedItems, ["id", "name"]);
      return !isValueMultiSelect ? selectedItems[0] : selectedItems;
    }
    return null;
  }

  setInputValue(
    fieldKey,
    fieldParams,
    fieldValue,
    updatedKeyMetaDataKey,
    updatedIndexMetaDataKey,
    dataTypeWiseValues,
    initialValue
  ) {
    //1. Set direct value in user or lead object

    //2. Set direct value in index meta data if db_key_id is not mentioned
    // - 2a. Check same meta data is already exist then skip

    //3. Set direct value in non-index meta data if db_key_id is not mentioned
    // - 3a. Check same meta data is already exist then skip

    //4. Set value in index meta data if db_key_id is mentioned
    // - 4a. Check same value exist in nested meta data then skip

    //5. Set value in non-index meta data if db_key_id is mentioned
    // - 5a. Check same value exist in nested meta data then skip
    //if (!!fieldValue) {
    let isValueMultiSelect =
      get(fieldParams, "mulitSelect") !== undefined
        ? get(fieldParams, "mulitSelect")
        : false;
    let masterKey = find(this.MasterKeys, {
      name: !!fieldParams.db_key_id ? fieldParams.db_key_id : fieldKey
    });
    let value =
      !isValueMultiSelect &&
      Array.isArray(fieldValue) &&
      (get(fieldParams, "type") === FORM_INPUT_UI.SELECT || get(fieldParams, "type") === FORM_INPUT_UI.SLOT_BOOKING)
        ? fieldValue[0]
        : fieldValue;
    value =
      fieldParams.type === FORM_INPUT_UI.DATE && !isNil(value)
        ? ConvertToUTCUsingMoment(value)
        : value;
    if (!masterKey) {
      //master key not found means need to save direct value in db_key object
      let useSameValueForFields = ["entity1Id","entity2Id","entity3Id","entity4Id"];
      this.setNormalData(fieldKey, fieldParams, (useSameValueForFields.includes(fieldKey) || (!!fieldParams.db_key_id && useSameValueForFields.includes(fieldParams.db_key_id)))? fieldValue : value);
    } else {
      //master key found
      if (fieldParams.db_key.indexOf(META_DATA_TYPE.INDEX_META_TYPE) > -1) {
        //save indexed data
        this.setMetaDataIndexed(
          fieldKey,
          fieldParams,
          value,
          masterKey,
          isValueMultiSelect,
          updatedIndexMetaDataKey,
          dataTypeWiseValues
        );
      } else {
        this.setMetaDataNonIndexed(
          fieldKey,
          fieldParams,
          value,
          masterKey,
          isValueMultiSelect,
          updatedKeyMetaDataKey
        );
      }
      if (get(fieldParams, "type") === FORM_INPUT_UI.GRID3) {
        if (checkIfArray(initialValue) && !value) {
          const {
            gridLayout: {
              meta,
              pdfLayoutSetting: {
                printAllRow: { metaKey: printAllRowMetaKey } = {},
              } = {},
            } = {},
          } = fieldParams;
          initialValue.map((gridPrintAllRowData) => {
            if (checkIfObject(gridPrintAllRowData)) {
              let uniqueRowMetaKey = gridPrintAllRowData.conditionalRowMetakey
              ? gridPrintAllRowData.conditionalRowMetakey
              : printAllRowMetaKey
              const grid3masterCollection =
                gridPrintAllRowData[uniqueRowMetaKey];
              this.updatedGrid3IndexKeyValueAttributes(
                {},
                grid3masterCollection,
                meta,
                uniqueRowMetaKey,
                fieldKey
              );
            }
          });
        }
      }
    }
    //  }
  }
  addUpdateEntryToMetaData(
    fieldParams,
    masterKey,
    obj,
    isRemoveExistingValue,
    updatedKeyMetaDataKey,
    uniqueKeyToFilterMeataData,
    uniqueCheckValuesToResetIndexKeyValue
  ) {
    updatedKeyMetaDataKey.push(obj);
    let meta_DB_Key = fieldParams.db_key;
    if (get(this.userInfo, meta_DB_Key)) {
      let meta_data_collection = get(this.userInfo, meta_DB_Key);
      if(checkIfObject(uniqueKeyToFilterMeataData)){
        const uniqueKey = Object.keys(uniqueKeyToFilterMeataData)[0];
        const uniqueValue = Object.values(uniqueKeyToFilterMeataData)[0];
        if(checkIfObject(uniqueCheckValuesToResetIndexKeyValue)){
          const {conditionalRowMetakey, formData} = uniqueCheckValuesToResetIndexKeyValue || {};
          const existingFormData = checkIfArray(formData) && formData.find(ele => ele.conditionalRowMetakey === conditionalRowMetakey);
          const conditionalUniqueValue = get(existingFormData, `${conditionalRowMetakey}`);
          let uniqueValue = "";
          if(checkIfObject(conditionalUniqueValue)){
            uniqueValue = conditionalUniqueValue.id;
          }else if(conditionalUniqueValue){
            uniqueValue = conditionalUniqueValue;
          }
          meta_data_collection = 
          uniqueValue ? meta_data_collection.filter(item => 
            {
              if(item.keyId === masterKey.id){
               return item[uniqueKey] !== uniqueValue
              }
              return true;
            })
          : meta_data_collection
        }
        if(uniqueValue){
          meta_data_collection = 
          meta_data_collection.filter(item => 
            {
              if(item.keyId === masterKey.id){
               return item[uniqueKey] !== uniqueValue
              }
              return true;
            })
        }else{
          obj = null;
        }        
      }else{
        meta_data_collection =
        !isNil(isRemoveExistingValue) && isRemoveExistingValue === true
          ? meta_data_collection.filter(item => item.keyId !== masterKey.id)
          : meta_data_collection;
      }      
      checkIfObject(obj) && meta_data_collection.push(obj);
      set(this.userInfo, meta_DB_Key, meta_data_collection);
    } else {
      set(this.userInfo, meta_DB_Key, [obj]);
    }
  }
  updatedGrid3IndexKeyValueAttributes = (gridRowData, gridPrintAllRowData, headerItems, rowMetaKey, fieldKey, userKeyValueAttributes) => {
    headerItems.forEach((item) => {
      const { db_key, metaKey, db_key_id, db_marks_key } = item;
      if (db_key) {
        let inputValue = gridRowData[metaKey] || "";
        let masterKeyName = db_key_id;
        let rowKey = ""
        const {name , id} = checkIfObject(gridPrintAllRowData) ? gridPrintAllRowData :  gridRowData[rowMetaKey]  || {};
        if(db_key_id){
          rowKey = id;
        }else{
          masterKeyName = this.getMasterKeyNameByMasterCollection(
            name,
            fieldKey
          )
        }
        if (masterKeyName) {
          let newIndexKeyValueItem = {};
          let masterKey = this.getMasterKeyDetails(
            {},
            masterKeyName
          );
          if (inputValue && db_key.indexOf(META_DATA_TYPE.INDEX_META_TYPE) > -1) {
            newIndexKeyValueItem = this.generateIndexKeyValue(
              Object.assign(item, {
                value: inputValue,
                doubleValue: !!db_marks_key && inputValue ? parseFloat(inputValue) : null,
                masterKey,
              },
              db_key_id ? {integerValue: rowKey} : {}
              )
            );
          }
          if (item && !!db_key && !!metaKey) {
            this.addUpdateEntryToMetaData(
              item,
              masterKey,
              newIndexKeyValueItem,
              true,
              this.getMetaDataExistingCollection(db_key),
              db_key_id && {integerValue: rowKey},
              userKeyValueAttributes
            );
          }
        }
      }
    });
  }

  getMasterKeyNameByMasterCollection = (
    masterCollectionOptionName,
    db_key_id
  ) => {
    if (masterCollectionOptionName) {
      let masterKeyName =
        `${db_key_id}_` + masterCollectionOptionName.replaceAll(" ", "_");
      return masterKeyName;
    }
    return "";
  };

  getUserInfo() {
    return this.userInfo ? this.userInfo : null;
  }
  setUserInfo(_userInfo) {
    this.userInfo = _userInfo ? _userInfo : null;
  }
  setValue(mappingString, value) {
    return mappingString;
  }
  generateIndexKeyValue(params) {
    const {masterKey:{id=null,type=null}={},value,doubleValue,integerValue,dataTypeWiseValues={}} = params || {};
    if (
      this.userInfo &&
      this.userInfo.lead &&
      !!this.userInfo.lead.id &&
      !!id &&
      !!type
    ){
      let output ={
        keyId: id,
        leadId: this.userInfo.lead.id,
        doubleValue: !isEmpty(doubleValue)
          ? doubleValue
          : type === MASTER_KEYS.TYPE.DOUBLE
          ? value
          : null,
        integerValue: !isEmpty(integerValue)
          ? integerValue
          : type === MASTER_KEYS.TYPE.INTEGER
            ? value
            : null,
        boolValue:
          type === MASTER_KEYS.TYPE.BOOLEAN
            ? value
            : null,
        textValue:
          type === MASTER_KEYS.TYPE.TEXT ? value : null,
        dateValue:
          type === MASTER_KEYS.TYPE.DATE ? value : null,
        id: 0
      }; 
      if(dataTypeWiseValues!=null && Object.keys(dataTypeWiseValues).length>0){
        forEach(dataTypeWiseValues,(item,key)=>{
          if(has(output,key) && isNil(output[key])){
            output[key]=item;
          }
        });
      }
      return output;
    }
  }
  generateKeyValue(params) {
    return {
      keyId: params.masterKey.id,
      leadId: this.userInfo.lead.id,
      value: params.value,
      id: 0
    };
  }
  /**
   * @description Get MasterKey's details from master data collection.
   * @param {*} fieldParams
   * @param {*} fieldKey
   */
  getMasterKeyDetails(fieldParams, fieldKey) {
    return (
      fieldParams &&
      fieldKey &&
      this.MasterKeys &&
      find(this.MasterKeys, {
        name: !!fieldParams.db_key_id ? fieldParams.db_key_id : fieldKey
      })
    );
  }
  /**
   * @description Get existing meta data by collection key (index or non-index)
   * @param {*} meta_data_key
   */
  getMetaDataExistingCollection(meta_data_key) {
    return !!meta_data_key &&
      this.userInfo &&
      this.userInfo.metaData &&
      this.userInfo.metaData[meta_data_key]
      ? this.userInfo.metaData[meta_data_key]
      : [];
  }
  /**
   *
   * @param {*} fieldParams
   * @param {*} masterKey
   * @param {*} obj
   */
  removeEntryFromMetaData(fieldParams, masterKey, value) {
    let meta_DB_Key = fieldParams.db_key;
    if (get(this.userInfo, meta_DB_Key)) {
      let meta_data_collection = get(this.userInfo, meta_DB_Key);
      meta_data_collection = meta_data_collection.filter(function(item) {
        if (!isEmpty(value)) {
          let flag =
            item.keyId !== masterKey.id ||
            !(
              item.keyId === masterKey.id &&
              ((!isNil(item.doubleValue) && item.doubleValue === value) ||
                (!isNil(item.integerValue) && item.integerValue === value) ||
                (!isNil(item.boolValue) && item.boolValue === value) ||
                (!isNil(item.textValue) && item.textValue === value) ||
                (!isNil(item.dateValue) && item.dateValue === value) ||
                (!isNil(item.value) && item.value === value))
            );
          return flag;
        } else {
          return item.keyId !== masterKey.id;
        }
      });
      set(this.userInfo, meta_DB_Key, meta_data_collection);
    }
  }
}
