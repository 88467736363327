import { setupProfileMock } from "../../shared/services/profile/mock/ProfileMockService";
import { setupPersonalMock } from "../../shared/services/personal/mock/PersonalMockService";
import { setupConfigMock as setupFormConfigMock } from "../../pages/form/mock/MockService";
import { setupConfigMock as setupLoginConfigMock } from "../../pages/login/mock/MockService";
import { setupGetMasterMock } from "../../shared/services/appData/mock/mockService";

export const MockFunctions = [
  setupProfileMock,
  setupPersonalMock,
  setupFormConfigMock,
  setupLoginConfigMock,
  setupGetMasterMock
];
