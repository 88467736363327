import { call, put, select, takeLatest } from "redux-saga/effects";
import { logError } from "../../infrastructure/logging/Logger";
import {
  actionTypes,
  configFailed,
  configRequested,
  configSuccess
} from "./Actions";
import { Service } from "./Services";
export const getLoginConfig = state => state.loginReducer.config;
export function* getConfig(action) {
  //CAll api
  try {
    yield put(configRequested(""));
    const loginConfig = yield select(getLoginConfig); // <-- get reducer data

    if (loginConfig && Object.keys(loginConfig).length > 0) {
      yield put(configSuccess(loginConfig));
    } else {
      const result = yield call(
        Service.getConfig,
        action.payload && !!action.payload.data ? action.payload.data : ""
      );
      yield put(configSuccess(result));
    }
  } catch (error) {
    logError(error);
    yield put(configFailed(JSON.stringify(error)));
  }
}

export function* watchConfigData() {
  yield takeLatest(actionTypes.GET_LOGIN_CONFIG, getConfig);
}
