import { call, put, select, takeLatest } from "redux-saga/effects";
import { logError } from "../../infrastructure/logging/Logger";
import {
  actionTypes,
  configFailed,
  configRequested,
  configSuccess,
  resetPasswordFail,
  resetPasswordRequested,
  resetPasswordSuccess
} from "./Actions";
import { Service } from "./Services";
export const getStoredConfig = state => state.resetPasswordReducer.config;
export function* getConfig(action) {
  //CAll api
  try {
    yield put(configRequested(""));
    const storedConfig = yield select(getStoredConfig); // <-- get reducer data

    if (storedConfig && Object.keys(storedConfig).length > 0) {
      yield put(configSuccess(storedConfig));
    } else {
      const result = yield call(
        Service.getConfig,
        action.payload && !!action.payload.data ? action.payload.data : ""
      );
      yield put(configSuccess(result));
    }
  } catch (error) {
    logError(error);
    yield put(configFailed(JSON.stringify(error)));
  }
}
export function* validateResetPassword(action) {
  //CAll api
  try {
    yield put(resetPasswordRequested(""));
    const result = yield call(Service.resetPassword, action.payload.data);
    result && result.data && (yield put(resetPasswordSuccess(result.data)));
  } catch (error) {
    logError(error);
    yield put(resetPasswordFail(error));
  }
}
export function* watchConfigData() {
  yield takeLatest(actionTypes.GET_RESET_PASSWORD_CONFIG, getConfig);
}
export function* watchResetPassword() {
  yield takeLatest(actionTypes.RESET_PASSWORD_INTI, validateResetPassword);
}
