import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in all application component */
export const actionTypes = {
  GET_WIDGET_FORM_CONFIG: "app/pages/allApplications/WIDGET_FORM_CONFIG/GET",
  WIDGET_FORM_CONFIG_REQUESTED:
    "app/pages/allApplications/WIDGET_FORM_CONFIG/REQUESTED",
  WIDGET_FORM_CONFIG_SUCCESS:
    "app/pages/allApplications/WIDGET_FORM_CONFIG/SUCCESS",
  WIDGET_FORM_CONFIG_FAILED:
    "app/pages/allApplications/WIDGET_FORM_CONFIG/FAILED",
  SET_DROPDOWN_OPTIONS: "app/pages/allApplications/SET_DROPDOWN_OPTIONS",
  SET_NESTED_MAPPED_OBJECT:
    "app/pages/allApplications/SET_NESTED_MAPPED_OBJECT",
  WEBHOOK_ADD_LEAD: "app/pages/allApplications/WEBHOOK_ADD_LEAD",
  WEBHOOK_ADD_LEAD_REQUESTED:
    "app/pages/allApplications/WEBHOOK_ADD_LEAD/REQUESTED",
  WEBHOOK_ADD_LEAD_SUCCESS:
    "app/pages/allApplications/WEBHOOK_ADD_LEAD/SUCCESS",
  WEBHOOK_ADD_LEAD_FAILED: "app/pages/allApplications/WEBHOOK_ADD_LEAD/FAILED",

  GET_USER_DETAILS_BY_LEAD_ID:
    "app/pages/allApplications/GET_USER_DETAILS_BY_LEAD_ID",
};

/* Declear here all actions used in component */
export const getWidgetFormConfig = createActionCreator(
  actionTypes.GET_WIDGET_FORM_CONFIG,
  "data"
);
export const widgetFormconfigRequested = createActionCreator(
  actionTypes.WIDGET_FORM_CONFIG_REQUESTED,
  ""
);
export const widgetFormconfigSuccess = createActionCreator(
  actionTypes.WIDGET_FORM_CONFIG_SUCCESS,
  "data"
);
export const widgetFormconfigFailed = createActionCreator(
  actionTypes.WIDGET_FORM_CONFIG_FAILED,
  "errorMessage"
);

export const setDropdownOptions = createActionCreator(
  actionTypes.SET_DROPDOWN_OPTIONS,
  "data"
);

export const setNestedMappedObject = createActionCreator(
  actionTypes.SET_NESTED_MAPPED_OBJECT,
  "data"
);

export const webhookAddLead = createActionCreator(
  actionTypes.WEBHOOK_ADD_LEAD,
  "data"
);

export const webhookAddLeadRequested = createActionCreator(
  actionTypes.WEBHOOK_ADD_LEAD_REQUESTED,
  ""
);

export const webhookAddLeadSuccess = createActionCreator(
  actionTypes.WEBHOOK_ADD_LEAD_SUCCESS,
  "data"
);

export const webhookAddLeadFailed = createActionCreator(
  actionTypes.WEBHOOK_ADD_LEAD_FAILED,
  "data"
);

export const getUserDetailsByLeadId = createActionCreator(
  actionTypes.GET_USER_DETAILS_BY_LEAD_ID,
  "data"
);
