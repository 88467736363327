import { get } from "lodash";
import { API } from "../../infrastructure/network/HttpClient";
import { QUERYAPI } from "../../infrastructure/network/HttpClient";

export const Service = {
  getConfig: function (url) {
    //return API.get("/dashboard/config").finally(() => {});
    return fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return myJson;
      })
      .catch((error) => {
        // handle your errors here
        console.error(error);
        return null;
      });
  },
  getSection: function (params) {
    return QUERYAPI.post(get(window, `extraaedge.APPFORM_V2_API_END_POINT.getAdvtSections`)
      ? window.extraaedge.APPFORM_V2_API_END_POINT.getAdvtSections
      : `/AdCarousel/sections/get`, params)
  }
};
