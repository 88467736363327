import { cloneDeep, get } from "lodash";
import { actionTypes as appActionTypes } from "../../shared/services/appData/Actions";
import { checkIfArray, parseJsonSafely } from "../../shared/utilties/Utils";
import { actionTypes } from "./Actions";

const defaultState = {
  isInProgress: false,
  data: {},
  errorMessage: "",
  sectionDetails: {},
  updateOn: new Date().toLocaleString(),
  sectionAPIIsProgress: false
};
export function reducer(state = defaultState, action) {
  switch (action.type) {
    case appActionTypes.LOGOUT: {
      return {
        ...defaultState
      };
    }
    case actionTypes.CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true
      };
    }
    case actionTypes.CONFIG_SUCCESS: {
      return {
        ...state,
        isInProgress: false,
        data: action.payload.data,
        updateOn: new Date().toLocaleString()
      };
    }
    case actionTypes.CONFIG_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isInProgress: false
      };
    }
    case actionTypes.GET_SECTION_REQUESTED: {
      return {
        ...state,
        sectionAPIIsProgress: true
      };
    }
    case actionTypes.GET_SECTION_SUCCESS: {
      const request = action.payload.data;
      const {data: requestData} = request || {};
      const {code, data, message} = requestData|| {};
      let newSectionDetails = cloneDeep(state.sectionDetails);
      if(code === 200 && checkIfArray(data)){
        data.forEach((data)=>{
          const {sectionId, sectionSettingJson,sectionTabs, tabIds} = data || {};
          const tabs = checkIfArray(sectionTabs) ? sectionTabs.reduce((object, data)=>{
            const {settingJson, tabId} = data || {};
            object = {
              ...(object || {}),
              [tabId] : {...data, data: parseJsonSafely(settingJson, sectionId, tabId)}
            }
            return object
          }, {}) : {}
          newSectionDetails = {
            ...newSectionDetails,
            [sectionId] : {...data, data:  parseJsonSafely(sectionSettingJson, sectionId, ""), sectionTabs: tabs, tabIds: tabIds && tabIds.split(",")}
          }
        })
        return {
          ...state,
          sectionAPIIsProgress: false,
          sectionDetails: newSectionDetails,
        };
      }else{
        console.error("No Data Found")
        return {
          ...state,
          sectionAPIIsProgress: false,
          sectionDetails: newSectionDetails,
          message: message || "Error In Fetching Data"
        };
      }
    }
    case actionTypes.GET_SECTION_FAILED: {
      return {
        ...state,
        sectionAPIIsProgress: false,
        errorMessage: action.payload.errorMessage,
      };
    }
    default: {
      return state;
    }
  }
}
