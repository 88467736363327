import { ProfileData } from "./ProfileMockData";

export function setupProfileMock(mock) {
  mock
    .onGet(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.profile
        ? window.extraaedge.API_END_POINT.profile
        : "/profile"
    )
    .reply(200, ProfileData);
}
