import { call, put, takeLatest } from "redux-saga/effects";
import {
  actionTypes,
  conversationMarkAsReadFailed,
  conversationMarkAsReadRequested,
  conversationMarkAsReadSuccess,
  createConversationFailed,
  createConversationRequested,
  createConversationSuccess,
  getAllConversationFailed,
  getAllConversationRequested,
  getAllConversationSuccess,
  getAllQueryFailed,
  getAllQueryRequested,
  getAllQuerySuccess,
  getQueryCountFailed,
  getQueryCountRequested,
  getQueryCountSuccess,
  getTotalUnreadMessageCountFailed,
  getTotalUnreadMessageCountRequested,
  getTotalUnreadMessageCountSuccess,
  submitQueryFailed,
  submitQueryRequested,
  submitQuerySuccess,
} from "./Actions";
import { Services } from "./Services";
import { getLang, notify } from "../../shared/utilties/Utils";
import { ToastType } from "react-toastify";
import { logError } from "zipyai";
import { get } from "lodash";

//--------------------------------------------------------------------

export function* submitQuerySaga(action) {
  try {
    const { params, onSuccessSubmitquery } = action.payload || {};
    yield put(submitQueryRequested(""));
    const result = yield call(Services.callSubmitQueryAPI, params);
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (data) {
      notify(getLang("query.askQueryModal.queryAddedSuccessMessage"), {
        type: ToastType.SUCCESS,
        autoClose: true,
      });
      yield put(submitQuerySuccess(data));
      onSuccessSubmitquery && onSuccessSubmitquery();
    } else {
      notify(message || result?.message, {
        type: ToastType.ERROR,
        autoClose: true,
      });
      yield put(submitQueryFailed());
      logError(result);
    }
  } catch (error) {
    yield put(submitQueryFailed());
    notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchSubmitQuery() {
  yield takeLatest(actionTypes.SUBMIT_QUERY, submitQuerySaga);
}

//--------------------------------------------------------------------

export function* getAllQuerySaga(action) {
  try {
    yield put(getAllQueryRequested(""));
    const { userId, clientAlias } = action.payload || {};
    const result = yield call(Services.getAllQueries, userId, clientAlias);
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (Array.isArray(data)) {
      yield put(getAllQuerySuccess(data));
    } else {
      notify(message || result?.message, {
        type: ToastType.ERROR,
        autoClose: true,
      });
      yield put(getAllQueryFailed());
      logError(result);
    }
  } catch (error) {
    yield put(getAllQueryFailed());
    notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchGetAllQuery() {
  yield takeLatest(actionTypes.GET_ALL_QUERY, getAllQuerySaga);
}

//--------------------------------------------------------------------

export function* getQueryCountSaga(action) {
  try {
    yield put(getQueryCountRequested(""));
    const { userId, clientAlias } = action.payload || {};
    const result = yield call(Services.getQueryCount, userId, clientAlias);
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (Array.isArray(data)) {
      let queryCount = { resolvedCount: 0, unResolvedCount: 0 };
      data.forEach(({ statusId, count }) => {
        if (statusId) {
          queryCount = {
            ...queryCount,
            resolvedCount: count,
          };
        } else {
          queryCount = {
            ...queryCount,
            unResolvedCount: count,
          };
        }
      });
      yield put(getQueryCountSuccess(queryCount));
    } else {
      yield put(getQueryCountFailed());
      logError(result);
    }
  } catch (error) {
    yield put(getQueryCountFailed());
    notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchGetQueryCount() {
  yield takeLatest(actionTypes.GET_QUERY_COUNT, getQueryCountSaga);
}

//--------------------------------------------------------------------

export function* getAllConversationSaga(action) {
  try {
    yield put(getAllConversationRequested(action.payload.ticketId));
    const { ticketId, clientAlias, onSuccessGetAllConversations } =
      action.payload || {};
    const result = yield call(
      Services.getAllConversation,
      ticketId,
      clientAlias
    );
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (Array.isArray(data)) {
      data.shift();
      yield put(
        getAllConversationSuccess({
          conversations: data,
          ticketId: action.payload.ticketId,
        })
      );
      onSuccessGetAllConversations && onSuccessGetAllConversations();
    } else {
      notify(message || result?.message, {
        type: ToastType.ERROR,
        autoClose: true,
      });
      yield put(getAllConversationFailed());
      logError(result);
    }
  } catch (error) {
    yield put(getAllConversationFailed());
    notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchGetAllConversation() {
  yield takeLatest(actionTypes.GET_ALL_CONVERSATION, getAllConversationSaga);
}

//--------------------------------------------------------------------

export function* createConversationSaga(action) {
  try {
    const { apiPrams, onSuccessReply } = action.payload.params || {};
    yield put(createConversationRequested(get(apiPrams, "ticketId")));
    const result = yield call(Services.createConveration, apiPrams);
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (data) {
      yield put(createConversationSuccess(data));
      onSuccessReply && onSuccessReply();
    } else {
      notify(message || result?.message, {
        type: ToastType.ERROR,
        autoClose: true,
      });
      yield put(createConversationFailed());
      logError(result);
    }
  } catch (error) {
    yield put(createConversationFailed());
    notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchCreateConversation() {
  yield takeLatest(actionTypes.CREATE_CONVERSATION, createConversationSaga);
}

//--------------------------------------------------------------------

export function* conversationMarkAsReadSaga(action) {
  try {
    yield put(conversationMarkAsReadRequested(""));
    const { ticketId } = action.payload || {};
    const result = yield call(Services.conversationMarkAsRead, action.payload);
    const apiResponse = result?.data;
    const { code, success } = apiResponse || {};
    if (code === 200 && success) {
      yield put(conversationMarkAsReadSuccess(ticketId));
    } else {
      yield put(conversationMarkAsReadFailed());
      logError(result);
    }
  } catch (error) {
    yield put(conversationMarkAsReadFailed());
    logError(error);
  }
}

export function* watchConversationMarkAsRead() {
  yield takeLatest(
    actionTypes.CONVERSATION_MARK_AS_READ,
    conversationMarkAsReadSaga
  );
}

//--------------------------------------------------------------------

export function* getTotalUnreadMessageCountSaga(action) {
  try {
    yield put(getTotalUnreadMessageCountRequested(""));
    const result = yield call(Services.getTotalUnreadMessageCount, {
      ...action.payload,
      queryType: "user",
      id: get(action.payload, "userId"),
    });
    const apiResponse = result?.data;
    const { code, data, message } = apiResponse || {};
    if (data) {
      yield put(
        getTotalUnreadMessageCountSuccess(data.totalUnreadMessageCount || 0)
      );
    } else {
      yield put(getTotalUnreadMessageCountFailed());
      logError(result);
    }
  } catch (error) {
    yield put(getTotalUnreadMessageCountFailed());
    // notify(error?.message, { type: ToastType.ERROR, autoClose: true });
    logError(error);
  }
}

export function* watchGetTotalunreadMessageCount() {
  yield takeLatest(
    actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT,
    getTotalUnreadMessageCountSaga
  );
}
