import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in hello world component */
export const actionTypes = {
  GET_FORM_CONFIG: "app/pages/form/config/GET",
  GET_FORM_CONFIG_REQUESTED: "app/pages/form/config/GET/REQUESTED",
  GET_FORM_CONFIG_SUCCESS: "app/pages/form/config/GET/SUCCESS",
  GET_FORM_CONFIG_FAILED: "app/pages/form/config/GET/FAILED",
  UPDATE_FORM_CONFIG: "app/pages/form/config/UPDATE",
  UPDATE_FORM_CONFIG_REQUESTED: "app/pages/form/config/UPDATE/REQUESTED",
  UPDATE_FORM_CONFIG_SUCCESS: "app/pages/form/config/UPDATE/SUCCESS",
  UPDATE_FORM_CONFIG_FAILED: "app/pages/form/config/UPDATE/FAILED",

  UPDATE_FORM_DATA: "app/pages/form/data/UPDATE",
  UPDATE_FORM_DATA_SUCCESS: "app/pages/form/data/UPDATE/SUCCESS",
  UPDATE_FORM_DATA_FAILED: "app/pages/form/data/UPDATE/FAILED",

  UPDATE_FORM_ERROR: "app/pages/form/error/UPDATE",
  UPDATE_FORM_ERROR_SUCCESS: "app/pages/form/error/UPDATE/SUCCESS",
  UPDATE_FORM_ERROR_FAILED: "app/pages/form/error/UPDATE/FAILED",

  UPDATE_FORM_INITIAL_DATA: "app/pages/form/initial/DATA/UPDATE",

  LEAD_EDIT_ERROR: "app/pages/form/lead/EDIT/ERROR",
  LEAD_EDIT_ERROR_SUCCESS: "app/pages/form/lead/EDIT/ERROR/SUCCESS",
  LEAD_EDIT_ERROR_RESET: "app/pages/form/lead/EDIT/ERROR/RESET",

  TRIGGER_FORM_VALIDATION: "app/pages/form/trigger/VALIDATION",

  UPDATE_PROMOCODE_DATA: "app/pages/form/promocode/DATA/UPDATE",

  UPDATE_PAYMENT_DETAILS: "app/pages/form/payment/details/UPDATE",
  UPDATE_PREFERRED_COURSES_PRICING: "app/pages/form/preferred/payment/details/UPDATE",
  ADD_UNIQUE_FEILD_BY_INPUT_TYPE: "app/pages/form/add/uniquefieldbyInputType/ADD",
  COUNSELOR_FREEZE_CONDITION: "app/pages/form/conuselor/freeze/condition/SET",
  PIN_CODE_LIST: "app/pages/form/pinCodeList",
  TOGGLE_VERIFY_MODAL: "app/pages/form/verifyModal",
  UPDATE_FORM_COMPLETION_DETAILS: "app/pages/form/UPDATE/percentangeCompletionDetails",
  TOGGLE_EMAIL_MOBILE_VALIDATION_PROGRESS: "app/pages/form/validationProgress",
  TOGGLE_FIELD_VALIDATION_PROGRESS: "app/pages/form/fieldValidationProgress",
  SET_STEP_WISE_MANDATORY_FIELDS: "app/pages/form/set/stepwise/mandatory/fields",
  SET_FIELDS_CHANGE_BEFORE_FEESTYPE1: "app/pages/form/set/stepwise/fields/change/feestype1",
  SET_FIELDS_CHANGE_BEFORE_FEESTYPE2: "app/pages/form/set/stepwise/fields/change/feestype2"
};
/* Declear here all actions used in component */
export const getConfig = createActionCreator(
  actionTypes.GET_FORM_CONFIG,
  "data",
  "urlParam"
);
export const configRequested = createActionCreator(
  actionTypes.GET_FORM_CONFIG_REQUESTED,
  ""
);
export const getFormConfigSuccess = createActionCreator(
  actionTypes.GET_FORM_CONFIG_SUCCESS,
  "data",
  "formConfigURL"
);
export const getFormConfigFailed = createActionCreator(
  actionTypes.GET_FORM_CONFIG_FAILED,
  "errorMessage"
);
export const updateFormData = createActionCreator(
  actionTypes.UPDATE_FORM_DATA,
  "data"
);
export const updateFormDataSuccess = createActionCreator(
  actionTypes.UPDATE_FORM_DATA_SUCCESS,
  "data"
);
export const updateFormDataFailed = createActionCreator(
  actionTypes.UPDATE_FORM_DATA_FAILED,
  "errorMessage"
);
export const updateFormError = createActionCreator(
  actionTypes.UPDATE_FORM_ERROR,
  "data"
);
export const updateFormErrorSuccess = createActionCreator(
  actionTypes.UPDATE_FORM_ERROR_SUCCESS,
  "data"
);
export const updateFormErrorFailed = createActionCreator(
  actionTypes.UPDATE_FORM_ERROR_FAILED,
  "errorMessage"
);

export const updateFormConfig = createActionCreator(
  actionTypes.UPDATE_FORM_CONFIG,
  "data"
);
export const updateFormConfigSuccess = createActionCreator(
  actionTypes.UPDATE_FORM_CONFIG_SUCCESS,
  "data"
);
export const updateFormConfigFailed = createActionCreator(
  actionTypes.UPDATE_FORM_CONFIG_FAILED,
  "errorMessage"
);

export const updateInitialFormData = createActionCreator(
  actionTypes.UPDATE_FORM_INITIAL_DATA,
  "data"
);
export const updateLeadEditError = createActionCreator(
  actionTypes.LEAD_EDIT_ERROR_SUCCESS,
  "leadEditError"
);

export const resetLeadEditError = createActionCreator(
  actionTypes.LEAD_EDIT_ERROR_RESET
)

/** Trigger validation */
export const triggerFormValidation = createActionCreator(
  actionTypes.TRIGGER_FORM_VALIDATION
);

export const updatePromocodeData = createActionCreator(
  actionTypes.UPDATE_PROMOCODE_DATA,
  "data"
);
export const updatePaymentDetails = createActionCreator(
  actionTypes.UPDATE_PAYMENT_DETAILS,
  "data"
);
export const addUniqueFieldByInputType = createActionCreator(
  actionTypes.ADD_UNIQUE_FEILD_BY_INPUT_TYPE,
  "data"
);
export const setCounselorFreezeCondition = createActionCreator(
  actionTypes.COUNSELOR_FREEZE_CONDITION,
  "data"
);
export const setPinCodeList = createActionCreator(
  actionTypes.PIN_CODE_LIST,
  "data"
);
export const setShowVerifyModal = createActionCreator(
  actionTypes.TOGGLE_VERIFY_MODAL,
  "data"
);
export const updateFormCompletionDetails = createActionCreator(
  actionTypes.UPDATE_FORM_COMPLETION_DETAILS,
  "requiredFieldsBySteps",
  "feesType1PaymentStepWeightagePercent",
  "feesType2PaymentStepWeightagePercent"
)
export const toggleEmailMobileValidationInProgress = createActionCreator(
  actionTypes.TOGGLE_EMAIL_MOBILE_VALIDATION_PROGRESS,
  "type",
  "flag"
);

export const toggleFieldValidationInApiProgress = createActionCreator(
  actionTypes.TOGGLE_FIELD_VALIDATION_PROGRESS,
  "type",
  "flag"
)

export const setStepWiseMandatoryFields = createActionCreator(
  actionTypes.SET_STEP_WISE_MANDATORY_FIELDS,
  "data"
);

export const setFieldsChangeBeforeFeestype1 = createActionCreator(
  actionTypes.SET_FIELDS_CHANGE_BEFORE_FEESTYPE1,
  "data"
);

export const setFieldsChangeBeforeFeestype2 = createActionCreator(
  actionTypes.SET_FIELDS_CHANGE_BEFORE_FEESTYPE2,
  "data"
);

export const updatePreferredCoursesPricing = createActionCreator(
  actionTypes.UPDATE_PREFERRED_COURSES_PRICING,
  "data"
);