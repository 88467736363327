import { get } from "lodash";
import { QUERYAPI } from "../../infrastructure/network/HttpClient";

export const Services = {
  callSubmitQueryAPI: (apiParams) => {
    return QUERYAPI.post(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.submitQuery`)
        ? window.extraaedge.APPFORM_V2_API_END_POINT.submitQuery
        : `/submitQuery`,
      apiParams
    );
  },
  getAllQueries: (userId, clientAlias) => {
    return QUERYAPI.get(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.getAllQueries`)
        ? `${window.extraaedge.APPFORM_V2_API_END_POINT.getAllQueries}?userId=${userId}&clientAlias=${clientAlias}`
        : `/tickets/lead?userId=${userId}&clientAlias=${clientAlias}`
    );
  },
  getQueryCount: (userId, clientAlias) => {
    return QUERYAPI.get(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.getQueryCount`)
        ? `${window.extraaedge.APPFORM_V2_API_END_POINT.getQueryCount}?userId=${userId}&clientAlias=${clientAlias}`
        : `/tickets/counselor/count?userId=${userId}&clientAlias=${clientAlias}`
    );
  },
  getTotalUnreadMessageCount: (apiParams) => {
    return QUERYAPI.post(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.getTotalUnreadMessageCount`)
        ? `${window.extraaedge.APPFORM_V2_API_END_POINT.getTotalUnreadMessageCount}`
        : `/conversation/unreadcount`,
      apiParams
    );
  },
  getAllConversation: (ticketId, clientAlias) => {
    return QUERYAPI.get(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.getAllConversation`)
        ? `${window.extraaedge.APPFORM_V2_API_END_POINT.getAllConversation}?ticketId=${ticketId}&clientAlias=${clientAlias}`
        : `/conversation?ticketId=${ticketId}&clientAlias=${clientAlias}`
    );
  },
  createConveration: (apiParams) => {
    return QUERYAPI.post(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.createConveration`)
        ? window.extraaedge.APPFORM_V2_API_END_POINT.createConveration
        : `/conversation/create`,
      apiParams
    );
  },
  conversationMarkAsRead: (apiParams) => {
    return QUERYAPI.post(
      get(window, `extraaedge.APPFORM_V2_API_END_POINT.conversationMarkAsRead`)
        ? `${window.extraaedge.APPFORM_V2_API_END_POINT.conversationMarkAsRead}`
        : `/conversation/markasread`,
      apiParams
    );
  },
};
