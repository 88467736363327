import { actionTypes } from "./Actions";
import { actionTypes as appActionTypes } from "../../shared/services/appData/Actions";

const defaultState = {
  isInProgress: false,
  widgetFormConfig: null,
  dropdownOptions: null,
  webHookAddLeadInProgress: false,
  nestedMappedObject: null,
  newAddedLeadData: false,
};

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case appActionTypes.LOGOUT: {
      return {
        ...state,
        defaultState
      };
    }
    case actionTypes.WIDGET_FORM_CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case actionTypes.WIDGET_FORM_CONFIG_SUCCESS: {
      return {
        ...state,
        widgetFormConfig: action.payload.data,
      };
    }
    case actionTypes.WIDGET_FORM_CONFIG_FAILED: {
      return {
        ...state,
        isInProgress: false,
      };
    }
    case actionTypes.SET_DROPDOWN_OPTIONS: {
      return {
        ...state,
        dropdownOptions: action.payload.data,
      };
    }
    case actionTypes.SET_NESTED_MAPPED_OBJECT: {
      return {
        ...state,
        nestedMappedObject: action.payload.data,
      };
    }
    case actionTypes.WEBHOOK_ADD_LEAD_REQUESTED: {
      return {
        ...state,
        webHookAddLeadInProgress: true,
      };
    }
    case actionTypes.WEBHOOK_ADD_LEAD_SUCCESS: {
      return {
        ...state,
        webHookAddLeadInProgress: false,
        newAddedLeadData: action.payload.data,
      };
    }
    case actionTypes.WEBHOOK_ADD_LEAD_FAILED: {
      return {
        ...state,
        webHookAddLeadInProgress: false,
      };
    }
    default: {
      return state;
    }
  }
}
