export default class eeAzureLogs {
    eeTrackEvent(clientAlias,leadId,body,notify=false){
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', 'x-functions-key':'Ae_xNoYTMgLEdxxHtV3NNKlYN6348mlFSrt5orbtSr3KAzFu_MjmYg=='},
            body: JSON.stringify({ PartitionKey: `${clientAlias}_${leadId}`,rowKey:crypto.randomUUID(),body:JSON.stringify(body),notify })
        };
        fetch('https://eestorelogapi.azurewebsites.net/api/Function1', requestOptions)
            .then(response => {
                //const isJson = response.headers.get('content-type')?.includes('application/json');
                //const data = isJson ? await response.json() : null;
                console.log(`AzureLogs success`);
            })
            .catch(error => {                
                console.error('AzureLogs failed: There was an error!', error);
            });
    }
}
