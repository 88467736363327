import { API } from "../../infrastructure/network/HttpClient";

export const Services = {
  callWidgetFormConfigAPI: function (url) {
    return fetch(url)
      .then(function (response) {
        return response.json();
      })
      .then(function (myJson) {
        return myJson;
      });
  },
  addLead: function (params, fd) {
    return API.post(params, fd);
  },
  getUserDetails: function (leadId) {
    return API.get(
      window &&
        window.extraaedge &&
        window.extraaedge.API_END_POINT &&
        !!window.extraaedge.API_END_POINT.getuserdetails
        ? `${window.extraaedge.API_END_POINT.getuserdetails}${leadId}`
        : `leads/leadApplicationDetails/${leadId}`
    );
  },
};
