import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in hello world component */
export const actionTypes = {
  RESET_STATE: "app/pages/resetpassowrd/RESET/STATE",
  GET_RESET_PASSWORD_CONFIG: "app/pages/resetpassowrd/config/GET",
  RESET_PASSWORD_CONFIG_REQUESTED: "app/pages/resetpassowrd/config/REQUESTED",
  RESET_PASSWORD_CONFIG_SUCCESS: "app/pages/resetpassowrd/config/SUCCESS",
  RESET_PASSWORD_CONFIG_FAILED: "app/pages/resetpassowrd/config/FAILED",

  RESET_PASSWORD_INTI: "app/pages/reset_password/INIT",
  RESET_PASSWORD_REQUESTED: "app/pages/reset_password/REQUESTED",
  RESET_PASSWORD_SUCCESS: "app/pages/reset_password/SUCCESS",
  RESET_PASSWORD_FAILED: "app/pages/reset_password/FAILED",
  UPDATE_SERVER_ERROR: "app/pages/reset_password/form/ERROR"
};
/* Declear here all actions used in component */

export const reset = createActionCreator(actionTypes.RESET_STATE);
/*****************************/
/* Get login form config - actions */
/*****************************/
export const getConfig = createActionCreator(
  actionTypes.GET_RESET_PASSWORD_CONFIG,
  "data",
  "urlParam"
);
export const configRequested = createActionCreator(
  actionTypes.RESET_PASSWORD_CONFIG_REQUESTED,
  ""
);
export const configSuccess = createActionCreator(
  actionTypes.RESET_PASSWORD_CONFIG_SUCCESS,
  "data"
);
export const configFailed = createActionCreator(
  actionTypes.RESET_PASSWORD_CONFIG_FAILED,
  "errorMessage"
);

/**
 * @description reset password actions
 */

export const resetPassword = createActionCreator(
  actionTypes.RESET_PASSWORD_INTI,
  "data"
);
export const resetPasswordRequested = createActionCreator(
  actionTypes.RESET_PASSWORD_REQUESTED,
  ""
);
export const resetPasswordSuccess = createActionCreator(
  actionTypes.RESET_PASSWORD_SUCCESS,
  "data"
);
export const resetPasswordFail = createActionCreator(
  actionTypes.RESET_PASSWORD_FAILED,
  "message"
);
export const updateServerError = createActionCreator(
  actionTypes.UPDATE_SERVER_ERROR,
  "error"
);
