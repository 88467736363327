import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in hello world component */
export const actionTypes = {
  RESET_STATE: "app/pages/forgetpassowrd/RESET/STATE",
  GET_FORGET_PASSWORD_CONFIG: "app/pages/forgetpassowrd/config/GET",
  FORGET_PASSWORD_CONFIG_REQUESTED: "app/pages/forgetpassowrd/config/REQUESTED",
  FORGET_PASSWORD_CONFIG_SUCCESS: "app/pages/forgetpassowrd/config/SUCCESS",
  FORGET_PASSWORD_CONFIG_FAILED: "app/pages/forgetpassowrd/config/FAILED",

  FORGET_PASSWORD_INTI: "app/pages/forget_password/INIT",
  FORGET_PASSWORD_REQUESTED: "app/pages/forget_password/REQUESTED",
  FORGET_PASSWORD_SUCCESS: "app/pages/forget_password/SUCCESS",
  FORGET_PASSWORD_FAILED: "app/pages/forget_password/FAILED",
  UPDATE_SERVER_ERROR: "app/pages/forget_password/form/ERROR"
};
/* Declear here all actions used in component */

export const reset = createActionCreator(actionTypes.RESET_STATE);
/*****************************/
/* Get login form config - actions */
/*****************************/
export const getConfig = createActionCreator(
  actionTypes.GET_FORGET_PASSWORD_CONFIG,
  "data",
  "urlParam"
);
export const configRequested = createActionCreator(
  actionTypes.FORGET_PASSWORD_CONFIG_REQUESTED,
  ""
);
export const configSuccess = createActionCreator(
  actionTypes.FORGET_PASSWORD_CONFIG_SUCCESS,
  "data"
);
export const configFailed = createActionCreator(
  actionTypes.FORGET_PASSWORD_CONFIG_FAILED,
  "errorMessage"
);

/**
 * @description forget password actions
 */

export const forgetPassword = createActionCreator(
  actionTypes.FORGET_PASSWORD_INTI,
  "loginInfo"
);
export const forgetPasswordRequested = createActionCreator(
  actionTypes.FORGET_PASSWORD_REQUESTED,
  ""
);
export const forgetPasswordSuccess = createActionCreator(
  actionTypes.FORGET_PASSWORD_SUCCESS,
  "data"
);
export const forgetPasswordFail = createActionCreator(
  actionTypes.FORGET_PASSWORD_FAILED,
  "message"
);
export const updateServerError = createActionCreator(
  actionTypes.UPDATE_SERVER_ERROR,
  "error"
);
