/*
 * <copyright company="Extraaedge Pvt Ltd">
 *        copyright (c) Extraaedge Pvt Ltd.  All rights reserved.
 * </copyright>
 */
import { combineReducers } from "redux";
import { reducer as dashboardReducer } from "../../pages/dashboard/Reducers";
import { reducer as formReducer } from "../../pages/form/Reducers";
import { reducer as loginReducer } from "../../pages/login/Reducers";
import { reducer as continueReducer } from "../../pages/continue/Reducers";
import { reducer as appDataReducer } from "../services/appData/Reducers";
import { reducer as forgetPasswordReducer } from "../../pages/forgetpassword/Reducers";
import { reducer as resetPasswordReducer } from "../../pages/resetPassword/Reducers";
import { reducer as queryReducer} from "../../pages/query/Reducer";
import { reducer as allApplicationsReducer } from "../../pages/allApplications/Reducers";

export const rootReducer = combineReducers({
  dashboardReducer,
  formReducer,
  loginReducer,
  appDataReducer,
  forgetPasswordReducer,
  resetPasswordReducer,
  continueReducer,
  queryReducer,
  allApplicationsReducer
});
