import PropTypes from "prop-types";

export const defaultPropsTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  visible: PropTypes.bool,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  error: PropTypes.string,
  cols:PropTypes.number,
  cssClass:PropTypes.string
};
export const CustomSelectProps = {
  ...defaultPropsTypes,
  dataCollection: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  ariaLabel:PropTypes.string,
  apiKey: PropTypes.string,
  mappingConfig: PropTypes.object,
  multiple: PropTypes.bool,
  handleChange: PropTypes.func,
  collection: PropTypes.array,
};
export const CustomTextProps = {
  ...defaultPropsTypes,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  ariaLabel:PropTypes.string,
  apiKey: PropTypes.string,
  maxLength: PropTypes.number,
  handleChange: PropTypes.func,
  typePattern: PropTypes.string,
};
export const CustomPhone2Props = {
  ...defaultPropsTypes,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  ariaLabel:PropTypes.string,
  apiKey: PropTypes.string,
  maxLength: PropTypes.number,
  handleChange: PropTypes.func,
  typePattern: PropTypes.string,
  defaultProps: PropTypes.object
};
export const CustomCheckBoxProps = {
  ...defaultPropsTypes,
  label: PropTypes.string,
  handleChange: PropTypes.func
};
