import React, { useEffect, useState } from 'react'
import { checkIfArray, checkIfObject, getLang, leadObjectManager, replaceToken, isRegExValidate, addURLParametersAndRoute, replaceEmailWithStars, replaceMobileWithStars, getEntityIdBasedOnUKC, getWidgetOtpVerificationHtml, getErrorMessageForExistAPI } from "../../utilties/Utils";
import './verificationModal.scss';
import { Button, Input, Label, FormGroup, Modal, Carousel, CarouselItem, FormFeedback, } from "reactstrap";
import { BUTTON_EVENT_TYPE, EDIT_MOBILE_MODAL_CONFIG, EDIT_EMAIL_MODAL_CONFIG, VERIFY_MOBILE_MODAL_CONFIG, VERIFY_EMAIL_MODAL_CONFIG, VERIFICATION_AND_EDIT_ACTION_ITEMS, OTP_VERIFICATION_API_RESPONSE, EMAIL_AND_MOBILE_VERIFICATION_ERRORS, MOBILE_REGEX_COUNTRY_WISE, PAGES, OTP_VERFICATION_SMS_TEMPLATE } from "../../utilties/Constants";
import { setShowVerifyModal, updateFormData } from "../../../pages/form/Actions";
import { connect } from 'react-redux';
import VerifiedHeaderIcon from "../../icons/verified_email.svg";
import NotVerifiedHeaderIcon from "../../icons/notVerified_email.svg";
import CloseButtonIcon from "../../icons/modalClose.svg";
import { Service } from '../../../pages/form/Services';
import OTPInput from '../../../pages/form/formInput/inputOtp';
import { Services } from '../../services/appData/Services';
import 'react-phone-input-2/lib/style.css';
import PhoneInput from 'react-phone-input-2';
import { userDetailSuccess, updateVerificationData } from '../../services/appData/Actions'
import { get, isEmpty } from 'lodash';


const VerificationModalPopup = (props) => {
    const { actionItem, mobileInput = "", widgetFormConfig = {},verificationDataArray = [], setVerificationDataArray, domainConfig, modalColor = "#0065E2", updateVerificationData, userDetailSuccess,
        clientInfo, userDetail, showVerifyModal, setShowVerifyModal, fixedModal=false, isCounselorAccessActive, setIsAlreadyExistInProgress, ukcValue } = props;
    const [btnHoverStyle, setBtnHoverStyle] = useState("");
    const hoverStyle = {
        backgroundColor: btnHoverStyle,
        borderColor: btnHoverStyle
    }
    const [timer, setTimer] = useState()
    const [timerValue, setTimerValue] = useState(0)
    const [headerIcon, setHeaderIcon] = useState(NotVerifiedHeaderIcon);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [showModalLoader, setShowModalLoader] = useState(false);
    const [modalStep, setModalStep] = useState(0);
    const [modalConfig, setModalConfig] = useState(null);
    const [inputValue, setInputValue] = useState({});
    const [countryCode, setCountryCode] = useState('IN');
    const [errorMsg, setErrorMsg] = useState({});
    const { Info, emailMobileVerification, enableMasking } = clientInfo ? clientInfo : {};
    const { source, authToken } = Info ? Info : {};
    const { user, lead } = userDetail ? userDetail : {};
    const { DltteId, otpExpiryInMin } = emailMobileVerification ? emailMobileVerification : {};
    let { email, mobileNumber } = user ? user : {};
    const leadId = lead && lead.id;
    const { VERIFY_EMAIL, VERIFY_MOBILE, EDIT_EMAIL, EDIT_MOBILE, WIDGET_VERIFY_MOBILE, SEND_OTP} = VERIFICATION_AND_EDIT_ACTION_ITEMS;
    if(actionItem === WIDGET_VERIFY_MOBILE){
        mobileNumber = mobileInput || mobileNumber;
    }
    const {global : { otpSMSTemplate = "" } = {}} = domainConfig || {};
    const { otp : widgetOtpConfig = {}} = widgetFormConfig || {};
    useEffect(()=> {
        return () => {
            clearInterval(timer)
        }
    }, [])

    useEffect(() => {
        if(actionItem === WIDGET_VERIFY_MOBILE){
            handleButtonClick(SEND_OTP);
        }
    },[actionItem])
    useEffect(() => {
        switch (actionItem) {
            case VERIFY_EMAIL:
                setModalConfig(VERIFY_EMAIL_MODAL_CONFIG)
                break;
            case VERIFY_MOBILE:
                setModalConfig(VERIFY_MOBILE_MODAL_CONFIG)
                break;
            case EDIT_EMAIL:
                setModalConfig(EDIT_EMAIL_MODAL_CONFIG)
                break;
            case EDIT_MOBILE:
                setModalConfig(EDIT_MOBILE_MODAL_CONFIG)
                break;
            case WIDGET_VERIFY_MOBILE:
                setModalConfig(getWidgetOtpVerificationHtml(widgetOtpConfig))
                break;
            default:
                break;
        }
    }, [actionItem])

    useEffect(() => {
        setIsAlreadyExistInProgress && setIsAlreadyExistInProgress(showModalLoader);
        let width = document.getElementById('modal-loader-container').clientWidth - document.getElementById('modal-loader').clientWidth + 300;
        function animateMydiv() {
            document.getElementById('modal-loader').animate([{ marginLeft: width + 'px' }], {
                duration: 2000,
                iterations: Infinity
            })
        };
        if (showModalLoader) {
            document.getElementById('modal-loader-container').style.visibility = "visible";
            animateMydiv();
        }
        else {
            const modalLoaderAnim= document.getElementById('modal-loader').getAnimations()
            document.getElementById('modal-loader-container').style.visibility = "hidden";
            checkIfArray(modalLoaderAnim) && modalLoaderAnim.map((item)=> {
                item.cancel()
            })
        }
    }, [showModalLoader])

    useEffect(() => {
        if (checkIfObject(modalConfig)) {
            const { title, description , body, footer } = modalConfig[`step_${modalStep + 1}`] ? modalConfig[`step_${modalStep + 1}`] : {};
            if (!title && !description) {
                toggleModal()
            }
            if(!footer && !body) {
                setHeaderIcon(VerifiedHeaderIcon)
            }
        }
    }, [modalStep])

    const onButtonClick = (e, param) => {
        const { history } = props;
        e.preventDefault();
        e.stopPropagation();
        switch (param && param.triggerEvent ? param.triggerEvent : "") {
          case BUTTON_EVENT_TYPE.TRIGGER_DASHBAORD:     
            history && addURLParametersAndRoute({ pathname: PAGES.DASHBOARD },history);
            break;
          default: {
          }
        }
      }

    const startTimer = () => {
        let timerCount = otpExpiryInMin*60;
        const timerTick = setInterval(()=> {
            setTimerValue(timerCount)
            timerCount--
            if(timerCount < 0) clearInterval(timerTick)
        }, 1000)
        setTimer(timerTick)
    }

    const resetTimer = () => {
        setTimerValue(0)
        clearInterval(timer)
    }

    const handleSendOtp = (sendType = "send") => {
        setShowModalLoader(true);
        if (actionItem === VERIFY_MOBILE || actionItem === EDIT_EMAIL || actionItem === WIDGET_VERIFY_MOBILE) {
            let paramRequest;
            let newDltteId = DltteId;
            const {
              DltteId: widgetDlteId,
              otpSMSBody,
              otpExpiryInMin: widgetOtpExpiryInMin,
            } = widgetOtpConfig || {};
            if(actionItem === WIDGET_VERIFY_MOBILE){
                  newDltteId = widgetDlteId || DltteId;
                  paramRequest = {
                    AuthToken: authToken,
                    Source: source,
                    MobileNumber: mobileNumber,
                    OtpMessage: otpSMSBody,
                    OtpExpiryInMin: (widgetOtpExpiryInMin && widgetOtpExpiryInMin.toString()) || (otpExpiryInMin && otpExpiryInMin.toString()),
                    DltteId: newDltteId,
                    LeadId: leadId,
                    AppFormFlag: "true"
                };
            }
            else {
                  paramRequest = {
                    AuthToken: authToken,
                    Source: source,
                    MobileNumber: mobileNumber,
                    OtpMessage:
                      otpSMSTemplate ||
                      otpSMSBody ||
                      OTP_VERFICATION_SMS_TEMPLATE,
                    OtpExpiryInMin: otpExpiryInMin && otpExpiryInMin.toString(),
                    DltteId: newDltteId,
                    LeadId: leadId,
                    AppFormFlag: "true",
                  };
            }
            if(!newDltteId){
                console.log("DltteId not Found");
              }
            let apiEndpoint = "/webhook/sendotp";
            if (authToken && source && newDltteId && leadId && mobileNumber) {
                try{
                Service.sendOtp(apiEndpoint, paramRequest)
                    .then((res) => {
                        let { data, status } = res ? res : {};
                        if (status === 200 || status === 201) {
                            const { type, message } = data ? data : {};
                            setShowModalLoader(false);
                            if (type === OTP_VERIFICATION_API_RESPONSE.SUCCESS) {
                                if (sendType === "send" && actionItem !== WIDGET_VERIFY_MOBILE) setModalStep(modalStep + 1);
                                startTimer();
                            } else {
                                setErrorMsg({"": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.SEND_ERROR })
                            }
                        } else {
                            setErrorMsg({"": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.SEND_ERROR })
                        }
                        setShowModalLoader(false);
                    })
                    .catch(error => {
                        setShowModalLoader(false);
                        console.log("error", error)
                    })
                }catch(e){
                    console.log(`sendotp ERROR - ${e}`);
                }
            }
        } else if (actionItem === EDIT_MOBILE || actionItem === VERIFY_EMAIL || actionItem === WIDGET_VERIFY_MOBILE) {
            let paramRequest = {
                AuthToken: authToken,
                Source: source,
                EmailIdToSendOtp: email,
                LeadId: leadId,
            };
            let apiEndpoint = "/webhook/SendOtpViaEmail";
            if (authToken && source && leadId && email) {
                try{
                    Service.sendOtp(apiEndpoint, paramRequest)
                    .then((res) => {
                        let { data, status } = res ? res : {};
                        if (status === 200 || status === 201) {
                            const { message, type } = data ? data : {}
                            if (message === OTP_VERIFICATION_API_RESPONSE.SUCCESS) {
                                if (sendType === "send") setModalStep(modalStep + 1);
                                setShowModalLoader(false);
                                startTimer()
                            } else {
                                setShowModalLoader(false);
                                setErrorMsg({"": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.SEND_ERROR })
                            }
                        } else {
                            setErrorMsg({"": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.SEND_ERROR })
                        }
                    })
                    .catch(error => {
                        console.log("error in SendOtpViaEmail", error)
                    })
                }catch(e){
                    console.log(`e - ${e}`);
                }
                
            }
        }
    }
    const handleVerifyOtp = () => {
        if(true) {
            setShowModalLoader(true);
            let errorMessage = "";
            if (actionItem === VERIFY_MOBILE || actionItem === EDIT_EMAIL || actionItem === WIDGET_VERIFY_MOBILE) {
                let paramRequest = {
                    AuthToken: authToken,
                    Source: source,
                    MobileNumber: mobileNumber,
                    Otp: inputValue.otp,
                    LeadId: leadId,
                    AppFormFlag: "true",
                    DoNotVerify: "false"
                };
                let apiEndpoint = "/webhook/otpVerify";
                if (authToken && source && leadId && mobileNumber && inputValue) {
                    try{
                    Service.verifyOtp(apiEndpoint, paramRequest)
                        .then((res) => {
                            setShowModalLoader(false);
                            let { data, status } = res ? res : {};
                            if (status === 200 || status === 201) {
                                const { message, type } = data ? data : {};
                                if (type === OTP_VERIFICATION_API_RESPONSE.SUCCESS) {
                                    setModalStep(modalStep + 1);
                                    updateVerificationData({ actionItem: VERIFY_MOBILE, changeDataTo: true, mobileNumber: mobileNumber })
                                    resetTimer();
                                    Array.isArray(verificationDataArray) && setVerificationDataArray && setVerificationDataArray(
                                        [ ...verificationDataArray,
                                         mobileNumber]
                                        );
                                } else {
                                    setErrorMsg({"otp": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.MISMATCH })
                                }
                            } else {
                                setErrorMsg({"otp": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.MISMATCH })
                            }
                        })
                        .catch(error => {
                            setShowModalLoader(false);
                            console.log("error in otpVerify API", error)
                        })
                    }catch(e){
                        console.log(`otpVerify ERROR - ${e}`);
                    }
                }
            } else if (actionItem === EDIT_MOBILE || actionItem === VERIFY_EMAIL || actionItem === WIDGET_VERIFY_MOBILE) {
                let paramRequest = {
                    AuthToken: authToken,
                    Source: source,
                    EmailIdToSendOtp: email,
                    LeadId: leadId,
                    EmailOtp: inputValue.otp,
                    DoNotVerify: "false"
                };
                let apiEndpoint = "/webhook/VerifyEmailOtp";
                if (authToken && source && leadId && email && inputValue) {
                    try{
                        Service.verifyOtp(apiEndpoint, paramRequest)
                        .then((res) => {
                            setShowModalLoader(false);
                            let { data, status } = res ? res : {};
                            if (status === 200 || status === 201) {
                                const { message, type } = data ? data : {};
                                if (message === OTP_VERIFICATION_API_RESPONSE.SUCCESS) {
                                    setModalStep(modalStep + 1);
                                    updateVerificationData({ actionItem: VERIFY_EMAIL, changeDataTo: true })
                                    resetTimer();
                                } else {
                                    setErrorMsg({"otp": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.MISMATCH })
                                }
                            } else {
                                setErrorMsg({"otp": EMAIL_AND_MOBILE_VERIFICATION_ERRORS.OTP.MISMATCH })
                            }
                        })
                        .catch(error => {
                            setShowModalLoader(false);
                            console.log("error in VerifyEmailOtp", error)
                        })
                    }catch(e){
                        console.log(`e - ${e}`);
                    }
                    
                }
            }
            setInputValue({});
        }
    }

    const renderOTPInput = (key) => {
        return (
            <div key={key}>
            <OTPInput
                key={key}
                autoFocus
                // isNumberInput
                length={4}
                className="otp-input-frame"
                inputClassName="otp-input"
                onChangeOTP={(otp) => {
                    setInputValue({"otp": otp})
                    otp.length>3 && setBtnDisabled(false)
                    setErrorMsg({})
                }}
            />
            {errorMsg && errorMsg.otp && (
                    <div className="input-validation-error-messsage otp-error" style={{color: 'red', textAlign: 'center'}}>
                    {errorMsg.otp}
                    </div>
                )}
            </div>
        )
    }
    const handleInputField = event => {
        setInputValue({[event.target.id]:event.target.value});
        btnDisabled && setBtnDisabled(false) 
        setErrorMsg({})
    }

    const handleNumberField = (value, data) => {
        setInputValue({"newMobileNumber":value})
        btnDisabled && setBtnDisabled(false)
        setErrorMsg({})
        let { countryCode } = data ? data : {};
        countryCode = countryCode && countryCode.toUpperCase();
        countryCode && setCountryCode(countryCode);
    }

    const renderInputText = (body) => {
        let { id, label, value = "", disabled = true, type } = body ? body : {};
        value = replaceToken(value);
        return (
            <FormGroup key={id}>
                <div className="from-group-bg">
                    <Label for={id}>
                        {label}
                    </Label>
                    <Input
                        disabled={disabled}
                        type={type}
                        id={id}
                        value={value ? value : inputValue[id] ? inputValue[id] : "" }
                        onChange={handleInputField}
                    />
                </div>
                {errorMsg && (
                    <FormFeedback className="input-validation-error-messsage">
                    {errorMsg[id]}
                    </FormFeedback>
                )}
            </FormGroup>
        )
    }
    const renderMobileInput = (body) => {
        let { id, label, value = "", disabled = true, type } = body ? body : {};
        value = replaceToken(value);
        return (
            <FormGroup key={id}>
                <div className="from-group-bg">
                    <Label style={{ fontWeight: "bold" }} for={id}>
                        {label}
                    </Label>
                    <PhoneInput
                        countryCodeEditable={false}
                        enableSearch={false}
                        country={'in'}
                        id={id}
                        value={value}
                        placeholder={"Enter mobile number"}
                        specialLabel={''}
                        onChange={handleNumberField}
                    />
                </div>
                {errorMsg && (
                    <FormFeedback className="input-validation-error-messsage">
                    {errorMsg[id]}
                    </FormFeedback>
                )}
            </FormGroup>
        )
    }
    const renderBody = (modalBody) => {
        return checkIfArray(modalBody) && modalBody.map((body, key) => {
            const { id, label, value = "", disabled = true, type } = body ? body : {};
            if (type === "otpBox") {
                return renderOTPInput(key);
            } else if (type === "mobile2") {
                return renderMobileInput(body);
            } else {
                return renderInputText(body);
            }
        })
    }

    const toggleModal = () => {
        setShowVerifyModal(!showVerifyModal);
    };
    const handleChange = () => {
        let targetFieldValue = '';
        const targetField = checkIfObject(modalConfig) ? modalConfig[`step_${modalStep + 1}`].body.find((item) => {
                targetFieldValue = inputValue[item.id] ? inputValue[item.id] : ''
                return inputValue[item.id]
        }) : {}
        if (isEmpty(targetField)) {
            setBtnDisabled(true)
            setErrorMsg({"newMobileNumber": replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.EMPTY,"New Mobile Number"), "newEmailId" : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.EMPTY,"New Email Id")})
            return false
        }
        if (targetFieldValue === mobileNumber || targetFieldValue === email) {
            setErrorMsg({[targetField.id] : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.NO_CHANGE,targetField.label) })
            return false
        }else {
            let isValidInput = targetField && targetFieldValue && isRegExValidate({pattern: targetField.typePattern, val: targetFieldValue})
            if(isValidInput) {
                setBtnDisabled(false)
                if(targetField.id === "newEmailId") {
                    const entityId = ukcValue || getEntityIdBasedOnUKC() || 0;
                    let apiEndpoint = `/leads/getLeadExistsByEmail?email=${targetFieldValue}&entityId=${entityId}&isExactMatch=true`;
                    try{
                    Service.verifyIfEmailMobileExists(apiEndpoint)
                        .then((res) => {
                            let { data, status } = res ? res : {};
                            if (data) {
                                setShowModalLoader(false)
                                setBtnDisabled(true)
                                setErrorMsg({[targetField.id] : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.ALREADY_EXISTS,targetField.label)})
                            }else{
                                setShowModalLoader(true);
                                invokeEditAppformApi();
                            }
                        })
                        .catch(error => {
                            setShowModalLoader(false)
                            setErrorMsg({[targetField.id] : replaceToken(getErrorMessageForExistAPI(error))})
                        })
                    }catch(e){
                        console.log(`getLeadExistsByEmail ERROR - ${e}`);
                    }
                
                }
                if(targetField.id === "newMobileNumber") {
                    const selectedCountryRegex = MOBILE_REGEX_COUNTRY_WISE[countryCode];
                    targetFieldValue = inputValue.newMobileNumber
                    if(targetFieldValue && selectedCountryRegex && selectedCountryRegex.test(targetFieldValue)) {
                        const entityId =  ukcValue || getEntityIdBasedOnUKC() || 0;
                        let apiEndpoint = `/leads/getLeadExistsByMobilenumber?mobileNumber=${targetFieldValue}&entityId=${entityId}&isExactMatch=true`;
                        try{
                        Service.verifyIfEmailMobileExists(apiEndpoint)
                            .then((res) => {
                                let { data, status } = res ? res : {};
                                if (data) {
                                    setShowModalLoader(false)
                                    setBtnDisabled(true)
                                    setErrorMsg({[targetField.id] : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.ALREADY_EXISTS,targetField.label)})
                                }else{
                                    setShowModalLoader(true)
                                    invokeEditAppformApi()
                                }
                            })
                            .catch(error => {
                                setShowModalLoader(false)
                                setErrorMsg({[targetField.id] : replaceToken(getErrorMessageForExistAPI(error))})
                            })
                        }catch(e){
                            console.log(`getLeadExistsByMobilenumber ERROR - ${e}`);
                        }
                    }
                    else {
                        setBtnDisabled(true)
                        setErrorMsg({[targetField.id] : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.INVALID,targetField.label)})
                    }
                }
            }else {
                setBtnDisabled(true)
                setErrorMsg({[targetField.id] : replaceToken(EMAIL_AND_MOBILE_VERIFICATION_ERRORS.INVALID,targetField.label) })
            }
        }
    }

    const invokeEditAppformApi = () => {
        let dataChange = "";
        if (actionItem === EDIT_EMAIL) {
            dataChange = "email"
        } else if (actionItem === EDIT_MOBILE) {
            dataChange = "mobileNumber";
        }
        let localUserDetail = userDetail;
        if (dataChange) {
            localUserDetail = {
                ...localUserDetail,
                user: {
                    ...localUserDetail.user,
                    [dataChange]: inputValue.newEmailId || inputValue.newMobileNumber
                }
            }
            inputValue && Services.updateUserDetail(localUserDetail)
            .then((res) => {
                if(res.status === 201 || res.status === 200) {
                    dataChange === "email" && updateVerificationData({ actionItem: VERIFY_EMAIL, changeDataTo: false })
                    dataChange === "mobileNumber" && updateVerificationData({ actionItem: VERIFY_MOBILE, changeDataTo: false })
                    setShowModalLoader(false);
                    userDetailSuccess(res.data);
                    leadObjectManager.setUserInfo(res.data);
                    setModalStep(modalStep + 1);

                }
            })
            .catch(error => {
                setShowModalLoader(false)
            })
        }
    }
    const handleCancel = () => {
        toggleModal();
    }
    const handleButtonClick = (param) => {
        switch (param) {
            case "sendOtp":
                return handleSendOtp();
            case "resend":
                return handleSendOtp("resend");
            case "verifyOtp":
                return handleVerifyOtp()
            case "change":
                return handleChange()
            case "cancel":
            case "skip":
                return handleCancel();
            default:
                return;
        }
    }
    const replaceToken = (str, val) => {
        let returnVal = str;
        const { email: isEailMaskingEnabled, mobile:  isMobileMaskingEnabled} = enableMasking || {};
        if (returnVal) {
            if (returnVal.includes("$MOBILE_NUMBER$")) {
                let updatedMobileNumber = mobileNumber;
                if (isMobileMaskingEnabled && isCounselorAccessActive) {
                    updatedMobileNumber = replaceMobileWithStars(updatedMobileNumber);
                }  
                returnVal = returnVal.replaceAll("$MOBILE_NUMBER$", updatedMobileNumber) 
            }
            if (returnVal.includes("$EMAIL$")) {
                let updatedEmail = email;
                if (isEailMaskingEnabled && isCounselorAccessActive) {
                    updatedEmail = replaceEmailWithStars(updatedEmail);
                }
                returnVal = returnVal.replaceAll("$EMAIL$", updatedEmail)
            }
            if (returnVal.includes("$INPUT_LABEL$")) {
                returnVal = returnVal.replaceAll("$INPUT_LABEL$", val)
            }
            if (returnVal.includes("$BUTTON$")) {
                returnVal = returnVal.replaceAll("$BUTTON$", val)
            }
        }
        return returnVal;
    }
    const renderSlides = checkIfObject(modalConfig) ? Object.keys(modalConfig).map((item, index) => {
        let { title, description, footer, body, message } = modalConfig[item];
        description = description && replaceToken(description);
        if (title || description || footer || body) {
            return (
                <CarouselItem key={index}>
                    <div>{<span dangerouslySetInnerHTML={{ __html: title }}></span>}</div>
                    <div className="modal-body-body"><span dangerouslySetInnerHTML={{ __html: description }}></span></div>
                    {checkIfArray(body) && renderBody(body)}
                    {
                        fixedModal && message &&
                        (
                            <div>
                                <span dangerouslySetInnerHTML={{ __html: message.title }}></span>
                                <label className='go-to-dashboard' onClick={(event) => {onButtonClick(event, {
                                        triggerEvent: BUTTON_EVENT_TYPE.TRIGGER_DASHBAORD
                                    })}}>{message.action}</label>
                            </div>
                        )
                    }
                    <div className="footer-btn">
                        {timerValue>0 && `${("0"+Math.floor(timerValue/60)).slice(-2)} : ${("0"+timerValue%60).slice(-2)}`}
                        {checkIfArray(footer) &&
                            footer.map((footerEle) => {
                                const { className, id, label , disabled } = footerEle;
                                return (
                                    <button
                                        key={id}
                                        id={id}
                                        className={`btn ml-2 ${className}`}
                                        onClick={() => handleButtonClick(id)}
                                        style={className === "button-secondary" ? hoverStyle : {}}
                                        onMouseOver={() => className === "button-secondary" && setBtnHoverStyle(`${modalColor + "20"}`)}
                                        onMouseLeave={() => setBtnHoverStyle("")}
                                        disabled={ id === "cancel" ? false :btnDisabled || (className === "button-secondary" && timerValue>0 ? true : btnDisabled) || (className === "button-primary" && inputValue.otp && inputValue.otp.length>3 ? false : disabled) }
                                    >
                                        {label}
                                    </button>
                                );
                            })}
                    </div>
                </CarouselItem>
            )
        }
    }) : [];
    return (
        <>
            <Modal
                isOpen={true}
                centered={true}
                contentClassName="modal-style"
                className="verification-modal"
            >
                <div className="modal-head-main">
                    <div
                        className="modal-head-top"
                    >
                        <div id='modal-loader-container'>
                            <div id="modal-loader"></div>
                        </div>
                        {(!fixedModal || headerIcon === VerifiedHeaderIcon) && (
                            <div className="modal-close-btn" onClick={toggleModal}>
                                <img src={CloseButtonIcon} />
                            </div>
                        )}
                    </div>
                    <div className="modal-head-bottom"></div>
                    <div className="modal-icon">
                        <img src={headerIcon} />
                    </div>
                </div>
                <div className="modal-body-main">
                    <div className="modal-body-form">
                        <Carousel
                            next={()=>{}}
                            previous={()=>{}}
                            activeIndex={modalStep}
                        >
                            {renderSlides}
                        </Carousel>
                    </div>
                </div>
            </Modal>
        </>
    );
};

const mapStateToProps = state => ({
    showVerifyModal: state.formReducer && state.formReducer.showVerifyModal,
    clientInfo: state.appDataReducer && state.appDataReducer.clientInfo,
    userDetail: state.appDataReducer && state.appDataReducer.userDetail,
    formData: state.formReducer && state.formReducer.formData,
    isCounselorAccessActive:
    state.appDataReducer && state.appDataReducer.isCounselorAccessActive,
    domainConfig: state.appDataReducer && state.appDataReducer.domainConfig,
    widgetFormConfig:state.allApplicationsReducer && state.allApplicationsReducer.widgetFormConfig,
});

const VerifyComponentConnected = connect(mapStateToProps,
    { setShowVerifyModal, updateVerificationData, updateFormData, userDetailSuccess }
)(VerificationModalPopup);

export { VerifyComponentConnected as default };