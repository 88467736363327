/*
 * <copyright company="Extraaedge Pvt Ltd">
 *        copyright (c) Extraaedge Pvt Ltd.  All rights reserved.
 * </copyright>
 */

import { fork } from "redux-saga/effects";
import { watchGetMasterData } from "../sagas/DemoSaga";
import { watchDashboardConfigData, watchgetSectionAdvtData } from "../../pages/dashboard/Saga";

import {
  watchConfigData as watchFormConfigData,
  watchUpdateFormConfig,
  watchUpdateFormError,
  watchUpdateLeadEditError,
} from "../../pages/form/Saga";

import { watchUpdateFormData } from "../../pages/form/Saga";

import { watchConfigData as watchLoginConfigData } from "../../pages/login/Saga";
import { watchConfigData as watchConituneConfigData } from "../../pages/continue/Saga";

import {
  watchConfigData as watchForgetPasswordConfigData,
  watchForgetPassword,
} from "../../pages/forgetpassword/Saga";
import {
  watchConfigData as watchResetPasswordConfigData,
  watchResetPassword,
} from "../../pages/resetPassword/Saga";

import {
  watchAppGetAllMaster,
  watchAppValidateLogin,
  watchUpdateUserInfoData,
  watchGetClientInfoData,
  watchGetUserInfo,
  watchUpdateCurrentStepNumber,
  watchRequestGeneratePDF,
  watchUpdateUserMetaData,
  watchRequestGeneratePreviewPDF,
  watchUploadUserProfile,
  watchRequestGenerateTemplatePDF,
  watchGetCounselorInfo,
  watchRequestAddLeadEvent,
  watchRequestCheckValuesInHistory,
  watchSwapCourseEditLead,
  watchRequestPushAppformLead,
  watchGetPaymentTransactionHistory,
  watchGetDomainConfigData,
  watchGetActivities,
  watchGetAllApplicationDetails,
  watchGetAllApplicationsConfig,
  watchUpdateAllConfigurationReducer,
  watchUpdateFormDataInUKC,
  watchGetAndUpdateTokenInUKC,
  watchGetLeadDocuments,
  watchUpdateIndexMetaDataInAllApplication,
} from "../services/appData/Saga";
import {
  watchConversationMarkAsRead,
  watchCreateConversation,
  watchGetAllConversation,
  watchGetAllQuery,
  watchGetQueryCount,
  watchGetTotalunreadMessageCount,
  watchSubmitQuery,
} from "../../pages/query/Saga";
import { watchGetUserDetailsByLeadId, watchGetWidgetFormConfig, watchWebHookAddLead } from "../../pages/allApplications/Saga";

export function* rootSaga() {
  yield fork(watchGetMasterData);
  yield fork(watchDashboardConfigData);
  yield fork(watchFormConfigData);
  yield fork(watchLoginConfigData);
  yield fork(watchUpdateFormData);
  yield fork(watchUpdateFormConfig);
  yield fork(watchUpdateFormError);
  yield fork(watchAppGetAllMaster);
  yield fork(watchAppValidateLogin);
  yield fork(watchUpdateUserInfoData);
  yield fork(watchGetClientInfoData);
  yield fork(watchGetDomainConfigData);
  yield fork(watchGetUserInfo);
  yield fork(watchUpdateCurrentStepNumber);
  yield fork(watchUpdateLeadEditError);
  yield fork(watchForgetPasswordConfigData);
  yield fork(watchForgetPassword);
  yield fork(watchRequestGeneratePDF);
  yield fork(watchResetPasswordConfigData);
  yield fork(watchResetPassword);
  yield fork(watchUpdateUserMetaData);
  yield fork(watchRequestGeneratePreviewPDF);
  yield fork(watchUploadUserProfile);
  yield fork(watchRequestGenerateTemplatePDF);
  yield fork(watchConituneConfigData);
  yield fork(watchGetCounselorInfo);
  yield fork(watchRequestAddLeadEvent);
  yield fork(watchRequestCheckValuesInHistory);
  yield fork(watchSwapCourseEditLead);
  yield fork(watchRequestPushAppformLead);
  yield fork(watchGetPaymentTransactionHistory);
  yield fork(watchGetActivities);
  yield fork(watchGetAllApplicationDetails);
  yield fork(watchGetAllApplicationsConfig);
  yield fork(watchUpdateAllConfigurationReducer);
  yield fork(watchSubmitQuery);
  yield fork(watchGetAllQuery);
  yield fork(watchGetQueryCount);
  yield fork(watchGetTotalunreadMessageCount)
  yield fork(watchGetAllConversation);
  yield fork(watchConversationMarkAsRead);
  yield fork(watchCreateConversation);
  yield fork(watchGetWidgetFormConfig);
  yield fork(watchWebHookAddLead);
  yield fork(watchgetSectionAdvtData);
  yield fork(watchUpdateFormDataInUKC);
  yield fork(watchGetAndUpdateTokenInUKC);
  yield fork(watchGetLeadDocuments);
  yield fork(watchUpdateIndexMetaDataInAllApplication);
  yield fork(watchGetUserDetailsByLeadId);
}
