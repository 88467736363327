import { createActionCreator } from "./Utilities";

export const INITIALIZE_APP = "INITIALIZE_APP";
export const INITIALIZE_APP_COMPLETE = "INITIALIZE_APP_COMPLETE";
export const INITIALIZE_APP_ERROR = "INITIALIZE_APP_ERROR";
export const RESET_APP = "app/RESET";
export const initializeApp = createActionCreator(INITIALIZE_APP, "");

export const resetApp = createActionCreator(RESET_APP, "");
export const initializeAppCompleted = createActionCreator(
  INITIALIZE_APP_COMPLETE,
  "message"
);
export const initializeAppFailure = createActionCreator(
  INITIALIZE_APP_ERROR,
  "message"
);
