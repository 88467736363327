import React from "react";
import { Button } from "reactstrap";

const ButtonLoader = (props) => {
  const {
    isInProgress,
    buttonLabel,
    buttonId,
    buttonClassName,
    onClickFunction,
    disabled,
    buttonColor = "primary",
    loaderDivClassName = "loader-div",
    loaderClassName = "",
  } = props;
  return (
    <Button
      id={buttonId}
      className={buttonClassName}
      onClick={onClickFunction}
      disabled={disabled}
      color={buttonColor}
    >
      {isInProgress ? (
        <div className={loaderDivClassName}>
          <div className={loaderClassName} id="loading-spinner"></div>
        </div>
      ) : (
        buttonLabel
      )}
    </Button>
  );
};

export default ButtonLoader;
