import { get, has, isInteger, isNil, isNumber, set } from "lodash";
import { actionTypes as appActionTypes } from "../../shared/services/appData/Actions";
import { actionTypes } from "./Actions";

import { PERMANENT_ADDRESS_KEYS, PRESENT_ADDRESS_KEYS, FEES_TYPE, VALIDATION_ERROR } from "../../shared/utilties/Constants";
import { checkIfArray, checkIfObject, getGroupKeyMappingObject } from "../../shared/utilties/Utils";
const defaultState = {
  leadEditErrorCount: 0,
  isInProgress: false,
  data: null,
  formConfigURL:null,
  errorMessage: "",
  formError: null,
  formData: null,
  initialFormData: null,
  updateOn: new Date().toLocaleString(),
  leadEditError: null,
  triggerValidation: 0,
  promoCodeDetails: null,
  paymentDetails: null,
  uniqueFieldByInputType: null,
  counselorFreezingCondition: {
    counselorRoles: [],
    excludeFields: [],
    excludeControls: [],
  },
  pinCodesByDistictId: null,
  showVerifyModal: false,
  formCompletionPercentage: {
    totalRequiredFields: null,
    feesType1PaymentStepWeightagePercent: null,
    feesType2PaymentStepWeightagePercent: null
  },
  emailOrMobileValidationInProgress: {},
  fieldValidationInApiProgress: {},
  stepwiseMandatoryFields:null,
  fieldsChangeBeforeFeestype1: null,
  fieldsChangeBeforeFeestype2: null,
  preferredCoursesPricingMapping: null,
  restrictSameValueGroupFieldskey:{},
  sameGroupFieldsKey:{}
};
export function reducer(state = defaultState, action) {
  switch (action.type) {
    case appActionTypes.LOGOUT: {
      return {
        ...defaultState,
      };
    }
    case actionTypes.TOGGLE_VERIFY_MODAL: {
      return {
        ...state,
        showVerifyModal: action.payload.data
      };
    }
    case actionTypes.GET_FORM_CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case actionTypes.UPDATE_FORM_CONFIG_SUCCESS:
    case actionTypes.GET_FORM_CONFIG_SUCCESS: {
      const groupKeySpecificMappingObject = action.payload && action.payload.data ?  getGroupKeyMappingObject(action.payload.data) : {};
      return {
        ...state,
        ...(groupKeySpecificMappingObject || {}),
        isInProgress: false,
        data: action.payload.data,
        formConfigURL: action.payload.formConfigURL,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.GET_FORM_CONFIG_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
        isInProgress: false,
      };
    }
    case actionTypes.UPDATE_FORM_DATA_SUCCESS: {
      /*if (action.payload && action.payload.data && !!action.payload.data.id) {
        //found proper object to update in form data
        let updatedFormData = Object.assign({}, state.formData);
        let obj = {};
        obj[action.payload.data.id] = action.payload.data.value;
        Object.assign(updatedFormData, obj);
        return {
          ...state,
          formData: updatedFormData
        };
      }*/
      if (!!action?.payload?.data) {
        //found proper object to update in form data
        if (
          action.payload.data &&
          Object.keys(action.payload.data).length > 0
        ) {
          let updatedFormData = Object.assign(
            {},
            state.formData,
            action.payload.data
          );
          const { PERMANENT_PINCODE, PERMANENT_DISTRICT, PERMANENT_PINCODE_NAME } = PERMANENT_ADDRESS_KEYS;
          if(has(updatedFormData,PERMANENT_PINCODE) && has(updatedFormData,PERMANENT_DISTRICT)){
            let pinCodeId = get(updatedFormData,PERMANENT_PINCODE);
            let districtId = get(updatedFormData,PERMANENT_DISTRICT);
            pinCodeId = pinCodeId && pinCodeId.toString();
            districtId = districtId && districtId.toString();
            const { pinCodesByDistictId } = state ? state : {};
            if(checkIfObject(pinCodesByDistictId) && districtId && checkIfArray(pinCodesByDistictId[districtId])){
              const selectedPinCodeObj = pinCodesByDistictId[districtId].find(ele => ele.id == pinCodeId);
              const { name } = selectedPinCodeObj ? selectedPinCodeObj : {};
              name && set(updatedFormData,PERMANENT_PINCODE_NAME,name);
            }
          }
          if(PERMANENT_PINCODE in updatedFormData && !has(updatedFormData,PERMANENT_PINCODE) && has(updatedFormData,PERMANENT_DISTRICT)){
            set(updatedFormData,PERMANENT_PINCODE_NAME,null);
          }
          const { pinCodesByDistictId } = state || {};
          const { PRESENT_PINCODE, PRESENT_DISTRICT, PRESENT_PINCODE_ID } = PRESENT_ADDRESS_KEYS;
          if(!!updatedFormData?.isSamePresentAddress){
            if(has(updatedFormData,`permenentCountry`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_COUNTRY,updatedFormData.permenentCountry);
            }
            if(has(updatedFormData,`permenentState`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_STATE,updatedFormData.permenentState);
            }
            if(has(updatedFormData,`permenentDistrict`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_DISTRICT,updatedFormData.permenentDistrict);
            }
            if(has(updatedFormData,`permenentCity`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_CITY,updatedFormData.permenentCity);
            }
            if(has(updatedFormData,`permenentAddressLine1`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_ADDRESS_LINE_ONE,updatedFormData.permenentAddressLine1);
            }
            if(has(updatedFormData,`permenentAddressLineTwo`)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_ADDRESS_LINE_TWO,updatedFormData.permenentAddressLineTwo);
            }
            if(has(updatedFormData,`permenentPincode`) && (has(updatedFormData,PRESENT_DISTRICT) ? checkIfObject(pinCodesByDistictId) : true)){
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_PINCODE,updatedFormData.permenentPincode);
              set(updatedFormData,PRESENT_ADDRESS_KEYS.PRESENT_PINCODE_ID,updatedFormData.permenentPincode);
            }
          }
          if(has(updatedFormData,PRESENT_PINCODE_ID) && has(updatedFormData,PRESENT_DISTRICT)){
            let pinCodeId = get(updatedFormData,PRESENT_PINCODE_ID);
            let districtId = get(updatedFormData,PRESENT_DISTRICT);
            pinCodeId = pinCodeId && pinCodeId.toString();
            districtId = districtId && districtId.toString();
            if(checkIfObject(pinCodesByDistictId) && districtId && checkIfArray(pinCodesByDistictId[districtId])){
              const selectedPinCodeObj = pinCodesByDistictId[districtId].find(ele => ele.id == pinCodeId);
              const { name } = selectedPinCodeObj ? selectedPinCodeObj : {};
              name && set(updatedFormData,PRESENT_PINCODE,name);
            }
          }
          if(PRESENT_PINCODE_ID in updatedFormData && !has(updatedFormData,PRESENT_PINCODE_ID) && has(updatedFormData,PRESENT_DISTRICT)){
            set(updatedFormData,PRESENT_PINCODE,null);
          }
          Object.keys(updatedFormData).map((key) => {
            if (
              updatedFormData[key] === undefined ||
              updatedFormData[key] === null ||
              updatedFormData[key] === "" ||
              (Array.isArray(updatedFormData[key]) &&
                updatedFormData[key].length === 0)
            ) {
              delete updatedFormData[key];
            }
            return true;
          });
          
          return {
            ...state,
            leadEditError: null,
            formData: JSON.parse(JSON.stringify(updatedFormData)),
          };
        }
      }
      return {
        ...state,
      };
    }
    case actionTypes.UPDATE_FORM_DATA_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case actionTypes.LEAD_EDIT_ERROR_SUCCESS: {
      return {
        ...state,
        leadEditError: action.payload.leadEditError,
        leadEditErrorCount: !!action?.payload?.leadEditError && action?.payload?.leadEditError?.errorCode && parseInt(action?.payload?.leadEditError?.errorCode) !== VALIDATION_ERROR.UPDATE_LEAD_FAIL_MANDATORY_FIELD_MISSING.errorCode ? state.leadEditErrorCount + 1 : 0
      };
    }
    case actionTypes.LEAD_EDIT_ERROR_RESET: {
      return {
        ...state,
        leadEditErrorCount: 0,
      };
    }

    case actionTypes.UPDATE_FORM_ERROR_SUCCESS: {
      if (action.payload && action.payload.data) {
        //found proper object to update in form data
        let collection =
          action.payload &&
          action.payload.data &&
          Array.isArray(action.payload.data) &&
          action.payload.data.length
            ? action.payload.data
            : null;
        let updatedFormDataErrorMessage = Object.assign({}, state.formError);
        if (collection && collection.length > 0) {
          collection.map((item) => {
            !!item.message
              ? Object.assign(updatedFormDataErrorMessage, {
                  [item.id]: item.message,
                })
              : updatedFormDataErrorMessage[item.id] &&
                delete updatedFormDataErrorMessage[item.id];
            return true;
          });
          return {
            ...state,
            formError:
              Object.keys(updatedFormDataErrorMessage).length > 0
                ? updatedFormDataErrorMessage
                : null,
          };
        }
        return {
          ...state,
        };
      }
      return {
        ...state,
      };
    }
    case actionTypes.UPDATE_FORM_ERROR_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.errorMessage,
      };
    }
    case actionTypes.UPDATE_FORM_CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case actionTypes.UPDATE_FORM_CONFIG_FAILED: {
      return {
        ...state,
        isInProgress: false,
      };
    }
    /*case actionTypes.UPDATE_FORM_CONFIG_SUCCESS: {
      return {
        ...state,
        isInProgress: false,
        data: Object.assign({}, action.payload.data),
        updateOn: new Date().toLocaleString(),
      };
    }*/

    case actionTypes.UPDATE_FORM_INITIAL_DATA: {
      let initialFormData = JSON.parse(JSON.stringify(action.payload.data));
      //Object.assign({}, action.payload.data);
      return {
        ...state,
        initialFormData,
        formData: JSON.parse(JSON.stringify(initialFormData)),
        isInProgress: false,
        updateOn: new Date().toLocaleString(),
      };
    }

    case actionTypes.UPDATE_PROMOCODE_DATA: {
      return {
        ...state,
        promoCodeDetails: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }

    case actionTypes.UPDATE_PAYMENT_DETAILS: {
      return {
        ...state,
        paymentDetails:
          !isNil(get(action, "payload.data")) &&
          Object.keys(get(action, "payload.data")).length &&
          !isNil(get(state, "paymentDetails")) &&
          Object.keys(get(state, "paymentDetails")).length
            ? Object.assign(get(state, "paymentDetails"), action.payload.data)
            : action.payload.data,
        updateOn: new Date().toLocaleString()
      };
    }

    case actionTypes.TRIGGER_FORM_VALIDATION: {
      const { triggerValidation } = state;
      return {
        ...state,
        triggerValidation: triggerValidation + 1,
      };
    }

    case actionTypes.ADD_UNIQUE_FEILD_BY_INPUT_TYPE: {
      return {
        ...state,
        uniqueFieldByInputType: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }

    case actionTypes.COUNSELOR_FREEZE_CONDITION: {
      return {
        ...state,
        counselorFreezingCondition: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.PIN_CODE_LIST: {
      return {
        ...state,
        pinCodesByDistictId: {
          ...state.pinCodesByDistictId,
          ...action.payload.data
        }
      };
    }
    case actionTypes.UPDATE_FORM_COMPLETION_DETAILS: {
      const { requiredFieldsBySteps, 
        feesType1PaymentStepWeightagePercent, 
        feesType2PaymentStepWeightagePercent
      } = action.payload
      return {
        ...state,
        formCompletionPercentage: {
          ...state.formCompletionPercentage,
          totalRequiredFields: !isNil(requiredFieldsBySteps) ? requiredFieldsBySteps : state.formCompletionPercentage.totalRequiredFields,
          feesType1PaymentStepWeightagePercent: !isNil(feesType1PaymentStepWeightagePercent) ? feesType1PaymentStepWeightagePercent : state.formCompletionPercentage.feesType1PaymentStepWeightagePercent,
          feesType2PaymentStepWeightagePercent: !isNil(feesType2PaymentStepWeightagePercent) ? feesType2PaymentStepWeightagePercent : state.formCompletionPercentage.feesType2PaymentStepWeightagePercent
        }
      }
    }
    case actionTypes.TOGGLE_EMAIL_MOBILE_VALIDATION_PROGRESS: {
      return {
        ...state,
        emailOrMobileValidationInProgress: {...state.emailOrMobileValidationInProgress, [action.payload.type]: action.payload.flag}
      }
    }

    case actionTypes.TOGGLE_FIELD_VALIDATION_PROGRESS: {
      return {
        ...state,
        fieldValidationInApiProgress: {...state.fieldValidationInApiProgress, [action.payload.type]: action.payload.flag}
      }
    }
    

    case actionTypes.SET_STEP_WISE_MANDATORY_FIELDS: {
      return {
        ...state,
        stepwiseMandatoryFields: action.payload.data
      };
    }
    case actionTypes.SET_FIELDS_CHANGE_BEFORE_FEESTYPE1: {
      return {
        ...state,
        fieldsChangeBeforeFeestype1: action.payload.data
      };
    }
    case actionTypes.SET_FIELDS_CHANGE_BEFORE_FEESTYPE2: {
      return {
        ...state,
        fieldsChangeBeforeFeestype2: action.payload.data
      };
    }
    case actionTypes.UPDATE_PREFERRED_COURSES_PRICING: {
      return {
        ...state,
        preferredCoursesPricingMapping: action.payload.data
      };
    }
    default: {
      return state;
    }
  }
}
