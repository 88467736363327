export const createActionCreator = (type, ...argNames) => {
  return function(...args) {
    let action = { type };
    let payload = {};
    argNames.forEach((arg, index) => {
      payload[argNames[index]] = args[index];
    });

    action.payload = payload;
    return action;
  };
};
