//CSS framework - bootstrap
//import "bootstrap/dist/css/bootstrap.css";
import React from "react";
import ReactDOM from "react-dom";
//import * as serviceWorker from './serviceWorker';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { composeWithDevTools } from "redux-devtools-extension";
import zipy from "zipyai";
import App from "./App";
import "./custom.scss";
import "./index.scss";
//bootstrap
import "./infrastructure/localization/localizationservice";
import "./infrastructure/network/HttpClient";
import { actionTypes } from "./shared/services/appData/Actions";
import { configureStore } from "./shared/store/ConfigureStore";
import { STORAGE_KEY, STORAGE_TYPE } from "./shared/utilties/Constants";
import { OfflineStorage } from "./shared/utilties/OfflineStorage";
import "./theme.scss";
import { isEmpty } from "lodash";
import { checkIfArray, detectBrowser } from "./shared/utilties/Utils";

const store = configureStore({}, composeWithDevTools());
let loadingStatus={"langFilesLoaded":false,"masterDataLoaded":false};
let appRendered=false;
export function renderApp(params) {
  if(detectBrowser() === "Safari") {
    const body = document.body;
    if(body) {
      body.style.fontFamily = "Source Sans Pro , sans-serif";
      console.log("Safari");
    }
  }
  if(window.location && window.location.search && window.location.search.includes("eekey")){
    let domainUrl = window.location.href;
    domainUrl = new URL(domainUrl);
    let jodoCallbackHash = domainUrl.searchParams.get("eekey");
    if(jodoCallbackHash){
      if(jodoCallbackHash.includes("?status=success")){
        jodoCallbackHash = jodoCallbackHash.replace("?status=success","");
      }
      jodoCallbackHash = atob(jodoCallbackHash);
      const appformUrl = window.location.origin + "/form" + jodoCallbackHash;
      window.open(appformUrl, "_self");
      return;
    }
  }
  if(window.extraaedge && window.extraaedge.isZIPYActive){
    zipy.init(`6bed96d3`);
  }
  if(params && Object.keys(params).length){
    loadingStatus=Object.assign(loadingStatus,params);
    if(Object.values(loadingStatus).indexOf(false)===-1 && !appRendered ){
      appRendered=true;
      var element = document.getElementById("static-loader");
      if(element){
        element.parentNode.removeChild(element);
      }
      /*<ErrorBoundary> 
        <Provider store={store}>
          <App />
        </Provider>
      </ErrorBoundary>*/
      ReactDOM.render(
        <BrowserRouter>
          <Provider store={store}>
            <App />
          </Provider>
        </BrowserRouter>,
        document.getElementById("root")
      );
    }
  }  
}
document.write('<div class="zipy-block"><div id="static-loader" class="api-loader"><div class="animation"></div></div></div>');
// store.dispatch({
//   type: INITIALIZE_APP,
//   payload: "Application initilaized"
// });
let accessToken = OfflineStorage.getItem(
  STORAGE_KEY.ACCESS_TOKEN,
  STORAGE_TYPE.LOCAL
);
if (!accessToken) OfflineStorage.clear(STORAGE_TYPE.LOCAL);

store.dispatch({
  type: actionTypes.DOMAIN_CONFIG_INIT,
  payload: {
    data:
      !!window?.extraaedge?.client?.pageConfiguration?.domainConfig
        ? window.extraaedge.API_END_POINT.getContent +
          "" +
          window.extraaedge.client.pageConfiguration.domainConfig
        : ""
  }
});

// if (accessToken) {//Fetch user details 
//   store.dispatch({
//     type: actionTypes.USER_DETAIL_INIT,
//     payload: { logFrom: "Fetch User Details" },
//   });
// }

store.dispatch({
  type: actionTypes.CLIENT_INFO_INIT,
  payload: {
    data:
      window.extraaedge &&
      window.extraaedge.client &&
      window.extraaedge.client.pageConfiguration &&
      !!window.extraaedge.client.pageConfiguration.clientConfig
        ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.pageConfiguration.clientConfig}`
        : "",
    rootFolderURL:
      window.extraaedge &&
      window.extraaedge.client &&
      window.extraaedge.client.pageConfiguration &&
      !!window.extraaedge.client.pageConfiguration.clientConfig_root
        ? `${window.extraaedge.API_END_POINT.getContent}${window.extraaedge.client.pageConfiguration.clientConfig_root}`
        : ""            
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
