import { actionTypes } from "./Actions";

import { FEES_TYPE, PAYMENT_SUCCESS_STATUS, STORAGE_KEY, STORAGE_TYPE, VERIFICATION_AND_EDIT_ACTION_ITEMS } from "../../utilties/Constants";
import { OfflineStorage } from "../../utilties/OfflineStorage";
import { cloneDeep, compact, filter, get, isEmpty, isNil, map, set, uniq } from "lodash";
import { logInfo } from "../../../infrastructure/logging/Logger";
import { checkIfArray, checkIfObject, getMasterDataByKey, leadObjectManager } from "../../utilties/Utils";
const defaultState = {
  isProgressCount: 0,
  isInProgress: false,
  masterData: null,
  filterMasterData:null,//this will hold collection of E1, E2, E3 and E4 based on pricing criteria 
  currentStep: null,
  userDetail: null,
  showRefreshWindowPopup:false,
  mandatoryFieldsMissing:[],
  allInstIdArray: [],
  isLeftSidebarExpanded:false,// Hamburger click
  clientInfo:
    !!OfflineStorage.getItem(STORAGE_KEY.CLIENT_INFO, STORAGE_TYPE.LOCAL) &&
    !!JSON.parse(
      OfflineStorage.getItem(STORAGE_KEY.CLIENT_INFO, STORAGE_TYPE.LOCAL)
    ) &&
    Object.keys(
      JSON.parse(
        OfflineStorage.getItem(STORAGE_KEY.CLIENT_INFO, STORAGE_TYPE.LOCAL)
      )
    ).length > 0
      ? JSON.parse(
          OfflineStorage.getItem(STORAGE_KEY.CLIENT_INFO, STORAGE_TYPE.LOCAL)
        )
      : null,
  auth_details:
    !!OfflineStorage.getItem(STORAGE_KEY.AUTH_DETAILS, STORAGE_TYPE.LOCAL) &&
    !!JSON.parse(
      OfflineStorage.getItem(STORAGE_KEY.AUTH_DETAILS, STORAGE_TYPE.LOCAL)
    ) &&
    Object.keys(
      JSON.parse(
        OfflineStorage.getItem(STORAGE_KEY.AUTH_DETAILS, STORAGE_TYPE.LOCAL)
      )
    ).length > 0
      ? JSON.parse(
          OfflineStorage.getItem(STORAGE_KEY.AUTH_DETAILS, STORAGE_TYPE.LOCAL)
        )
      : null,
  userDetailError: "",
  errorMessage: "",
  AuthDetailError: null,
  pdfError: null,
  templatePDFError: null,
  previewPdfError: null,
  updateOn: new Date().toLocaleString(),
  isCounselorAccessActive: false,
  counselorDetails: null,
  addEventError:null,
  historyValues:null,
  isAutoSaveInProgress: false,
  isSelectedSlotExpired:false,
  swapCourseErrorMessasage:null,
  isGlobalInProgress:false,
  globalLoaderMessage:null,
  isLoginRequestInProgress:false,
  leadHistoryStatusId:null,
  stepDetailsFromMissingFieldsPopupFirsttimeRequested:null,
  autoNavigateRouteDetails:null,
  editLeadAPIError:null,
  isSlotBookingAPICallEnabled: false,
  isSlotBookingBtnTriggered: false,
  slotBookingAPICallFunc: null,
  domainConfig: null,
  activeleftMenu: null,
  studentActivities: null,
  getStudentActivitiesIsInprogress: false,
  applicationCardData: null,
  allApplicationsData: null,
  allApplicationsIds: null,
  allApplicatonsInstData: null,
  allApplicationsDataMappedWithLeadIds: null,
  instwiseAllConfigs: null,
  fetchAllApplicationDataIsinProgress: false,
  isAllApplicationDataAPICalled: false,
  isAllApplicationDataFailed: false,
  payment_txn_success_status:null,
  paid_FeesType_1_Entities:null,
  applicationsAuthDetailsMappedWithLeadId: null,
  updatedMetaDataLeadId: null,
  fetchLeadDocumentsIsInProgress: false,
  leadIdwiseFetchLeadDocumentsIsInProgress:null,
  leadDocuments: null,
  instwiseLeadDocuments: null,
  leadIdWiseActionInProgress: {}
};

export function reducer(state = defaultState, action) {
  switch (action.type) {
    case actionTypes.LOGOUT: {
      return {
        ...defaultState,
      };
    }
    case actionTypes.MASTERDATA_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
        isProgressCount: state.isProgressCount + 1,
      };
    }
    case actionTypes.MASTERDATA_COMPLETED: {
      const filterMasterData={};
      if(action.payload.data){
        //update filterMasterData
        const masterData=action.payload.data;
        let {pricingCriterion2=null,pricingCriterion=null} = masterData || {};
        if (!!pricingCriterion2 && checkIfArray(pricingCriterion2)){
          //pricingCriterion2          
          const {labelToken=null} = pricingCriterion2[0] || {};
          if(isNil(labelToken)){
              //set(pricingCriterion2[0],`labelToken`,`%entity.name% (%pricingCriterion2.name%)`); //for DEVELOPMENT use ONLY, COMMENT this line after development

              set(pricingCriterion2[0],`labelToken`,labelToken); //for PRODUCTION use ONLY, UNCOMMENT this line after development
          }              
        }
        if (
          
          !isNil(pricingCriterion) &&
          !isEmpty(pricingCriterion)
        ) {
          let entityNumber = parseInt(
            pricingCriterion.replace(/[^0-9]/g, "")
          );
          let arrayName = "Entity" + entityNumber;
          const arrayNameCollection = !isNil(masterData) && getMasterDataByKey(arrayName,masterData);
          if (checkIfArray(arrayNameCollection)) {
            const pricingListCollection = !isNil(masterData) && getMasterDataByKey(`PricingList`,masterData);
            var parentIds =
              checkIfArray(pricingListCollection)
                ? pricingListCollection
                : [];
            parentIds = parentIds.length > 0
              ? compact(uniq(map(parentIds, "entityId")))
              : parentIds;
            set(
              filterMasterData,
               arrayName,
              filter(arrayNameCollection, (o) => {
                return (
                  (isNil(get(o, "isSystemValue")) ||
                    get(o, "isSystemValue") === false) &&
                  (isNil(get(o, "isActive")) ||
                    get(o, "isActive") === true) &&
                  parentIds.includes(o.id)
                );
              })
            );
            while (entityNumber > 1) {
              let currentArrayName = `Entity${entityNumber}`;
              let parentArrayName =`Entity${entityNumber - 1}`;
              let parentFieldName = `entity${entityNumber - 1}Id`;
              const currentArrayNameCollection = !isNil(masterData) && getMasterDataByKey(currentArrayName,masterData);
              const parentArrayNameCollection = !isNil(masterData) && getMasterDataByKey(parentArrayName,masterData);
              let currentIds =
                checkIfArray(currentArrayNameCollection)
                  ? uniq(
                      compact(
                        map(
                          currentArrayNameCollection,
                          parentFieldName
                        )
                      )
                    )
                  : [];
              if (
                checkIfArray(parentArrayNameCollection)
              ) {
                set(
                  filterMasterData,
                  parentArrayName,
                  filter(parentArrayNameCollection, (o) => {
                    return (
                      (isNil(get(o, "isSystemValue")) ||
                        get(o, "isSystemValue") === false) &&
                      (isNil(get(o, "isActive")) ||
                        get(o, "isActive") === true) &&
                      currentIds.includes(o.id)
                    );
                  })
                );
              }
              entityNumber--;
            }
          }
        }
        logInfo("filterMasterData",filterMasterData);
      }
      return {
        ...state,
        filterMasterData,
        isInProgress: false,
        isProgressCount: state.isProgressCount - 1,
        masterData: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.MASTERDATA_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }
    case actionTypes.USER_DETAIL_REQUESTED: {
      return {
        ...state,
        userDetailError:"",
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }
    case actionTypes.USER_DETAIL_SUCCESS: {      
    /**
     * get paid entities and store in seperate reducer state 
     */
    let responseUserDetails = cloneDeep(action.payload.data);
    const allApplicationsData = state.allApplicationsData;
    if(checkIfArray(allApplicationsData)) {
      allApplicationsData.some(applicationData => {
        if(get(applicationData, "lead.id") === get(responseUserDetails, "lead.id")) {
          if(get(responseUserDetails, "accessToken")) {
            responseUserDetails = {
              ...responseUserDetails,
              accessToken: get(responseUserDetails, "accessToken")
            };
          }
          return true;
        }
      })
    }
    const { paymentDetails = null } = responseUserDetails || {};
    const payment_txn_success_status = state.payment_txn_success_status || 
    (state.clientInfo &&
      checkIfArray(get(state.clientInfo, "paymentDetails.payment_success_status"))
        ? state.clientInfo.paymentDetails.payment_success_status
        : PAYMENT_SUCCESS_STATUS);
    const paid_FeesType_1_Entities = (
      paymentDetails &&
      map(
        filter(paymentDetails, item => {
          return (
            (isNil(get(item, "paymentDetailsModel.feesType")) ||
              isEmpty(get(item, "paymentDetailsModel.feesType")) ||
              get(item, "paymentDetailsModel.feesType") ===
                FEES_TYPE.APPLICATION_FORM_FEES) &&
            payment_txn_success_status.includes(
              get(item, "paymentDetailsModel.transactionStatus")
            )
          );
        }),
        item => {
          set(
            get(item, "paymentDetailsModel"),
            "entityId",
            get(item, "enrolledLeadModel.entityId")
          );
          /*UKC code - COMMENTED FOR FUTHER USE  */
          !isNil(get(item, "enrolledLeadModel.amount")) && set(
            get(item, "paymentDetailsModel"),
            "amount",
            get(item, "enrolledLeadModel.amount")
          );
          return get(item, "paymentDetailsModel");
        }
      )
    );
      const updatedLeadHistoryStatusId = get(responseUserDetails, "lead.leadHistoryStatusId");
      return {
        ...state,
        payment_txn_success_status,
        paid_FeesType_1_Entities,
        isInProgress: false,
        userDetailError:"",
        isProgressCount: state.isProgressCount - 1,
        userDetail: responseUserDetails,
        updateOn: new Date().toLocaleString(),
        leadHistoryStatusId: Array.isArray(updatedLeadHistoryStatusId) ? updatedLeadHistoryStatusId : state.leadHistoryStatusId
      };
    }
    case actionTypes.USER_DETAIL_FAILED: {
      const errorMessage = get(action.payload, "errorMessage.message")
        ? action.payload.errorMessage.message
        : action.payload.message; 
      return {
        ...state,
        userDetailError:errorMessage,
        errorMessage: errorMessage,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }

    case actionTypes.USER_INFO_UPDATE_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: !action.payload.isAutoSaveRequest,
        isAutoSaveInProgress: action.payload.isAutoSaveRequest
      };
    }
    case actionTypes.USER_INFO_UPDATE_SUCCESS: {
      //found proper object to update in form data
      // let updatedData = Object.assign({}, state.userDetail);
      // Object.assign(updatedData, action.payload.data);
      let responseUserDetails = !!action?.payload?.data
      ? cloneDeep(action.payload.data)
      : state.userDetail;

      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      let updateApplicationsAuthDetailsAccordingToLeadId = cloneDeep(state.allApplicationsDataMappedWithLeadIds || {});
      if (checkIfArray(updatedAllApplicationData) && responseUserDetails) {
        const indexOfOldUser = updatedAllApplicationData.findIndex(
          //Find the index of lead which is updated and replace with new update user details in UKC for my application card and dashboard
          (applicationData) =>
            get(applicationData, "lead.id") === get(responseUserDetails, "lead.id")
        );
        if (indexOfOldUser !== -1) {
          responseUserDetails = {
            ...responseUserDetails,
            accessToken: get(updatedAllApplicationData[indexOfOldUser],"accessToken")
          }
          updatedAllApplicationData[indexOfOldUser] = responseUserDetails;
        }
      }
      const userDetails = get(responseUserDetails, "user");
      if(checkIfObject(userDetails) && checkIfArray(updatedAllApplicationData)) {
        updatedAllApplicationData = updatedAllApplicationData.map((applicationData) => {
          const leadId = get(applicationData, "lead.id");
          const updatedData = {
            ...applicationData,
            user: userDetails
          }
          updateApplicationsAuthDetailsAccordingToLeadId = {
            ...(updateApplicationsAuthDetailsAccordingToLeadId || {}),
            [leadId]: updatedData
          }
          return updatedData;
        })
      }
        
      !!action?.payload?.data && leadObjectManager.setUserInfo(responseUserDetails);

      /**
       * get paid entities and store in seperate reducer state 
       */
      const { paymentDetails = null } = responseUserDetails || {};
      const payment_txn_success_status = state.payment_txn_success_status || 
      (state.clientInfo &&
        checkIfArray(get(state.clientInfo, "paymentDetails.payment_success_status"))
          ? state.clientInfo.paymentDetails.payment_success_status
          : PAYMENT_SUCCESS_STATUS);
      const paid_FeesType_1_Entities = (
        paymentDetails &&
        map(
          filter(paymentDetails, item => {
            return (
              (isNil(get(item, "paymentDetailsModel.feesType")) ||
                isEmpty(get(item, "paymentDetailsModel.feesType")) ||
                get(item, "paymentDetailsModel.feesType") ===
                  FEES_TYPE.APPLICATION_FORM_FEES) &&
              payment_txn_success_status.includes(
                get(item, "paymentDetailsModel.transactionStatus")
              )
            );
          }),
          item => {
            set(
              get(item, "paymentDetailsModel"),
              "entityId",
              get(item, "enrolledLeadModel.entityId")
            );
            /*UKC code - COMMENTED FOR FUTHER USE  */
            !isNil(get(item, "enrolledLeadModel.amount")) && set(
              get(item, "paymentDetailsModel"),
              "amount",
              get(item, "enrolledLeadModel.amount")
            );
            return get(item, "paymentDetailsModel");
          }
        )
      );
      return {
        ...state,
        paid_FeesType_1_Entities,
        payment_txn_success_status,
        userDetail:responseUserDetails,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        isAutoSaveInProgress: false,
        allApplicationsData: updatedAllApplicationData,
        allApplicationsDataMappedWithLeadIds: updateApplicationsAuthDetailsAccordingToLeadId
      };
    }
    case actionTypes.USER_INFO_UPDATE_FAILED: {
      return {
        ...state,
        editLeadAPIError:action.payload.errorMessage,
        errorMessage: action?.payload?.errorMessage?.data?.message,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        isAutoSaveInProgress: false
      };
    }

    case actionTypes.CLIENT_INFO_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }
    case actionTypes.CLIENT_INFO_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        clientInfo: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.CLIENT_INFO_FAILED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        errorMessage: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isInProgress: false,
      };
    }

    case actionTypes.UPDATE_CLIENT_INFO: {
      return {
        ...state,
        clientInfo: action.payload.data,
        updateOn: new Date().toLocaleString(),
      }
    }

    case actionTypes.AUTH_DETAILS_REQUESTED: {
      return {
        ...state,
        isLoginRequestInProgress:true,
        isProgressCount: state.isProgressCount + 1,
        AuthDetailError: null,
        isInProgress: true,
      };
    }
    case actionTypes.AUTH_DETAILS_SUCCESS: {
      return {
        ...state,
        isInProgress: false,
        isLoginRequestInProgress:false,
        isProgressCount: state.isProgressCount - 1,
        auth_details: action.payload.data,
        AuthDetailError: null,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.AUTH_DETAILS_FAILED: {
      return {
        ...state,
        isLoginRequestInProgress:false,
        AuthDetailError: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }
    case actionTypes.UPDATE_CURRENT_STEP_NUMBER_SUCCESS: {
      return {
        ...state,
        currentStep: action.payload.data,
      };
    }

    case actionTypes.GENERATE_PDF_REQUESTED: {
      return {
        ...state,
        pdfError: null,
        isProgressCount: state.isProgressCount + 1,
      };
    }
    case actionTypes.GENERATE_PDF_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        pdfError: null,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.GENERATE_PDF_FAILED: {
      return {
        ...state,
        pdfError: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
      };
    }
    case actionTypes.GENERATE_TEMPLATE_PDF_REQUESTED: {
      return {
        ...state,
        templatePDFError: null,
        isProgressCount: state.isProgressCount + 1,
      };
    }
    case actionTypes.GENERATE_TEMPLATE_PDF_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        templatePDFError: null,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.GENERATE_TEMPLATE_PDF_FAILED: {
      return {
        ...state,
        templatePDFError: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
      };
    }

    case actionTypes.USER_METADATA_UPDATE_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }
    case actionTypes.USER_METADATA_UPDATE_SUCCESS: {
      const { leadId } = action.payload.data || {};
      let updatedUserDetail = cloneDeep(state.userDetail);
      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      let updatedAllApplicationDataMappedWithId = cloneDeep(
        state.allApplicationsDataMappedWithLeadIds
      );
      const userDetailLeadId = get(updatedUserDetail, "lead.id");
      if (userDetailLeadId !== leadId) {
        updatedUserDetail =
          (checkIfArray(updatedAllApplicationData) &&
            updatedAllApplicationData.find(
              (leadData) => get(leadData, "lead.id") === leadId
            )) ||
          (updatedAllApplicationDataMappedWithId &&
            updatedAllApplicationDataMappedWithId[leadId]);
      }
      if(leadId) {
        updatedUserDetail = {
          ...(updatedUserDetail || {}),
          metaData: {
            ...(updatedUserDetail.metaData || {}),
            ...(action.payload.data || {})
          }
        }
        if(checkIfArray(updatedAllApplicationData)) {
          updatedAllApplicationData = updatedAllApplicationData.map((applicationDataObj) => {
            if(get(applicationDataObj, "lead.id") === leadId) {
              return updatedUserDetail;
            }
            return applicationDataObj;
          })
        }
        if(updatedAllApplicationDataMappedWithId && updatedAllApplicationDataMappedWithId[leadId]) {
          updatedAllApplicationDataMappedWithId = {
            ...(updatedAllApplicationDataMappedWithId),
            [leadId]: updatedUserDetail
          }
        }
      }
      
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        userDetail: updatedUserDetail,
        allApplicationsData: updatedAllApplicationData,
        allApplicationsDataMappedWithLeadIds: updatedAllApplicationDataMappedWithId,
        updatedMetaDataLeadId: leadId
      };
    }
    case actionTypes.USER_METADATA_UPDATE_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }

    case actionTypes.GENERATE_PREVIEW_PDF_REQUESTED: {
      return {
        ...state,
        previewPdfError: null,
        isProgressCount: state.isProgressCount + 1,
      };
    }
    case actionTypes.GENERATE_PREVIEW_PDF_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        previewPdfError: null,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.GENERATE_PREVIEW_PDF_FAILED: {
      return {
        ...state,
        previewPdfError: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
      };
    }
    case actionTypes.UPLOAD_PROFILE_PICTURE_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case actionTypes.UPLOAD_PROFILE_PICTURE_SUCCESS: {
      let userDetail = state.userDetail;
      userDetail.user.avatarUrl = action.payload.data.profilePathName;
      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      let updatedAllApplicationDataMappedWithId = cloneDeep(state.allApplicationsDataMappedWithLeadIds);
      if (checkIfArray(updatedAllApplicationData) && userDetail) {
        const indexOfOldUser = updatedAllApplicationData.findIndex(
          //Find the index of lead which is updated and replace with new update user details in UKC for my application card and dashboard
          (applicationData) =>
            get(applicationData, "lead.id") === get(userDetail, "lead.id")
        );
        if (indexOfOldUser !== -1) {
          userDetail = {
            ...userDetail,
            accessToken: get(updatedAllApplicationData[indexOfOldUser],"accessToken")
          }
          updatedAllApplicationData[indexOfOldUser] = userDetail;
          const leadId = get(userDetail, "lead.id");
          if(leadId && updatedAllApplicationDataMappedWithId) {
            updatedAllApplicationDataMappedWithId[leadId] = userDetail
          }
        }
      }
      return {
        ...state,
        isInProgress: false,
        userDetail: cloneDeep(userDetail),
        updateOn: new Date().toLocaleString(),
        allApplicationsData: updatedAllApplicationData,
        allApplicationsDataMappedWithLeadIds: updatedAllApplicationDataMappedWithId
      };
    }
    case actionTypes.UPLOAD_PROFILE_PICTURE_FAILED: {
      return {
        ...state,
        isInProgress: false,
      };
    }
    case actionTypes.COUNSELOR_ACCESS_GLOBAL_KEY_TOGGLE: {
      return {
        ...state,
        isCounselorAccessActive:
          !isNil(action) &&
          !isNil(get(action, `payload.isCounselorAccessActive`))
            ? action.payload.isCounselorAccessActive
            : state.isCounselorAccessActive,
      };
    }

    case actionTypes.COUNSELOR_DETAIL_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }
    case actionTypes.COUNSELOR_DETAIL_SUCCESS: {
      return {
        ...state,
        isInProgress: false,
        isProgressCount: state.isProgressCount - 1,
        counselorDetails: action.payload.data,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.COUNSELOR_DETAIL_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }



    

    case actionTypes.ADD_LEAD_EVENT_REQUESTED: {
      return {
        ...state,
        addEventError: null,
        isProgressCount: state.isProgressCount + 1,
      };
    }
    case actionTypes.ADD_LEAD_EVENT_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        addEventError: null,
        updateOn: new Date().toLocaleString(),
      };
    }
    case actionTypes.AUTH_DETAILS_FAILED: {
      return {
        ...state,
        addEventError : action.payload.message,
        updateOn: new Date().toLocaleString(),
        isProgressCount: state.isProgressCount - 1,
      };
    }


    
    case actionTypes.CHECK_VALUES_IN_HISTORY_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }
    case actionTypes.CHECK_VALUES_IN_HISTORY_SUCCESS: {
      const {
        key,
        data: { result } = {},
        params: { data: { storeLeadIdWiseValue, leadId } = {} } = {},
      } = get(action, `payload.data`) || {};
      if (storeLeadIdWiseValue) {
        let updatedKeyData = checkIfArray(
          get(state, `leadIdWiseHistoryValues.${leadId}.${key}`)
        )
          ? [...state.leadIdWiseHistoryValues[leadId][key], ...result]
          : result;
        return {
          ...state,
          leadIdWiseHistoryValues: {
            ...state.leadIdWiseHistoryValues,
            [leadId]: {
              [key]: updatedKeyData,
              apiCalled: true,
            },
          },
          isInProgress: false,
        };
      } else {
        let updatedKeyData =
          !!get(state, `historyValues.${key}`) &&
          Array.isArray(get(state, `historyValues.${key}`))
            ? [...get(state, `historyValues.${key}`), ...result]
            : result;
        return {
          ...state,
          historyValues: { ...state.historyValues, [key]: updatedKeyData },
          isProgressCount: state.isProgressCount - 1,
          isInProgress: false,
        };
      }
      
    }
    case actionTypes.CHECK_VALUES_IN_HISTORY_FAILED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }
    case actionTypes.ADD_SLOT_BOOKING_DATA_IN_MASTERDATA: {
      return {
        ...state,
        masterData: Object.assign(get(state,`masterData`),{"AllotmentSlots":get(action,`payload.data`)})
      }
    }
    
    case actionTypes.IS_SELECTED_SLOT_EXPIRED_KEY_TOGGLE: {
      return {
        ...state,
        isSelectedSlotExpired:
          !isNil(action) &&
          !isNil(get(action, `payload.isSelectedSlotExpired`))
            ? action.payload.isSelectedSlotExpired
            : state.isSelectedSlotExpired,
      };
    }
    
    case actionTypes.SWAP_COURSE_EDIT_REQUESTED: {
      return {
        ...state,
        swapCourseErrorMessasage:null,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true
      };
    }
    case actionTypes.SWAP_COURSE_EDIT_SUCCESS: {
      //found proper object to update in form data
      // let updatedData = Object.assign({}, state.userDetail);
      // Object.assign(updatedData, action.payload.data);
      let responseUserDetails = !!action?.payload?.data
      ? cloneDeep(action.payload.data)
      : state.userDetail;
      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      if (checkIfArray(updatedAllApplicationData) && responseUserDetails) {
        const indexOfOldUser = updatedAllApplicationData.findIndex(
          //Find the index of lead which is updated and replace with new update user details in UKC for my application card and dashboard
          (applicationData) =>
            get(applicationData, "lead.id") === get(responseUserDetails, "lead.id")
        );
        if (indexOfOldUser !== -1) {
          responseUserDetails = {
            ...responseUserDetails,
            accessToken: get(updatedAllApplicationData[indexOfOldUser],"accessToken")
          }
          updatedAllApplicationData[indexOfOldUser] = responseUserDetails;
        }
      }

      /**
       * get paid entities and store in seperate reducer state 
       */
      const { paymentDetails = null } = responseUserDetails || {};
      const payment_txn_success_status = state.payment_txn_success_status || 
      (state.clientInfo &&
        checkIfArray(get(state.clientInfo, "paymentDetails.payment_success_status"))
          ? state.clientInfo.paymentDetails.payment_success_status
          : PAYMENT_SUCCESS_STATUS);
      const paid_FeesType_1_Entities = (
        paymentDetails &&
        map(
          filter(paymentDetails, item => {
            return (
              (isNil(get(item, "paymentDetailsModel.feesType")) ||
                isEmpty(get(item, "paymentDetailsModel.feesType")) ||
                get(item, "paymentDetailsModel.feesType") ===
                  FEES_TYPE.APPLICATION_FORM_FEES) &&
              payment_txn_success_status.includes(
                get(item, "paymentDetailsModel.transactionStatus")
              )
            );
          }),
          item => {
            set(
              get(item, "paymentDetailsModel"),
              "entityId",
              get(item, "enrolledLeadModel.entityId")
            );
            /*UKC code - COMMENTED FOR FUTHER USE  */
            !isNil(get(item, "enrolledLeadModel.amount")) && set(
              get(item, "paymentDetailsModel"),
              "amount",
              get(item, "enrolledLeadModel.amount")
            );
            return get(item, "paymentDetailsModel");
          }
        )
      );
      return {
        ...state,
        swapCourseErrorMessasage:null,
        userDetail: responseUserDetails,          
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        isAutoSaveInProgress: false,
        allApplicationsData: updatedAllApplicationData
      };
    }
    case actionTypes.SWAP_COURSE_EDIT_FAILED: {
      return {
        ...state,
        swapCourseErrorMessasage: action.payload.message,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        isAutoSaveInProgress: false
      };
    }
    case actionTypes.GLOBAL_LOADER_TOGGLE: {
      return {
        ...state,
        isGlobalInProgress: !!action?.payload?.flag,
        globalLoaderMessage:!!action?.payload?.messaage?action.payload.messaage:null
      };
    }
    case actionTypes.UPDATE_VERIFICATION_DATA: {
      const { actionItem, changeDataTo, mobileNumber = "", email = "" } = action.payload.data;
      const { userDetail } = state;
      const { VERIFY_EMAIL, VERIFY_MOBILE } = VERIFICATION_AND_EDIT_ACTION_ITEMS;
      const mobileNumberData = mobileNumber ||  get(userDetail, "user.mobileNumber") || "";
      const emailData = email || get(userDetail, "user.email") || "";
      let verificationData = get(userDetail, "lead.verificationData") ? get(userDetail, "lead.verificationData") : [];
      Array.isArray(verificationData) && !verificationData.some(ele => ele.value === emailData && ele.keyId === 1) && verificationData.push({keyId: 1, value: userDetail.user.email, isVerified: null})
      Array.isArray(verificationData) && !verificationData.some(ele => ele.value === mobileNumberData && ele.keyId === 5) && verificationData.push({keyId: 5, value: userDetail.user.mobileNumber, isVerified: null})
      verificationData = checkIfArray(verificationData) && verificationData.map(ele => {
        let { keyId, value, isVerified } = ele;
        if(keyId === 1 && value === emailData && changeDataTo && actionItem === VERIFY_EMAIL){
          ele = {...ele, isVerified: changeDataTo}
        }
        if(keyId === 5 && value === mobileNumberData && changeDataTo && actionItem === VERIFY_MOBILE){
          ele = {...ele, isVerified: changeDataTo}
        }
        return ele;
      })
      let updatedUserDetail = {
        ...state.userDetail,
        lead: {
          ...state.userDetail.lead,
          verificationData: verificationData
        }
      }
      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      if (checkIfArray(updatedAllApplicationData) && updatedUserDetail) {
        const indexOfOldUser = updatedAllApplicationData.findIndex(
          //Find the index of lead which is updated and replace with new update user details in UKC for my application card and dashboard
          (applicationData) =>
            get(applicationData, "lead.id") === get(updatedUserDetail, "lead.id")
        );
        if (indexOfOldUser !== -1) {
          updatedUserDetail = {
            ...updatedUserDetail,
            accessToken: get(updatedAllApplicationData[indexOfOldUser],"accessToken")
          }
          updatedAllApplicationData[indexOfOldUser] = updatedUserDetail;
        }
      }
      return {
        ...state,
        userDetail: updatedUserDetail,
        allApplicationsData: updatedAllApplicationData
      };
    }

    case actionTypes.UPDATE_ONLINE_STATUS: {
      return {
        ...state      
      };
    }

    case actionTypes.SHOW_REFRESH_WINDOW_POPUP: {
      return {
        ...state,
        showRefreshWindowPopup:true      
      };
    }
    case actionTypes.HIDE_REFRESH_WINDOW_POPUP: {
      return {
        ...state,
        showRefreshWindowPopup:false      
      };
    }
    case actionTypes.SET_MISSING_MANDATORY_FIELDS: {
      return {
        ...state,
        mandatoryFieldsMissing:!!action?.payload?.data?action.payload.data:[],
      };
    }
    case actionTypes.SET_LEAD_HISTORY_STATUSID: {
      return {
        ...state,
        leadHistoryStatusId:!!action?.payload?.data && Array.isArray(action.payload.data) && (action.payload.data).length>0 ?action.payload.data:null,
      };
    }
    case actionTypes.BOOKMARK_ORIGIN_STEP_FORM_MISSING_FIELDS_POPUP_FIRSTTIME_REQUESTED: {
      console.log(`stepDetailsFromMissingFieldsPopupFirsttimeRequested`,action?.payload?.data);
      return {
        ...state,
        stepDetailsFromMissingFieldsPopupFirsttimeRequested:!!action?.payload?.data ?action.payload.data:null,
      };
    }
    case actionTypes.SET_AUTO_NAVIGATE_ROUTE_DETAILS: {
      console.log(`autoNavigateRouteDetails`,action?.payload?.data);
      return {
        ...state,
        autoNavigateRouteDetails:!!action?.payload?.data ?action.payload.data:null,
      };
    }

    case actionTypes.PAYMENT_TRANSACTION_HISTORY_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
        isLeftSidebarExpanded:false
      };
    }

    case actionTypes.PAYMENT_TRANSACTION_HISTORY_SUCCESS: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
        paymentTransactionHistory: action.payload.data,
      };
    }

    case actionTypes.PAYMENT_TRANSACTION_HISTORY_FAILED: {
      return {
        ...state,
        errorMessage: action.payload.message,
        isProgressCount: state.isProgressCount - 1,
        isInProgress: false,
      };
    }

    case actionTypes.SLOT_BOOKING_API_CALL_ENABLED: {
      return {
        ...state,
        isSlotBookingAPICallEnabled: action.payload.data
      };
    }

    case actionTypes.SUBMIT_BTN_TRIGERRED: {
      return {
        ...state,
        isSubmitBtnTriggered: action.payload.data
      };
    }

    case actionTypes.SLOT_BOOKING_API_CALL_FUNC: {
      return {
        ...state,
        slotBookingAPICallFunc: action.payload.data
      };
    }

    case actionTypes.UPDATE_VALUES_IN_MASTERDATA: {
      const { key, value={} } = get(action,`payload.data`);
      return {
        ...state,
        masterData: {
          ...state.masterData,
          [key]: [...value]
        }
      }
    }
    
    case actionTypes.DOMAIN_CONFIG_SUCCESS: {
      return {
        ...state,
        domainConfig: action.payload.data
      }
    }

    case actionTypes.DOMAIN_CONFIG_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }

    case actionTypes.DOMAIN_CONFIG_FAILED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        errorMessage: action.payload.message,
        isInProgress: false,
      };
    }

    case actionTypes.SET_LEFT_NAVIGATION_ACTIVE_MENU: {
      return {
        ...state,
        activeleftMenu: action.payload.data
      }
    }

    case actionTypes.GET_ACTIVITIES_REQUESTED: {
      return {
        ...state,
        getStudentActivitiesIsInprogress: true
      }
    }

    case actionTypes.GET_ACTIVITIES_SUCCESS: {
      return {
        ...state,
        studentActivities: action.payload.data,
        getStudentActivitiesIsInprogress: false
      }
    }

    case actionTypes.GET_ACTIVITIES_FAILED: {
      return {
        ...state,
        getStudentActivitiesIsInprogress: false
      }
    }
    case actionTypes.SET_LEFT_NAVIGATION_BAR_ACTIVE: {
      return {
        ...state,
        isLeftSidebarExpanded: action.payload.data
      };
    }

    case actionTypes.GENERATE_PDF_REQUESTED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount + 1,
        isInProgress: true,
      };
    }

    case actionTypes.GENERATE_PDF_FAILED: {
      return {
        ...state,
        isProgressCount: state.isProgressCount - 1,
        errorMessage: action.payload.message,
        isInProgress: false,
      };
    }

    case actionTypes.SET_APPLICATION_CARD_DATA: {
      return {
        ...state,
        applicationCardData: action.payload.data
      }
    }

    case actionTypes.ALL_APPLICATION_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
        fetchAllApplicationDataIsinProgress: true
      }
    }

    case actionTypes.ALL_APPLICATION_SUCCESS: {
      const allApplicationsIds = [];
      const allApplicationsData = action.payload.data;
      const allApplicatonsInstData = action.payload.allApplicatonsInstData || [];
      const allInstIdArray = checkIfObject(allApplicatonsInstData) ? Object.keys(allApplicatonsInstData) : [];
      let allApplicationsDataMappedWithLeadIds = {};
      let userDetails = cloneDeep(state.userDetail);
      const updatedMetaDataLeadId = state.updatedMetaDataLeadId;
      let currentLeadAccessToken = null;
      checkIfArray(allApplicationsData) && allApplicationsData.forEach(applicationData => {
        const applicationId = get(applicationData, "lead.id");
        if(applicationId === get(userDetails, "lead.id")) {
          currentLeadAccessToken = get(applicationData, "accessToken");
          if(currentLeadAccessToken) {
            userDetails = {
              ...userDetails,
              accessToken: currentLeadAccessToken
            }
          }
        }
        if(applicationId) {
          allApplicationsIds.push(applicationId);
          allApplicationsDataMappedWithLeadIds = {
            ...allApplicationsDataMappedWithLeadIds,
            [applicationId]:
              applicationId === updatedMetaDataLeadId &&
              applicationId === get(userDetails, "lead.id")
                ? userDetails || applicationData
                : applicationData,
          };
        }
      });
      return {
        ...state,
        allApplicationsData,
        allApplicationsIds,
        allApplicatonsInstData,
        allApplicationsDataMappedWithLeadIds,
        allInstIdArray,
        fetchAllApplicationDataIsinProgress: false,
        isAllApplicationDataAPICalled: true,
        userDetail: userDetails
      }
    }

    case actionTypes.UPDATE_ALL_APPLICATION_DATA: {
      return {
        ...state,
        ...(action.payload.data || {})
      }
    }

    case actionTypes.ALL_APPLICATIONL_FAILED: {
      return {
        ...state,
        isInProgress: false,
        fetchAllApplicationDataIsinProgress: false,
        isAllApplicationDataAPICalled: true,
        isAllApplicationDataFailed: true
      }
    }

    case actionTypes.ALL_APPLICATIONS_CONFIG_REQUESTED: {
      return {
        ...state,
        isInProgress: true
      }
    }

    case actionTypes.ALL_APPLICATIONS_CONFIG_SUCCESS: {
      return {
        ...state,
        instwiseAllConfigs: action.payload.data,
        isInProgress: false
      }
    }

    case actionTypes.ALL_APPLICATIONS_CONFIG_FAILED: {
      return {
        ...state,
        isInProgress: false
      }
    }

    case actionTypes.UPDATE_APPLICATIONS_AUTH_DETAILS_ACCORDING_LEAD_ID: {
        let updatedApplicationsAuthDetails = cloneDeep(
          state.applicationsAuthDetailsMappedWithLeadId || {}
        );
        updatedApplicationsAuthDetails = {
          ...updatedApplicationsAuthDetails,
          ...(action.payload.data || {}),
        };
        return {
          ...state,
          applicationsAuthDetailsMappedWithLeadId:
            updatedApplicationsAuthDetails,
        };
    }

    case actionTypes.GET_LEAD_DOCUMENTS_REQUESTED: {
      const leadId = action.payload.leadId;
      if(leadId) {
        return {
          ...state,
          leadIdwiseFetchLeadDocumentsIsInProgress: {
            ...(state.leadIdwiseFetchLeadDocumentsIsInProgress || {}),
            [leadId]: true
          }
        }
      } else {
        return {
          ...state,
          fetchLeadDocumentsIsInProgress: true
        }
      }
    }

    case actionTypes.GET_LEAD_DOCUMENTS_SUCCESS: {
      const leadId = action.payload.leadId;
      if(leadId) {
        return {
          ...state,
          leadIdwiseFetchLeadDocumentsIsInProgress: {
            ...(state.leadIdwiseFetchLeadDocumentsIsInProgress || {}),
            [leadId]: false
          }
        }
      } else {
        return {
          ...state,
          fetchLeadDocumentsIsInProgress: false
        }
      }
    }

    case actionTypes.GET_ACTIVITIES_FAILED: {
      const leadId = action.payload.leadId;
      if(leadId) {
        return {
          ...state,
          leadIdwiseFetchLeadDocumentsIsInProgress: {
            ...(state.leadIdwiseFetchLeadDocumentsIsInProgress || {}),
            [leadId]: false
          }
        }
      } else {
        return {
          ...state,
          fetchLeadDocumentsIsInProgress: false
        }
      }
    }

    case actionTypes.SET_LEADID_WISE_ACTION_INPROGRESS: {
      const leadId = action.payload.leadId;
      const actionKey = action.payload.actionKey;
      return {
        ...state,
        leadIdWiseActionInProgress: {
          ...(state.leadIdWiseActionInProgress || {}),
          [leadId]: {
            ...(get(state.leadIdWiseActionInProgress, `${leadId}`) || {}),
            [actionKey]: !get(state.leadIdWiseActionInProgress, `${leadId}.${actionKey}`)
          }
        }
      }
    }

    case actionTypes.SET_LEADWISE_HISTORY_VALUES_API_NOT_CALLED: {
      const leadId = action.payload.leadId;
      return {
        ...state,
        leadIdWiseHistoryValues: {
          ...state.leadIdWiseHistoryValues,
          [leadId]: {
            apiCalled: false
          }
        }
      }
    }

    case actionTypes.UPDATE_INDEX_META_DATA_OF_ALL_APPLICATION_SUCCESS: {
      const { indexedKeyValueAttributes, leadId, updatedUserDetail } = action.payload.data || {};
      let updatedAllApplicationData = cloneDeep(state.allApplicationsData);
      let updatedAllApplicationDataMappedWithId = cloneDeep(state.allApplicationsDataMappedWithLeadIds);
      let updatedUserDetails = {};
      if (
        checkIfArray(updatedAllApplicationData) &&
        leadId &&
        checkIfArray(indexedKeyValueAttributes)
      ) {
        const indexOfOldUser = updatedAllApplicationData.findIndex(
          //Find the index of lead which is updated and replace with new update user details in UKC for my application card and dashboard
          (applicationData) => get(applicationData, "lead.id") === leadId
        );
        if (indexOfOldUser !== -1) {
          updatedUserDetails =  set(
            updatedAllApplicationData[indexOfOldUser],
            `metaData.indexedKeyValueAttributes`,
            indexedKeyValueAttributes
          )
          updatedAllApplicationData[indexOfOldUser] = updatedUserDetails;
          updatedAllApplicationDataMappedWithId[leadId] = updatedUserDetails
        }
      }
      return {
        ...state,
        userDetail: checkIfObject(updatedUserDetail) ? updatedUserDetail : state.userDetail,
        allApplicationsData: updatedAllApplicationData,
        allApplicationsDataMappedWithLeadIds: updatedAllApplicationDataMappedWithId
      };
    }

    default: {
      return state;
    }
  }
}
