import Cookies from "js-cookie";
import { checkIfObject, isAccessTokenTableKeyEnabled } from "./Utils";
import { ACCESS_TOKEN_CLAIMS, SKIP_LOCAL_STORAGE, STORAGE_KEY, STORAGE_TYPE } from "./Constants";
import { cloneDeep } from "lodash";
const jwt_decode = require("jwt-decode");

export const OfflineStorage = {
  init: function () {
    if (typeof Storage === "undefined" && window.inMemoryStorage === undefined)
      window.inMemoryStorage = window.inMemoryStorage || [];
  },
  setItem: function (
    key,
    value,
    isPersistant,
    storageType = STORAGE_TYPE.COOKIES
  ) {
    if (
      isAccessTokenTableKeyEnabled() &&
      storageType === STORAGE_TYPE.COOKIES && 
      SKIP_LOCAL_STORAGE.includes(key)
    ) {
      //If security version 2 is enabled then store data in cookies
      let cookieAttributes = {
        secure: true,
        sameSite: "Strict",
      };
      const accesstoken = Cookies.get(STORAGE_KEY.ACCESS_TOKEN) || localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
      if(accesstoken) {
        const tokenDecoded = jwt_decode(accesstoken);  
        if(!(ACCESS_TOKEN_CLAIMS[key] && tokenDecoded[ACCESS_TOKEN_CLAIMS[key]])) {
          Cookies.set(key, value, cookieAttributes);
        }
        if(SKIP_LOCAL_STORAGE.includes(key)) {
          Cookies.set(key, value, cookieAttributes);
        }
      } else {
        Cookies.set(key, value, cookieAttributes);
      }
    } else {
      if (typeof Storage !== "undefined") {
        if (isPersistant) {
          const accesstoken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
          if(accesstoken && isAccessTokenTableKeyEnabled()) {
            const tokenDecoded = jwt_decode(accesstoken);  
            if(!(ACCESS_TOKEN_CLAIMS[key] && tokenDecoded[ACCESS_TOKEN_CLAIMS[key]])) {
              localStorage.setItem(key, value);
            }
          } else {
            localStorage.setItem(key, value);
          }
        } else {
          sessionStorage.setItem(key, value);
        }
      } else {
        //Store token in global Variable so it can be accessed in same session
        window.inMemoryStorage[key] = value;
      }
    }
  },
  getItem: function (key, isPersistant, storageType = STORAGE_TYPE.COOKIES) {
    const accesstoken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
    let updatedKey = cloneDeep(key);
    if (
      isAccessTokenTableKeyEnabled() &&
      storageType === STORAGE_TYPE.COOKIES && 
      SKIP_LOCAL_STORAGE.includes(key)
    ) {
      //If security version 2 is enabled then get data from cookies
      if(accesstoken) {
        const tokenDecoded = jwt_decode(accesstoken);  
        const claimsKey = ACCESS_TOKEN_CLAIMS[updatedKey]
        const decodedClaimsKeyValye = claimsKey && tokenDecoded[claimsKey]
        if(decodedClaimsKeyValye) {
          return `${decodedClaimsKeyValye}`;
        }
        if(updatedKey === STORAGE_KEY.INST) {
          updatedKey = STORAGE_KEY.VERIFIED_INST;
        }
      }
      return Cookies.get(updatedKey);
    } else {
      //IF Local Storage is available
      if(accesstoken && isAccessTokenTableKeyEnabled()) { //If data is present in access token then return that data
        const tokenDecoded = jwt_decode(accesstoken);  
        const claimsKey = ACCESS_TOKEN_CLAIMS[updatedKey]
        const decodedClaimsKeyValye = claimsKey && tokenDecoded[claimsKey]
        if(decodedClaimsKeyValye) {
          return `${decodedClaimsKeyValye}`;
        }
        if(updatedKey === STORAGE_KEY.INST) {
          updatedKey = STORAGE_KEY.VERIFIED_INST;
          return Cookies.get(updatedKey);
        }
      }
      if (typeof Storage !== "undefined") {
        if (isPersistant) {
          return localStorage.getItem(key);
        } else {
          return sessionStorage.getItem(key);
        }
      } else {
        if (window.inMemoryStorage) {
          return window.inMemoryStorage[key];
        }
      }
      return null;
    }
  },
  getAllItem: function (storageType = STORAGE_TYPE.COOKIES) {
    let allStorageData = {};
    if ((storageType = STORAGE_TYPE.COOKIES)) {
      const accesstoken = localStorage.getItem(STORAGE_KEY.ACCESS_TOKEN);
      if(accesstoken) {
        const tokenDecoded = jwt_decode(accesstoken);  
        Object.keys(ACCESS_TOKEN_CLAIMS).forEach(storageKey => {
          const claimsKey = ACCESS_TOKEN_CLAIMS[storageKey]
          if(tokenDecoded[claimsKey]) {
            allStorageData[storageKey] = `${tokenDecoded[claimsKey]}`
          }
        });
        allStorageData = {
          ...allStorageData,
          [STORAGE_KEY.ACCESS_TOKEN]: accesstoken
        }
      }
      allStorageData = {
        ...allStorageData, 
        ...(Cookies.get() || {})
      }
      return allStorageData;
    } else {
      return window.localStorage;
    }
  },
  deleteItem: function (key, isPersistant, storageType = STORAGE_TYPE.COOKIES) {
    //IF Local Storage is available
    if (typeof Storage !== "undefined") {
      if (isPersistant) {
        localStorage.removeItem(key);
        Cookies.remove(key);
      } else {
        sessionStorage.removeItem(key);
      }
    } else {
      if (window.inMemoryStorage) {
        delete window.inMemoryStorage[key];
      }
    }
  },
  clear: function (isPersistant, storageType = STORAGE_TYPE.COOKIES) {
    if (typeof Storage !== "undefined") {
      if (isPersistant) {
        localStorage.clear();
        const allCookies = Cookies.get();
        // Delete each cookie
        checkIfObject(allCookies) &&
        Object.keys(allCookies).forEach((cookieName) => {
          Cookies.remove(cookieName);
        });
      } else {
        sessionStorage.clear();
      }
    } else {
      if (window.inMemoryStorage) {
        delete window.inMemoryStorage;
      }
    }
  },
};
OfflineStorage.init();
