import { createActionCreator } from "../../shared/store/Utilities";

/* Declear here all type of actions used in hello world component */
export const actionTypes = {
  GET_CONFIG: "app/pages/dashbaord/config/GET",
  CONFIG_REQUESTED: "app/pages/dashbaord/config/REQUESTED",
  CONFIG_SUCCESS: "app/pages/dashbaord/config/SUCCESS",
  CONFIG_FAILED: "app/pages/dashbaord/config/FAILED",
  GET_SECTION : "app/pages/dashbaord/card/GET",
  GET_SECTION_REQUESTED : "app/pages/dashbaord/card/REQUESTED",
  GET_SECTION_SUCCESS : "app/pages/dashbaord/card/SUCCESS",
  GET_SECTION_FAILED : "app/pages/dashbaord/card/FAILED",
};
/* Declear here all actions used in component */
export const getConfig = createActionCreator(
  actionTypes.GET_CONFIG,
  "data",
  "urlParam"
);
export const configRequested = createActionCreator(
  actionTypes.CONFIG_REQUESTED,
  ""
);
export const configSuccess = createActionCreator(
  actionTypes.CONFIG_SUCCESS,
  "data"
);
export const configFailed = createActionCreator(
  actionTypes.CONFIG_FAILED,
  "errorMessage"
);
export const getSectionAdvtData = createActionCreator(
  actionTypes.GET_SECTION,
  "data"
);
export const getSectionAdvtDataRequest = createActionCreator(
  actionTypes.GET_SECTION_REQUESTED,
  ""
);
export const getSectionAdvtDataSuccess = createActionCreator(
  actionTypes.GET_SECTION_SUCCESS,
  "data"
);
export const getSectionAdvtDataFailed = createActionCreator(
  actionTypes.GET_SECTION_FAILED,
  "errorMessage"
);