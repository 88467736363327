import { call, put, takeLatest } from "redux-saga/effects";
import { logError, logInfo } from "../../infrastructure/logging/Logger";
import { ProfileService } from "../services/profile/ProfileService";
import {
  initializeAppCompleted,
  initializeAppFailure,
  INITIALIZE_APP
} from "../store/Actions";

export function* getAppInitialize(action) {
  //const details = yield call(ProfileService.getProfileData);
  //yield put(actions.receiveProducts(details))
  try {
    logInfo("Received message into app Initilize saga", action);
    yield put(initializeAppCompleted("App Initiliazed completed."));
  } catch (error) {
    logError("App Initialized error.");
    yield put(initializeAppFailure("App Initiliazed Error."));
  }
}

export function* watchGetMasterData() {
  yield takeLatest(INITIALIZE_APP, getAppInitialize);
}
