import { get } from "lodash";
import { checkIfArray } from "../../shared/utilties/Utils";
import { actionTypes } from "./Actions";
import { actionTypes as appActionTypes } from "../../shared/services/appData/Actions";

const defaultState = {
  isInProgress: true,
  queryList: null,
  submitQueryInProgress: false,
  isNewQueryAdded: false,
  getAllConversationInProgress: null,
  allConversations: null,
  queryCount: null,
  queryCountIsInProgress: false,
  createConversationInProgress: null,
  queryTotalUnreadMessagCount: null,
};
export function reducer(state = defaultState, action) {
  switch (action.type) {
    case appActionTypes.LOGOUT: {
      return {
        ...state,
        defaultState
      };
    }
    case actionTypes.RESET_ALL_QUERY_DATA: {
      return {
        ...defaultState,
        queryCount: state.queryCount,
        queryTotalUnreadMessagCount: state.queryTotalUnreadMessagCount,
      };
    }
    case actionTypes.SUBMIT_QUERY_REQUESTED: {
      return {
        ...state,
        submitQueryInProgress: true,
        isNewQueryAdded: false,
      };
    }
    case actionTypes.SUBMIT_QUERY_SUCCESS: {
      const submitedQueryData = action.payload.data;
      const updatedQueryList = checkIfArray(state.queryList)
        ? [submitedQueryData, ...state.queryList]
        : [submitedQueryData];
      const updatedUnResolvedCount =
        (get(state.queryCount, "unResolvedCount") || 0) + 1;
      return {
        ...state,
        submitQueryInProgress: false,
        queryList: updatedQueryList,
        isNewQueryAdded: true,
        queryCount: {
          ...(state.queryCount || {}),
          unResolvedCount: updatedUnResolvedCount,
        },
      };
    }
    case actionTypes.SUBMIT_QUERY_FAILED: {
      return {
        ...state,
        submitQueryInProgress: false,
        isNewQueryAdded: false,
      };
    }
    case actionTypes.GET_ALL_QUERY_REQUESTED: {
      return {
        ...state,
        isInProgress: true,
      };
    }
    case actionTypes.GET_ALL_QUERY_SUCCESS: {
      return {
        ...state,
        isInProgress: false,
        queryList: action.payload.data,
      };
    }
    case actionTypes.GET_ALL_QUERY_FAILED: {
      return {
        ...state,
        isInProgress: false,
      };
    }
    case actionTypes.GET_ALL_CONVERSATION_REQUESTED: {
      return {
        ...state,
        getAllConversationInProgress: action.payload.ticketId,
      };
    }
    case actionTypes.GET_ALL_CONVERSATION_SUCCESS: {
      const { ticketId, conversations } = action.payload.data || {};
      const allConversationsMappedWithTicket = {
        ...(state.allConversations || {}),
        [ticketId]: conversations,
      };
      return {
        ...state,
        getAllConversationInProgress: null,
        allConversations: allConversationsMappedWithTicket,
      };
    }
    case actionTypes.GET_ALL_CONVERSATION_FAILED: {
      return {
        ...state,
        getAllConversationInProgress: null,
      };
    }
    case actionTypes.GET_QUERY_COUNT_REQUESTED: {
      return {
        ...state,
        queryCountIsInProgress: true,
      };
    }
    case actionTypes.GET_QUERY_COUNT_SUCCESS: {
      return {
        ...state,
        queryCount: action.payload.data,
        queryCountIsInProgress: false,
      };
    }
    case actionTypes.GET_QUERY_COUNT_FAILED: {
      return {
        ...state,
        queryCountIsInProgress: false,
      };
    }
    case actionTypes.CONVERSATION_MARK_AS_READ_SUCCESS: {
      const ticketId = action.payload.ticketId;
      let updatedQueryList = state.queryList;
      let leadUnreadMessageCount = 0;
      if (ticketId) {
        updatedQueryList = state.queryList.map((queryData) => {
          if (queryData.rowKey === ticketId) {
            leadUnreadMessageCount = queryData.leadUnreadMessageCount;
            return {
              ...queryData,
              leadUnreadMessageCount: 0,
            };
          }
          return queryData;
        });
      }
      return {
        ...state,
        queryList: updatedQueryList,
        queryTotalUnreadMessagCount:
          state.queryTotalUnreadMessagCount && leadUnreadMessageCount
            ? state.queryTotalUnreadMessagCount - leadUnreadMessageCount
            : state.queryTotalUnreadMessagCount,
      };
    }
    case actionTypes.CREATE_CONVERSATION_REQUESTED: {
      return {
        ...state,
        createConversationInProgress: action.payload.ticketId,
      };
    }
    case actionTypes.CREATE_CONVERSATION_SUCCESS: {
      const { ticketId } = action.payload.data || {};
      return {
        ...state,
        createConversationInProgress: null,
        allConversations: {
          ...(state.allConversations || {}),
          [ticketId]: [
            ...get(state.allConversations, ticketId),
            action.payload.data,
          ],
        },
      };
    }
    case actionTypes.CREATE_CONVERSATION_FAILED: {
      return {
        ...state,
        createConversationInProgress: null,
      };
    }
    case actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT_SUCCESS: {
      return {
        ...state,
        queryTotalUnreadMessagCount: action.payload.totalUnreadMessageCount,
      };
    }
    case actionTypes.GET_TOTAL_UNREAD_MESSAGE_COUNT_FAILED: {
      return {
        ...state,
        queryTotalUnreadMessagCount: null,
      };
    }
    default: {
      return state;
    }
  }
}
